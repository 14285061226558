import { Draggable, DraggableStyle } from "@hello-pangea/dnd";
import { ComponentType } from "react";

const getItemStyle = (draggableStyle: DraggableStyle | undefined) => ({
  marginTop: "8px",
  overflow: "none",
  ...draggableStyle,
});

type WithDraggableProps<T> = T & {
  draggableId: string;
  index: number;
  isDragDisabled?: boolean;
};

const withDraggable = <T,>(Component: ComponentType<T>) => {
  // eslint-disable-next-line react/function-component-definition, react/display-name
  return (props: WithDraggableProps<Omit<T, "dragHandleProps">>) => {
    const { draggableId, index, isDragDisabled = false, ...rest } = props;

    return (
      <Draggable
        draggableId={draggableId}
        index={index}
        isDragDisabled={isDragDisabled}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(provided.draggableProps.style)}>
            <Component
              {...(rest as T)}
              dragHandleProps={provided.dragHandleProps}
            />
          </div>
        )}
      </Draggable>
    );
  };
};

export default withDraggable;
