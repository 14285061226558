import { createSvgIcon } from "@mui/material";

const CheckedIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'>
    <mask
      id='mask0_90_12207'
      maskUnits='userSpaceOnUse'
      x='3'
      y='3'
      width='18'
      height='18'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 3H19C20.11 3 21 3.9 21 5V19C21 20.1 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z' // eslint-disable-line max-len
        fill='white'
      />
    </mask>
    <g mask='url(#mask0_90_12207)'>
      <rect
        width='24'
        height='24'
        fill='#1749D1'
      />
    </g>
  </svg>,
  "Checked"
);

export default CheckedIcon;
