import Box from "@mui/material/Box";
import { Pagination, Stack, Typography } from "@mui/material";
import Select from "components/inputs/Select";
import React, { useMemo } from "react";
import useList, { DEFAULT_LIMIT, ILimit, LIMITS } from "hooks/useList";

interface IListPaginationProps {
  totalCount: number;
  limits?: ILimit[];
  defaultLimit?: number;
  text?: string;
}

const ListPagination = ({
  totalCount,
  limits = LIMITS,
  defaultLimit = DEFAULT_LIMIT,
  text = "Карточек\nна странице",
}: IListPaginationProps) => {
  const { changePage, page, changeLimit, limit } = useList();
  const itemsPerPage = +limit || defaultLimit;
  const currentPage = +page || 1;
  const pageCount = useMemo(() => {
    const limitInt = limit ? +limit : defaultLimit;
    if (totalCount) return Math.ceil(totalCount / +limitInt);
    return 0;
  }, [totalCount, limit]);

  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalCount);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Box flex={1} />
      <Pagination
        count={pageCount}
        variant='outlined'
        shape='rounded'
        onChange={changePage}
        page={currentPage}
      />
      <Stack
        flex={1}
        spacing={4}
        direction='row'
        alignItems='center'
        justifyContent='flex-end'>
        <Typography
          variant='body14rg'
          sx={{ color: "primary.main" }}>
          {`${firstItemIndex}-${lastItemIndex} из ${totalCount}`}
        </Typography>
        <Stack
          alignItems='center'
          justifyContent='end'
          direction='row'
          spacing={1.5}>
          <Typography
            sx={{ whiteSpace: "break-spaces" }}
            variant='body14rg'>
            {text}
          </Typography>
          <Select<ILimit>
            items={limits}
            value={limit || (defaultLimit.toString() as ILimit["value"])}
            onChange={changeLimit}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default ListPagination;
