import TableConstructor from "layout/TableConstructor";
import { useNavigate } from "react-router-dom";
import { Examination_Type, useCompetencyMapExaminationListQuery } from "@generated/graphql";
import columnConfig, { ExaminationItem } from "pages/CompetencyMaps/view/tabs/TestingListTab/tableConfig/columnConfig";
import filterConfig, {
  ExaminationFilter,
} from "pages/CompetencyMaps/view/tabs/TestingListTab/tableConfig/filterConfig";
import columnFilterMap from "pages/CompetencyMaps/view/tabs/TestingListTab/tableConfig/columnFilterMap";
import ROUTES from "constants/routes";

const TestingListTab = () => {
  // const { mapId } = useParams();
  const navigate = useNavigate();

  const { data, loading, refetch } = useCompetencyMapExaminationListQuery({
    variables: {
      page: 0,
      limit: 50,
      filter: {
        // competencyMapId: mapId TODO add filter
      },
    },
  });

  const examinationList = data?.examinations.data || [];
  const totalCount = data?.examinations.totalCount || 0;

  const navigateToView = (
    examination: ExaminationItem,
    trial?: NonNullable<ExaminationItem["trials"]>[number] | null
  ) => {
    if (trial) {
      navigate(`/${ROUTES.TESTING}/view/${trial.id}`);
    } else {
      navigate(`/${ROUTES.TESTING}/view/${examination.id}`);
    }
  };

  return (
    <TableConstructor<ExaminationItem, ExaminationFilter, undefined, "trials">
      data={examinationList}
      totalCount={totalCount}
      loading={loading}
      columnsConfig={columnConfig}
      filtersConfig={filterConfig}
      columnFilterMap={columnFilterMap}
      emptyPlaceholderText='Пока нет тестирований'
      refetch={refetch}
      colapsingRowName='trials'
      onRowClick={navigateToView}
      defaultColumns={[
        "numericId",
        "competencyMapTitle",
        "competencesCount",
        "candidateId",
        "candidateFullName",
        "createdAt",
        "trialStatus",
        "examinationType",
      ]}
      checkIsCollapsingRow={(row) => row.type !== Examination_Type.Personal}
    />
  );
};

export default TestingListTab;
