import { Company_Status, Organization_State, Type_Of_Organization } from "@generated/graphql";

export const ORGANIZATION_STATES: { id: Organization_State; displayName: string }[] = [
  {
    id: Organization_State.Active,
    displayName: "Действующая",
  },
  {
    id: Organization_State.Liquidating,
    displayName: "Ликвидируется",
  },
  {
    id: Organization_State.Liquidated,
    displayName: "Ликвидирована",
  },
  {
    id: Organization_State.Bankrupt,
    displayName: "Банкротство",
  },
  {
    id: Organization_State.Reorganizing,
    displayName: "В процессе присоединения к другому юрлицу",
  },
];

export const ORGANIZATION_TYPES = [
  {
    id: Type_Of_Organization.Legal,
    displayName: "Юридическое лицо",
  },
  {
    id: Type_Of_Organization.Individual,
    displayName: "Индивидуальные предприниматель",
  },
];

export const COMPANY_STATUS_TITLE: { [key in Company_Status]: string } = {
  [Company_Status.PartlyApproved]: "Частично подтверждена",
  [Company_Status.NotApproved]: "Не подтверждена",
  [Company_Status.Created]: "Создана",
  [Company_Status.Blocked]: "Заблокирована",
  [Company_Status.Approved]: "Подтверждена",
};
