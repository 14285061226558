import { ColumnFilterMap } from "layout/TableConstructor/types";
import { ExaminationFilter } from "pages/Testing/list/config/filterFields";

const columnFilterMap: ColumnFilterMap<ExaminationFilter> = {
  numericId: "numericId",
  competencyMapTitle: "competencyMapTitle",
  specialization: "specialization",
  competencesCount: "competencyAmount",
  knowledgeBlocksCount: "knowledgeBlockAmount",
  candidateId: "candidateNumericId",
  candidateFullName: "candidateFullName",
  createdAt: "createdAt",
  completedAt: "completedAt",
  trialStatus: "trialStatus",
  grades: "grades",
  candidateScore: "candidateScore",
  expectedTimeOfTesting: "expectedTimeOfTesting",
  timeOfTesting: "timeOfTesting",
  examinationType: "examinationType",
  isRepositoryCheck: "isRepositoryCheck",
};

export default columnFilterMap;
