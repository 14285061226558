import React, { useContext } from "react";
import { Alert, Card, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CompanyForm from "components/CompanyForm";
import SecretCompanyCode from "components/SecretCompanyCode";
import AddButton from "components/buttons/AddButton";
import { CompanyRegisterForm } from "pages/Register/types";
import { ORGANIZATION_STATES, ORGANIZATION_TYPES } from "constants/company";
import ROUTES from "constants/routes";
import CircularLoading from "components/CircularLoading";
import { CompanyContext } from "context/companyContext";

const CompanyPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentCompany, loading } = useContext(CompanyContext);

  const form = useForm<CompanyRegisterForm>({
    values: currentCompany
      ? {
          inn: currentCompany.inn,
          ogrn: currentCompany.ogrn,
          okpo: currentCompany.okpo,
          okato: currentCompany.okato,
          oktmo: currentCompany.oktmo,
          managerFio: currentCompany.managerFio,
          managerPosition: currentCompany.managerPosition,
          fullName: currentCompany.fullName,
          shortName: currentCompany.shortName,
          registrationAddress: currentCompany.registrationAddress,
          kpp: currentCompany.kpp,
          ogrnDate: new Date(currentCompany.ogrnDate),
          registrationDate: new Date(currentCompany.registrationDate),
          userPosition: currentCompany.currentUserPosition || "",
          type: ORGANIZATION_TYPES.find((type) => type.id === currentCompany.type) || null,
          state: ORGANIZATION_STATES.find((status) => status.id === currentCompany.state) || null,
        }
      : undefined,
  });

  const handleNavigateAddNewCompany = () => navigate(`/${ROUTES.COMPANY_REGISTER}`);

  if (loading) return <CircularLoading />;

  return (
    <Card>
      <Typography
        variant='h28'
        sx={{ mb: 3.5 }}>
        Данные организации
      </Typography>
      <Stack
        sx={{ mb: 3.5 }}
        direction='row'
        justifyContent='space-between'>
        <Alert
          severity='info'
          sx={{ alignItems: "center" }}>
          Для изменения данных компании обратитесь к администратору
        </Alert>
        {currentCompany?.identification && (
          <SecretCompanyCode
            code={currentCompany?.identification}
            sx={{
              backgroundColor: "blue",
              borderRadius: 2,
              px: 1.5,
              py: 0.5,
              "& .MuiTypography-root": {
                color: "#fff",
              },
            }}
          />
        )}
      </Stack>
      <CompanyForm
        form={form}
        disableAllFields /* showRequestToChangeData TODO */
      />
      <AddButton
        buttonText='добавить организацию'
        onClick={handleNavigateAddNewCompany}
        sx={{ mt: 6 }}
      />
    </Card>
  );
};

export default CompanyPage;
