import { Box, Link, Stack, Typography } from "@mui/material";
import CopyButton from "components/buttons/CopyButton";

interface TestingLinkProps {
  link: string | undefined;
  withoutLabel?: boolean;
}

const TestingLink = ({ link, withoutLabel }: TestingLinkProps) => {
  if (!link) return null;

  return (
    <Stack spacing={0.5}>
      {!withoutLabel && (
        <Typography
          variant='body14rg'
          sx={{ color: "base.500" }}>
          Ссылка на тестирование
        </Typography>
      )}
      <Box
        sx={{
          backgroundColor: "bgSwitch",
          px: 1.5,
          py: 1.3,
          borderRadius: 2,
        }}>
        <CopyButton copyText={link!}>
          <Link
            variant='body16rg'
            href={link}
            sx={{ textDecoration: "none" }}
            target='_blank'>
            {link}
          </Link>
        </CopyButton>
      </Box>
    </Stack>
  );
};

export default TestingLink;
