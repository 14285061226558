import { Grade, KnowledgeBlockOptionsQuery } from "@generated/graphql";

export enum KnowledgeBlockCreationType {
  NEW,
  EXIST,
}

export type KnowledgeBlocksOption = NonNullable<KnowledgeBlockOptionsQuery["knowledgeBlocks"]["data"]>[number];
export type SkillOption = {
  id?: string;
  title: string;
  grades?: Grade[] | null;
  confirmedQuestionsCount?: number | null;
  notConfirmedQuestionsCount?: number | null;
};
export type KnowledgeBlockCompetencyOption = {
  id?: string;
  title?: string;
  skills?: Array<SkillOption> | null;
  profession?: string;
};
export type CompetencyMapInputs = {
  id?: string;
  title: string;
  specialization: string;
  comment?: string | null;
  createdAt?: any;
  updatedAt?: any;
  grades?: string;
  isRepositoryCheck: boolean;
  knowledgeBlocks?: Array<{
    id?: string;
    title?: string;
    knowledgeBlockCompetences?: Array<KnowledgeBlockCompetencyOption> | null;
  }> | null;
};

export type KnowledgeBlockLiteralType = `knowledgeBlocks.${number}`;
export type CompetenciesLiteralType = `${KnowledgeBlockLiteralType}.knowledgeBlockCompetences`;
export type CompetencyLiteralType = `${CompetenciesLiteralType}.${number}`;
export type SkillsLiteralType = `${CompetencyLiteralType}.skills`;
export type SkillLiteralType = `${SkillsLiteralType}.${number}`;

export const isKnowledgeBlockCompetencyFieldsNonNullable = (
  knowledgeBlock: KnowledgeBlockCompetencyOption
): knowledgeBlock is NonNullable<KnowledgeBlockOptionsQuery["knowledgeBlocks"]["data"]>[number] => {
  if (knowledgeBlock?.id && knowledgeBlock?.title && knowledgeBlock?.profession) return true;
  return false;
};

export const isSkillFieldsNonNullable = (skill: SkillOption): skill is Required<SkillOption> => {
  if (skill?.id && skill?.title) return true;
  return false;
};
