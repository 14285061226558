import { Button, FormHelperText, IconButton, Stack, Typography } from "@mui/material";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import { ChangeEvent, useRef } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "assets/icons/DeleteIcon";

type FileUploadButtonProps<T extends FieldValues> = {
  label?: string;
  sx?: object;
  accept?: string;
} & UseControllerProps<T>;

const FileUploadButton = <T extends FieldValues>({
  label = "Загрузить файл",
  sx,
  name,
  rules,
  accept,
}: FileUploadButtonProps<T>) => {
  const { field, fieldState } = useController({ name, rules });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? Array.from(event.target.files)[0] : null;
    field.onChange(file);
  };

  const handleRemoveFile = () => {
    field.onChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Stack
      alignItems='flex-start'
      spacing={0.5}
      sx={sx}>
      {field.value ? (
        <Stack
          direction='row'
          alignItems='center'
          spacing={0.5}
          sx={{
            border: "1px solid",
            borderColor: "primary.main",
            pl: 1,
            borderRadius: 2,
          }}>
          <Typography>{field.value.name}</Typography>
          <IconButton
            onClick={handleRemoveFile}
            color='primary'>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ) : (
        <Button
          variant='contained'
          startIcon={<UploadFileIcon />}
          onClick={handleUploadClick}>
          {label}
        </Button>
      )}
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept={accept}
      />
      {fieldState.error && <FormHelperText sx={{ color: "error.main" }}>{fieldState.error.message}</FormHelperText>}
    </Stack>
  );
};

export default FileUploadButton;
