import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import HomeIcon from "assets/icons/HomeIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import ROUTES from "constants/routes";

const PAGES = [
  { title: "Карты компетенций", link: `/${ROUTES.COMPETENCE_MAPS}` },
  { title: "Блоки знаний", link: `/${ROUTES.KNOWLEDGE_BLOCKS}` },
  { title: "Тестирования", link: `/${ROUTES.TESTING}` },
];

const Navigation = ({ isAuth }: { isAuth: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // TODO integrate isDirector
  const isDirector = isAuth;

  return (
    <>
      {isDirector && (
        <Stack
          direction='row'
          alignItems='center'>
          <Button
            size='medium'
            sx={{ textAlign: "left" }}
            disableRipple
            startIcon={<HomeIcon />}
            onClick={() => navigate(`/${ROUTES.COMPANY}`)}>
            Моя компания
          </Button>
        </Stack>
      )}
      {isAuth &&
        PAGES.map((page) => {
          const isCurrentPage = page.link === location.pathname;
          return (
            <Button
              color='secondary'
              size='medium'
              disableRipple
              key={page.link}
              sx={{
                ...(isCurrentPage && { color: "primary.dark" }),
              }}
              component={Link}
              to={page.link}>
              {page.title}
            </Button>
          );
        })}
    </>
  );
};

export default Navigation;
