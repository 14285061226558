import React, { ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import { Collapse, Grid, Stack, Typography } from "@mui/material";
import ExpandMoreButton from "components/buttons/ExpandMoreButton";

const CollapsedBlock = ({ children, title }: { children: ReactNode; title: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Box sx={{ backgroundColor: "bgSwitch", p: 2, borderRadius: 2 }}>
      <Stack
        direction='row'
        onClick={() => setOpen(!open)}
        spacing={1}
        alignItems='center'
        sx={{ cursor: "pointer" }}>
        <Typography>{title}</Typography>
        <ExpandMoreButton
          expand={open}
          sx={{ color: "base.200", width: 16, height: 16 }}
        />
      </Stack>
      <Collapse in={open}>
        <Grid
          container
          mt={1}>
          {children}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default CollapsedBlock;
