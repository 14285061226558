import { useController, UseControllerProps } from "react-hook-form";
import React, { FC } from "react";
import { CheckboxProps } from "@mui/material";
import Checkbox from "./components/Checkbox";

export type TCheckboxProp = {
  name: string;
  label: string;
  handleChange?: (value: boolean) => void;
  rules?: UseControllerProps["rules"];
};

const CheckboxControl: FC<CheckboxProps & TCheckboxProp> = ({ name, label, disabled, handleChange, rules }) => {
  const {
    field: { value, onChange },
  } = useController({ name, defaultValue: false, rules });
  return (
    <Checkbox
      label={label}
      handleChange={(checked) => {
        onChange(checked);
        handleChange?.(checked);
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export default CheckboxControl;
