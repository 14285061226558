import TextFieldControl from "components/form/TextFieldControl";
import useIsCreate from "hooks/useIsCreate";
import { UseFormReturn } from "react-hook-form";
import TestingLink from "components/TestingLink";
import { InfoInputs } from "../types";

interface ITrialInfoProps {
  form: UseFormReturn<InfoInputs>;
}

const TrialInfo = ({ form }: ITrialInfoProps) => {
  const isCreate = useIsCreate();
  const link = form.watch("link");

  return (
    <>
      <TextFieldControl
        name='createdAt'
        label='Дата создания тестирования'
        disabled
      />
      {!isCreate && <TestingLink link={link} />}
      <TextFieldControl
        name='startedAt'
        label='Начало тестирования'
        disabled
      />
      <TextFieldControl
        name='completedAt'
        label='Завершение тестирования'
        disabled
      />
    </>
  );
};

export default TrialInfo;
