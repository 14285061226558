import React, { useContext } from "react";
import {
  Grade,
  Language,
  Question_Type,
  useGenerateQuestionsMutation,
  useQuestionSubtypesQuery,
} from "@generated/graphql";
import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import Form from "components/form/Form";
import TextFieldControl from "components/form/TextFieldControl";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { LoadingButton } from "@mui/lab";
import AutocompleteControl from "components/form/AutocompleteControl";
import { GENERATE_QUESTIONS_DEFAULT_AMOUNT, GRADE_OPTIONS, QUESTION_TYPES } from "constants/global";
import SelectControl from "components/form/SelectControl";
import { QUESTION_LANGUAGE_OPTIONS } from "constants/language";

export type GenerateQuestionsModalProps = {
  open: boolean;
  onClose: () => void;
  knowledgeBlock: { id: string; title: string; profession: string };
  skill: { id: string; title: string };
};

export type GenerateQuestionsModalForm = {
  knowledgeBlock: { id: string; title: string; profession: string };
  skill: string;
  grade: { id: Grade; name: string };
  questionType: Question_Type;
  questionSubtype?: { id: string; displayName: string };
  language: Language;
  amount: string;
};

const GenerateQuestionsModal = ({ open, onClose, knowledgeBlock, skill }: GenerateQuestionsModalProps) => {
  const { addToast } = useContext(ToastContext);
  const [generateQuestions, { loading }] = useGenerateQuestionsMutation();
  const { data: questionSubtypesData, loading: questionSubtypesLoading } = useQuestionSubtypesQuery();

  const questionSubtypes = questionSubtypesData?.questionSubtypes.map((subtype) => ({
    id: subtype,
    displayName: subtype,
  }));

  const form = useForm<GenerateQuestionsModalForm>({
    defaultValues: {
      knowledgeBlock: {
        id: knowledgeBlock?.id as string,
        title: knowledgeBlock?.title,
        profession: knowledgeBlock?.profession,
      },
      skill: skill.title,
      language: Language.Ru,
      amount: String(GENERATE_QUESTIONS_DEFAULT_AMOUNT),
    },
  });

  const { setValue, handleSubmit, watch } = form;

  const onSubmit = (formData: GenerateQuestionsModalForm) => {
    generateQuestions({
      variables: {
        input: {
          skillId: skill.id,
          grade: formData.grade.id,
          questionType: formData.questionType,
          questionSubtype: formData.questionSubtype?.id,
          language: formData.language,
          amount: Number(formData.amount),
        },
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS, text: "Генерация вопросов может занимать несколько минут" });
        onClose();
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onChangeAmount = (value: number) => {
    let resValue = String(value);

    if (value < 1) resValue = "1";
    else if (value > 10) resValue = "10";

    setValue("amount", resValue);
  };

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const questionTypeWatch = watch("questionType");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'>
      <DialogTitle
        variant='h24'
        textAlign='center'>
        Генерация вопросов
      </DialogTitle>
      <DialogContent sx={{ mb: 3 }}>
        <Form form={form}>
          <Stack spacing={2}>
            <TextFieldControl
              name='knowledgeBlock.title'
              disabled
              label='Блок знаний'
            />
            <TextFieldControl
              name='knowledgeBlock.profession'
              disabled
              label='Профессия'
            />
            <TextFieldControl
              name='skill'
              disabled
              label='Навык'
            />
            <AutocompleteControl
              options={GRADE_OPTIONS}
              name='grade'
              label='Грейд'
              rules={{
                required: true,
              }}
            />
            <SelectControl
              name='questionType'
              items={QUESTION_TYPES}
              sx={{ backgroundColor: "bgSwitch" }}
              label='Тип вопроса'
              rules={{
                required: true,
              }}
            />
            {questionTypeWatch === Question_Type.Practical && (
              <AutocompleteControl
                name='questionSubtype'
                options={questionSubtypes || []}
                loading={questionSubtypesLoading}
                label='Подтип'
                rules={{
                  validate: (value, formValues) => {
                    const isPracticalQuestion = formValues.questionType === Question_Type.Practical;
                    return !(isPracticalQuestion && !value);
                  },
                }}
              />
            )}
            <SelectControl
              name='language'
              items={QUESTION_LANGUAGE_OPTIONS}
              sx={{ backgroundColor: "bgSwitch" }}
              label='Язык вопроса'
              rules={{
                required: true,
              }}
            />
            <TextFieldControl
              name='amount'
              type='number'
              onNumberValueChange={onChangeAmount}
              label='Количество вопросов'
              rules={{
                required: true,
                min: {
                  value: 1,
                  message: "Минимальное количество вопросов для генерации - 1",
                },
                max: {
                  value: 10,
                  message: "Максимальное количество вопросов для генерации - 10",
                },
              }}
            />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={onClose}>
          Отмена
        </Button>
        <LoadingButton
          variant='contained'
          onClick={handleSubmit(onSubmit, onError)}
          loading={loading}>
          Сгенерировать
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateQuestionsModal;
