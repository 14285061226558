import React, { SyntheticEvent, useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import Form from "components/form/Form";
import { Card, Stack } from "@mui/material";
import TextFieldControl from "components/form/TextFieldControl";
import {
  KnowledgeBlockQuery,
  useCreateOrUpdateKnowledgeBlockMutation,
  useDeleteKnowledgeBlockMutation,
  useGetUniqProfessionQuery,
} from "@generated/graphql";
import DatePickerControl from "components/form/DatePickerControl";
import SaveEditButtons from "components/buttons/SaveEditButtons";
import AutocompleteControl from "components/form/AutocompleteControl";
import useIsCreate from "hooks/useIsCreate";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/routes";
import { CompanyContext } from "context/companyContext";
import { ConfirmModalContext } from "context/confirmModalContext";
import VALIDATION from "helpers/validation";
import { cleanSpaceInString } from "helpers/validation/createValidation";
import { debounce } from "lodash";

type KnowledgeBlockInfoInputs = {
  title: string;
  createdAt: string;
  updatedAt: string;
  grades: string;
  profession: string | { displayName: string };
};

const Info = ({ knowledgeBlock }: { knowledgeBlock: KnowledgeBlockQuery["knowledgeBlock"] | null | undefined }) => {
  const { addToast } = useContext(ToastContext);
  const { currentCompanyId } = useContext(CompanyContext);
  const { handleOpen } = useContext(ConfirmModalContext);
  const navigate = useNavigate();
  const isCreate = useIsCreate();
  const [isEditing, setIsEditing] = useState(isCreate);

  const form = useForm<KnowledgeBlockInfoInputs>({
    defaultValues: {
      title: knowledgeBlock?.title || "",
      updatedAt: knowledgeBlock?.updatedAt,
      createdAt: knowledgeBlock?.createdAt,
      profession: knowledgeBlock?.profession,
      grades: knowledgeBlock?.grades?.join(", ") || "",
    },
  });
  const { handleSubmit } = form;

  const { data, loading, refetch } = useGetUniqProfessionQuery();

  const onProfessionInputChange = useCallback(
    debounce((_: SyntheticEvent<Element, Event>, value: string) => {
      refetch({
        search: value,
      });
    }, 500),
    []
  );

  const [updateKnowledgeBlock, { loading: updateLoading }] = useCreateOrUpdateKnowledgeBlockMutation();
  const onSubmit = (formData: KnowledgeBlockInfoInputs) => {
    if (!currentCompanyId) {
      addToast({ type: ToastTypeEnum.ERROR });
      return;
    }
    updateKnowledgeBlock({
      variables: {
        input: {
          companyId: currentCompanyId,
          id: knowledgeBlock?.id,
          title: cleanSpaceInString(formData.title),
          profession: typeof formData.profession === "string" ? formData.profession : formData.profession.displayName,
        },
      },
    })
      .then((res) => {
        if (isCreate) navigate(`/${ROUTES.KNOWLEDGE_BLOCKS}/view/${res.data?.createOrUpdateKnowledgeBlock.id}`);
        setIsEditing(false);
        addToast({ type: ToastTypeEnum.SUCCESS });
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const [deleteKnowledgeBlock] = useDeleteKnowledgeBlockMutation();

  const handleDeleteKnowledgeBlock = async () => {
    await deleteKnowledgeBlock({
      variables: {
        knowledgeBlockId: knowledgeBlock?.id!,
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS, text: "Блок знаний успешно удален" });
        navigate(`/${ROUTES.KNOWLEDGE_BLOCKS}`);
      })
      .catch(() => {
        addToast({ type: ToastTypeEnum.ERROR });
      });
  };

  const handleOpenConfirmDeleteModal = () => {
    handleOpen({
      title: "Вы уверены, что хотите удалить блок знаний?",
      actionButtonText: "Удалить",
      handleClick: handleDeleteKnowledgeBlock,
    });
  };

  return (
    <Card>
      <Form form={form}>
        <Stack spacing={2.5}>
          <TextFieldControl
            name='title'
            label='Название'
            disabled={!isEditing}
            rules={{ ...VALIDATION.competencyMapTitle, required: true }}
          />
          <AutocompleteControl
            options={data?.getUniqProfession || []}
            loading={loading}
            name='profession'
            label='Профессия'
            freeSolo
            onInputChange={onProfessionInputChange}
            disabled={!isEditing}
            rules={{ required: true }}
          />
          <TextFieldControl
            name='grades'
            label='Грейд'
            disabled
          />
          <DatePickerControl
            name='createdAt'
            label='Дата создания'
            disabled
          />
          <DatePickerControl
            name='updatedAt'
            label='Дата изменения'
            disabled
          />
          <SaveEditButtons
            isEdited={isEditing}
            onSubmit={handleSubmit(onSubmit)}
            setIsEdited={setIsEditing}
            loading={updateLoading}
            deleteText='Удалить блок знаний'
            onDelete={handleOpenConfirmDeleteModal}
          />
        </Stack>
      </Form>
    </Card>
  );
};

export default Info;
