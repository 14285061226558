const ROUTES = {
  LOGIN: "login",
  USER_REGISTER: "user-register",
  COMPANY_REGISTER: "company-register",
  COMPANY: "company",
  PROFILE: "profile",
  COMPETENCE_MAPS: "competency-maps",
  KNOWLEDGE_BLOCKS: "knowledge-blocks",
  TESTING: "testing",
  SKILL: "skill",
};

export default ROUTES;
