import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import Form from "components/form/Form";
import { Card, Stack } from "@mui/material";
import TextFieldControl from "components/form/TextFieldControl";
import {
  KnowledgeBlockOptionsQuery,
  SkillQuery,
  useKnowledgeBlockOptionsQuery,
  useCreateOrUpdateSkillMutation,
  useDeleteSkillMutation,
} from "@generated/graphql";
import DatePickerControl from "components/form/DatePickerControl";
import SaveEditButtons from "components/buttons/SaveEditButtons";
import AutocompleteControl from "components/form/AutocompleteControl";
import useIsCreate from "hooks/useIsCreate";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectControl from "components/form/SelectControl";
import qs from "qs";
import { GRADE_OPTIONS } from "constants/global";
import ROUTES from "constants/routes";
import { ConfirmModalContext } from "context/confirmModalContext";
import VALIDATION from "helpers/validation";
import { cleanSpaceInString } from "helpers/validation/createValidation";

type SkillInfoInputs = {
  id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  knowledgeBlock?: { id: string; title: string };
  skillGrades?: string[];
};

type KnowledgeBlockOptions = NonNullable<KnowledgeBlockOptionsQuery["knowledgeBlocks"]["data"]>[number];

const Info = ({ skill }: { skill: SkillQuery["skill"] | null | undefined }) => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { knowledgeBlockId, knowledgeBlockTitle } = params as unknown as {
    knowledgeBlockId?: string;
    knowledgeBlockTitle?: string;
  };

  const { addToast } = useContext(ToastContext);
  const { handleOpen } = useContext(ConfirmModalContext);
  const navigate = useNavigate();
  const isCreate = useIsCreate();
  const [isEditing, setIsEditing] = useState(isCreate);

  const { data: KnowledgeBlockData, loading: knowledgeBlockLoading } = useKnowledgeBlockOptionsQuery();

  const form = useForm<SkillInfoInputs>({
    values: {
      id: skill?.id!,
      title: skill?.title!,
      createdAt: skill?.createdAt,
      updatedAt: skill?.updatedAt,
      knowledgeBlock:
        isCreate && knowledgeBlockId && knowledgeBlockTitle
          ? { id: knowledgeBlockId, title: knowledgeBlockTitle }
          : skill?.knowledgeBlock,
      skillGrades: skill?.grades || [],
    },
  });
  const { handleSubmit } = form;

  const [updateSkill, { loading: updateLoading }] = useCreateOrUpdateSkillMutation();
  const onSubmit = (formData: SkillInfoInputs) => {
    updateSkill({
      variables: {
        input: {
          id: skill?.id,
          title: cleanSpaceInString(formData.title),
          knowledgeBlockId: formData.knowledgeBlock?.id!,
        },
      },
    })
      .then((res) => {
        if (isCreate) navigate(`/${ROUTES.SKILL}/view/${res.data?.createOrUpdateSkill.id}`);
        setIsEditing(false);
        addToast({ type: ToastTypeEnum.SUCCESS });
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const [deleteSkill] = useDeleteSkillMutation();

  const handleDeleteSkill = async () => {
    await deleteSkill({
      variables: {
        skillId: skill?.id!,
      },
    })
      .then(() => {
        addToast({ type: ToastTypeEnum.SUCCESS, text: "Навык успешно удален" });
        navigate(`/${ROUTES.KNOWLEDGE_BLOCKS}/view/${skill?.knowledgeBlock.id}`);
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const handleOpenConfirmDeleteSkillModal = () => {
    handleOpen({
      title: "Вы уверены, что хотите удалить навык?",
      actionButtonText: "Удалить",
      handleClick: handleDeleteSkill,
    });
  };

  return (
    <Card>
      <Form form={form}>
        <Stack spacing={2.5}>
          <TextFieldControl
            name='title'
            label='Название'
            disabled={!isEditing}
            rules={{ required: true, ...VALIDATION.competencyMapTitle }}
          />
          <AutocompleteControl<KnowledgeBlockOptions>
            options={KnowledgeBlockData?.knowledgeBlocks.data || []}
            name='knowledgeBlock'
            label='Блок знаний'
            loading={knowledgeBlockLoading}
            optionName='title'
            disabled={!isEditing}
          />
          <SelectControl
            items={GRADE_OPTIONS}
            name='skillGrades'
            label='Грейд'
            labelName='displayName'
            valueName='id'
            disabled
            multiple
            sx={{
              backgroundColor: "bgSwitch",
            }}
          />
          <DatePickerControl
            name='createdAt'
            label='Дата создания'
            disabled
          />
          <DatePickerControl
            name='updatedAt'
            label='Дата изменения'
            disabled
          />
          <SaveEditButtons
            isEdited={isEditing}
            onSubmit={handleSubmit(onSubmit)}
            setIsEdited={setIsEditing}
            loading={updateLoading}
            deleteText='Удалить навык'
            onDelete={handleOpenConfirmDeleteSkillModal}
          />
        </Stack>
      </Form>
    </Card>
  );
};

export default Info;
