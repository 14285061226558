import React, { ReactNode } from "react";
import { Divider, Fab, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { KeyboardArrowUp } from "@mui/icons-material";
import ScrollTop from "components/ScrollTop";
import MarkdownFormatter from "components/MarkdownFormatter";
import { green, red, lime, yellow, orange } from "@mui/material/colors";
import { KnowledgeBlocks } from "pages/Testing/view/components/Personal/ResultTab";
import getTrialAnswerList from "pages/Testing/view/components/Personal/ResultTab/helpers/getTrialAnswerList";
import CollapsedBlock from "../CollapsedBlock";
import { Decision } from "./RecomendationBlock";

interface CandidateAnswersProps {
  knowledgeBlocks: KnowledgeBlocks;
}

const Block = ({ title, data }: { title: string; data: string | number | ReactNode }) => (
  <Stack
    direction='row'
    spacing={2}>
    <Box
      height='fit-content'
      width={130}
      sx={{
        backgroundColor: "white",
        borderRadius: 1,
        boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)",
        p: 1,
      }}>
      <Typography>{title}</Typography>
    </Box>
    <Box
      flex={1}
      sx={{
        px: 1.5,
        py: 1,
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
      }}>
      <Typography sx={{ whiteSpace: "pre-wrap", wordWrap: "anywhere" }}>{data}</Typography>
    </Box>
  </Stack>
);

const CandidateAnswers = ({ knowledgeBlocks }: CandidateAnswersProps) => {
  const getDecisionResult = (decision: Decision): { text: string; color: string } => {
    if (decision === Decision.correct) return { text: "100%", color: green[900] };
    if (decision === Decision.almost_correct) return { text: "75%", color: lime[900] };
    if (decision === Decision.partial_correct) return { text: "50%", color: yellow[600] };
    if (decision === Decision.partial_incorrect) return { text: "25%", color: orange[900] };
    return { text: "0%", color: red[900] };
  };

  const answers = getTrialAnswerList(knowledgeBlocks);

  return (
    <CollapsedBlock title='Ответы кандидата'>
      {answers?.map((answer, index) => {
        const fields = [
          {
            title: "Компетенция",
            data: answer.blockTitle,
          },
          {
            title: "Блок знаний",
            data: answer.knowledgeBlockTitle,
          },
          {
            title: "Навык",
            data: answer.skillTitle,
          },
          {
            title: "Вопрос",
            data: <MarkdownFormatter text={answer.questionText} />,
          },
          {
            title: "Ответ",
            data: answer.candidateAnswer,
          },
          {
            title: "Оценка ответа",
            data: answer.score?.toFixed(),
          },
          {
            title: "Время ответа",
            data: answer.time ? new Date(answer.time * 1000).toISOString().substring(11, 19) : "",
          },
        ];

        return (
          <Grid
            container
            item
            xs={12}
            sx={{ py: 1.5 }}>
            <Grid
              container
              rowSpacing={2}>
              <Grid
                item
                xs={12}
                lg={8}>
                <Stack spacing={2}>
                  {fields.map((field, key) => (
                    <Block
                      // eslint-disable-next-line react/no-array-index-key
                      key={`answer-block-${key}`}
                      title={field.title}
                      data={field.data!}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}>
                <Table sx={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "white",
                      }}>
                      <TableCell sx={{ borderTopLeftRadius: 8 }}>Критерии оценки</TableCell>
                      <TableCell>Соответствие</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {answer.criterionAssessments?.map((criteria, key) => {
                      const decision = getDecisionResult(criteria.decision as Decision);
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableRow key={`answer-criteria-${key}`}>
                          <TableCell>{criteria.criteria}</TableCell>
                          <TableCell>
                            <Typography
                              variant='body14rg'
                              sx={{ color: decision.color }}>
                              {decision.text}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            {answers?.length < index + 1 && <Divider />}
          </Grid>
        );
      })}
      <ScrollTop>
        <Fab
          size='small'
          aria-label='scroll back to top'>
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </CollapsedBlock>
  );
};

export default CandidateAnswers;
