import { useParams } from "react-router-dom";
import {
  Examination_Type,
  ExaminationHeaderQuery,
  Trial_Status,
  useCancelExaminationTrialsMutation,
} from "@generated/graphql";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext, useState } from "react";
import useIsCreate from "hooks/useIsCreate";
import TestingStatus from "components/TestingStatus";
import useDownloadReport from "hooks/useDownloadReport";
import { ConfirmModalContext } from "context/confirmModalContext";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import useSetTabTitle from "hooks/useSetTabTitle";
import { LoadingButton } from "@mui/lab";
import withLanguagePopover from "hocs/withLanguagePopover";
import LanguageIcon from "assets/icons/LanguageIcon";
import { TESTING_TYPES_DATA } from "constants/global";
import AddCandidateModal from "./AddCandidateModal";

const LoadingButtonWithLanguage = withLanguagePopover(LoadingButton);

interface HeaderProps {
  examination: ExaminationHeaderQuery["examination"] | undefined;
  examinationType: Examination_Type | undefined;
  onSubmitAddCandidateModal: (selected: string[]) => void;
}

const Header = ({ examination, examinationType, onSubmitAddCandidateModal }: HeaderProps) => {
  const { handleOpen } = useContext(ConfirmModalContext);
  const { addToast } = useContext(ToastContext);
  const isCreate = useIsCreate();
  const { examinationId } = useParams();
  const status = examination?.trials?.[0]?.status;

  const [isWithExplanations, setIsWithExplanations] = useState<boolean>(false);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWithExplanations(event.target.checked);
  };

  const { onDownloadReport, isLoading } = useDownloadReport(
    examinationId!,
    isWithExplanations,
    examination?.trials?.[0]?.companyCandidate?.fullName,
    examination?.trials?.[0]?.completedAt
  );

  const isCompleted = status === Trial_Status.Completed;
  const isCanCancel = status === Trial_Status.Created;

  const [cancelExamination] = useCancelExaminationTrialsMutation();

  const onCancel = () => {
    handleOpen({
      title: "Вы уверены, что хотите отменить тестирование?",
      description: "Кандидат не сможет приступить к тестированию после отмены.",
      actionButtonText: "Подтвердить",
      handleClick: async () =>
        cancelExamination({
          variables: {
            examinationId: examinationId!,
          },
        })
          .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
          .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
    });
  };

  const onAddCandidate = () => {
    setIsCandidateModalOpen(true);
  };

  const title = `Тестирование ${examination?.numericId ? ` ID ${examination?.numericId}` : ""}`;
  useSetTabTitle(title, examination?.numericId);

  const examinationTypeData = TESTING_TYPES_DATA.find((data) => data.type === examinationType);

  const isPersonalExamination = examinationType === Examination_Type.Personal;

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        mb={2.5}
        spacing={2}>
        <Stack
          direction='row'
          alignItems='flex-start'
          spacing={2}>
          <Typography variant='h28'>{title}</Typography>
          {Boolean(examinationTypeData) && (
            <Typography
              variant='colored'
              sx={{ backgroundColor: examinationTypeData?.color }}>
              {examinationTypeData?.title || ""}
            </Typography>
          )}
        </Stack>
        <Stack
          direction='row'
          spacing={2}
          alignItems='baseline'>
          {!isCreate && (
            <>
              {status && isPersonalExamination && <TestingStatus status={status} />}
              <Button
                variant='contained'
                color='secondary'
                disabled={isPersonalExamination ? !isCanCancel : false}
                onClick={onCancel}
                sx={{ backgroundColor: "white", boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)" }}>
                Отменить тестирование
              </Button>
            </>
          )}
          {examinationType === Examination_Type.Group && (
            <Button
              variant='contained'
              color='primary'
              onClick={onAddCandidate}>
              Добавить кандидата
            </Button>
          )}
          {!isCreate && isPersonalExamination && (
            <Stack spacing={1}>
              <LoadingButtonWithLanguage
                loading={isLoading}
                variant='contained'
                color='primary'
                disabled={!isCompleted}
                sx={{ gap: "6px" }}
                setLanguage={onDownloadReport}
                popoverProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                  slotProps: { paper: { sx: { marginLeft: "-28px" } } },
                }}>
                Выгрузить отчет
                <LanguageIcon />
              </LoadingButtonWithLanguage>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isWithExplanations}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label='Расширенный отчет'
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      {isCandidateModalOpen && (
        <AddCandidateModal
          open={isCandidateModalOpen}
          onClose={() => setIsCandidateModalOpen(false)}
          onSubmit={onSubmitAddCandidateModal}
        />
      )}
    </>
  );
};

export default Header;
