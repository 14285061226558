import { formatNumber } from "helpers/index";
import { CreateValidationParams, CreateValidationReturn } from "./types";

const cleanSpaceInString = (input: string): string => input.trim().replace(/\s+/g, " ");

const isOnlySpaces = (value: string): boolean => /^\s+$/.test(value);

const createValidation = ({
  pattern,
  maxLength,
  maxLengthMessage,
  minLength,
  minLengthMessage,
  onlySpacesMessage = "Поле не должно состоять только из пробелов",
}: CreateValidationParams): CreateValidationReturn => ({
  validate: (value: string): string | boolean => {
    if (isOnlySpaces(value)) return onlySpacesMessage;
    if (value && !pattern.value.test(value)) return pattern.message;
    return true;
  },
  ...(maxLength && {
    maxLength: {
      value: maxLength,
      message:
        maxLengthMessage || `Длина строки превышает ${formatNumber(["символ", "символа", "символов"], maxLength)}`,
    },
  }),
  ...(minLength && {
    minLength: {
      value: minLength,
      message:
        minLengthMessage ||
        `Длина строки должна быть минимум ${formatNumber(["символ", "символа", "символов"], minLength)}`,
    },
  }),
});

export { createValidation, isOnlySpaces, cleanSpaceInString };
