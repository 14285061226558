import Form from "components/form/Form";
import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import EmptyList from "components/EmptyList";
import Info from "pages/CompetencyMaps/view/components/Info";
import PlusIcon from "assets/icons/PlusIcon";
import { Link } from "react-router-dom";
import ROUTES from "constants/routes";
import CopyMapModal from "pages/CompetencyMaps/view/components/CopyMapModal";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import CompetenciesList from "pages/CompetencyMaps/view/components/CompetenciesList";
import withDragDropContext from "hocs/withDragDropContext";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { CompetencyMapQuery } from "@generated/graphql";
import { CompetencyMapInputs } from "pages/CompetencyMaps/view/components/types";

const CompetenciesListWithDragDropContext = withDragDropContext(CompetenciesList);

interface IConstructorTabProps {
  fieldArray: UseFieldArrayReturn<CompetencyMapInputs, "knowledgeBlocks", "formId">;
  form: UseFormReturn<CompetencyMapInputs>;
  isEditable: boolean;
  setIsEditable: Dispatch<SetStateAction<boolean>>;
  competencyMap: CompetencyMapQuery["competencyMap"];
  disabled: boolean;
}

const ConstructorTab = ({
  fieldArray,
  form,
  isEditable,
  setIsEditable,
  competencyMap,
  disabled,
}: IConstructorTabProps) => {
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const blockContainerRef = useRef<HTMLDivElement>(null);
  const infoCardRef = useRef<HTMLDivElement>(null);

  const { fields, move, remove } = fieldArray;

  const [openCopyModal, setOpenCopyModal] = useState<boolean>(false);

  const handleCopyMap = () => {
    setOpenCopyModal(true);
  };

  useEffect(() => {
    if (blockContainerRef.current) {
      // TODO происходит скролл при ините, тк есть состояние между загрузкой и заполнением filds
      const currentScrollHeight = blockContainerRef.current.scrollHeight;
      if (currentScrollHeight > scrollHeight && scrollHeight !== 0) {
        blockContainerRef.current.scrollTo({
          top: blockContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
      setScrollHeight(blockContainerRef.current.scrollHeight);
    }
  }, [fields.length]);

  const originalCompetencyMap = competencyMap?.originalCompetencyMap;
  const infoCardHeight = infoCardRef.current?.clientHeight;
  return (
    <>
      <Form form={form}>
        <Grid
          container
          columnSpacing={2.5}>
          <Grid
            item
            xs={12}
            md={9}>
            <Stack
              direction='column'
              sx={{
                height: "100%",
                maxHeight: `${infoCardHeight ? `${infoCardHeight}px` : "auto"}`,
                overflow: "auto",
              }}
              spacing={2.5}
              ref={blockContainerRef}>
              {!fields.length && !isEditable ? (
                <EmptyList emptyPlaceholderText='Пока нет компетенций' />
              ) : (
                <CompetenciesListWithDragDropContext
                  droppableId='competenciesList'
                  moveItems={move}
                  fields={fields}
                  form={form}
                  remove={remove}
                  isEditable={isEditable}
                />
              )}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}>
            <Stack spacing={2.5}>
              <Info
                form={form}
                disabled={disabled}
                isEditable={isEditable}
                infoCardRef={infoCardRef}
                setIsEditable={setIsEditable}
              />
              <Button
                startIcon={<PlusIcon sx={{ width: 16, height: 16 }} />}
                sx={({ typography }) => ({
                  px: 0,
                  justifyContent: "flex-end",
                  color: "base.200",
                  ...typography.body14rg,
                })}
                color='primary'
                onClick={handleCopyMap}>
                дублировать карту
              </Button>
              {originalCompetencyMap && (
                <Card>
                  <Typography variant='body16rg'>
                    Является дубликатом карты{" "}
                    <Link to={`/${ROUTES.COMPETENCE_MAPS}/view/${originalCompetencyMap.id}`}>
                      {originalCompetencyMap.title}
                    </Link>
                  </Typography>
                </Card>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
      {openCopyModal && competencyMap && (
        <CopyMapModal
          open={openCopyModal}
          onClose={() => setOpenCopyModal(false)}
          originalMap={{ id: competencyMap.id, title: competencyMap.title }}
        />
      )}
    </>
  );
};

export default ConstructorTab;
