import { useTableConstructor } from "layout/TableConstructor/context/tableConstructorContext";
import { Identifiable } from "layout/TableConstructor/types";
import CustomModal from "components/modals/CustomModal";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import Checkbox from "components/form/CheckboxControl/components/Checkbox";
import { useLocation } from "react-router-dom";

interface ColumnSettingsModalProps {
  open: boolean;
  onClose: () => void;
}

const ColumnSettingsModal = <T extends Identifiable, AvailableFilters extends object = object>(
  props: ColumnSettingsModalProps
) => {
  const location = useLocation();
  const { open, onClose } = props;
  const { visibleColumnList, setVisibleColumnList, columnConfigList, setVisibleFilterList, columnFilterMap } =
    useTableConstructor<T, AvailableFilters>();

  const [selectedColumnList, setSelectedColumnList] = useState<string[]>(visibleColumnList);

  const onSaveColumnSettings = () => {
    setVisibleColumnList(selectedColumnList);
    const visibleFilterList = selectedColumnList.reduce((acc: (keyof AvailableFilters | string)[], columnName) => {
      const filterName = columnFilterMap[columnName];
      if (filterName) acc.push(filterName);
      return acc;
    }, []);
    setVisibleFilterList(visibleFilterList);
    localStorage.setItem(location.pathname.replace("/", ""), JSON.stringify(selectedColumnList));
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title='Список карт компетенций'
      buttonText='Применить'
      handleClick={onSaveColumnSettings}
      content={
        <Stack spacing={1}>
          {columnConfigList.map((column) => (
            <Checkbox
              sx={{ ml: 0 }}
              key={column.name}
              value={selectedColumnList.includes(column.name)}
              handleChange={(checked) => {
                if (checked) {
                  setSelectedColumnList((prev) => [...prev, column.name]);
                } else {
                  setSelectedColumnList((prev) => prev.filter((filterName) => filterName !== column.name));
                }
              }}
              label={column.title}
            />
          ))}
        </Stack>
      }
    />
  );
};

export default ColumnSettingsModal;
