import { UseFormReturn } from "react-hook-form";
import { InfoInputs } from "../types";
import CompetencyMapInfo from "./CompetencyMapInfo";
import TrialInfo from "./TrialInfo";
import { CompetencyMapOptionList } from "../../../types";

interface InfoTabRightProps {
  competencyMaps: CompetencyMapOptionList;
  competencyMapsLoading: boolean;
  isEditing: boolean;
  form: UseFormReturn<InfoInputs>;
}

const InfoTabRight = ({ competencyMaps, competencyMapsLoading, isEditing, form }: InfoTabRightProps) => {
  return (
    <>
      <CompetencyMapInfo
        competencyMaps={competencyMaps}
        isEditing={isEditing}
        competencyMapsLoading={competencyMapsLoading}
      />
      <TrialInfo form={form} />
    </>
  );
};

export default InfoTabRight;
