import AutocompleteControl from "components/form/AutocompleteControl";
import { useFormContext } from "react-hook-form";
import { AutocompleteInputChangeReason } from "@mui/material";
import { CompetencyMapOption, CompetencyMapOptionList, ICompetencyMapOption } from "./types";

interface CompetencyMapControlProps {
  isEditing: boolean;
  competencyMapsLoading: boolean;
  competencyMaps: CompetencyMapOptionList;
}

const CompetencyMapControl = ({ isEditing, competencyMapsLoading, competencyMaps }: CompetencyMapControlProps) => {
  const { setValue, reset, getValues } = useFormContext();
  const onCompetencyMapChange = (data: CompetencyMapOption | ICompetencyMapOption | null) => {
    if (data && typeof data === "object" && "comment" in data) {
      setValue("competencyMap.comment", data.comment || "");
      setValue("competencyMap.grades", data?.grades?.join(", "));
    }
    return data ? { id: data.id, title: data.title } : data;
  };

  const resetCompetencyMapFields = () => {
    reset({
      ...getValues(),
      competencyMap: {
        id: "",
        title: "",
        grades: "",
        comment: "",
      },
    });
  };

  const onAutocompleteInputChange = (reason: AutocompleteInputChangeReason) => {
    if (reason === "clear") {
      resetCompetencyMapFields();
    }
  };

  return (
    <AutocompleteControl<CompetencyMapOption, false, false, false, ICompetencyMapOption>
      options={competencyMaps || []}
      name='competencyMap'
      optionName='title'
      label='Карта компетенций'
      disabled={!isEditing}
      loading={competencyMapsLoading}
      rules={{ required: true }}
      formatDataBeforeOnChange={onCompetencyMapChange}
      onInputChange={(_, inputValue, reason) => onAutocompleteInputChange(reason)}
    />
  );
};

export default CompetencyMapControl;
