import { styled, SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

interface ExpandMoreProps extends SvgIconProps {
  expand: boolean;
}

const ExpandMoreButton = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon
      inheritViewBox
      sx={{ width: 16, height: 16 }}
      {...other}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='13'
        height='8'
        viewBox='0 0 13 8'
        fill='none'>
        <path
          d='M11.5469 6.72729L6.54688 1.7273L1.54688 6.72729'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
})(({ theme, expand }) => ({
  transform: expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMoreButton;
