import React from "react";
import TabLayout from "layout/TabLayout";
import { Exact, SkillQuery } from "@generated/graphql";
import { QueryResult } from "@apollo/client";
import QuestionList from "./components/QuestionList";

export type QuestionsProps = {
  skillQuery: QueryResult<SkillQuery, Exact<{ skillId: string }>>;
};

const Questions = ({ skillQuery }: QuestionsProps) => {
  const TABS = [
    {
      id: "confirmed",
      title: "Подтвержденные вопросы",
      component: (
        <QuestionList
          isConfirmed
          skillQuery={skillQuery}
        />
      ),
    },
    {
      id: "unconfirmed",
      title: "Неподтвержденные вопросы",
      component: <QuestionList skillQuery={skillQuery} />,
    },
  ];
  return (
    <TabLayout
      tabs={TABS}
      defaultTab='confirmed'
    />
  );
};

export default Questions;
