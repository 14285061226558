import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'>
      <path
        // eslint-disable-next-line max-len
        d='M21.3498 6.66175C20.8948 6.20675 20.1598 6.20675 19.7048 6.66175L13.9998 12.3551L8.29484 6.65009C7.83984 6.19509 7.10484 6.19509 6.64984 6.65009C6.19484 7.10509 6.19484 7.84009 6.64984 8.29509L12.3548 14.0001L6.64984 19.7051C6.19484 20.1601 6.19484 20.8951 6.64984 21.3501C7.10484 21.8051 7.83984 21.8051 8.29484 21.3501L13.9998 15.6451L19.7048 21.3501C20.1598 21.8051 20.8948 21.8051 21.3498 21.3501C21.8048 20.8951 21.8048 20.1601 21.3498 19.7051L15.6448 14.0001L21.3498 8.29509C21.7932 7.85176 21.7932 7.10509 21.3498 6.66175Z'
        fill='#9E9E9E'
      />
    </svg>
  </SvgIcon>
);

export default CloseIcon;
