import { Stack, SxProps, Tooltip, tooltipClasses, Typography } from "@mui/material";
import KeyIcon from "assets/icons/KeyIcon";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import React, { useContext } from "react";

const SecretCompanyCode = ({ code, sx }: { code: string; sx?: SxProps }) => {
  const { addToast } = useContext(ToastContext);
  const onCopyText = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS, text: "Код успешно скопирован" }));
  };
  return (
    <Tooltip
      title='Код&nbsp;компании для&nbsp;доступа кандидатов к чат-боту'
      arrow
      componentsProps={{
        popper: {
          sx: {
            [`&[data-popper-placement*="bottom"] > .${tooltipClasses.tooltip}`]: {
              marginTop: "0px",
            },
            [`.${tooltipClasses.tooltip}`]: {
              maxWidth: 105,
              textAlign: "center",
            },
          },
        },
      }}>
      <Stack
        onClick={onCopyText}
        direction='row'
        alignItems='center'
        sx={{ cursor: "pointer", ...sx }}>
        <KeyIcon sx={{ width: 32, height: 32, mr: 1 }} />
        <Typography variant='body16rg'>{code}</Typography>
      </Stack>
    </Tooltip>
  );
};

export default SecretCompanyCode;
