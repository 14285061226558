import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Order } from "layout/ListLayout/types";
import { Data, HeadCell } from "../types";

const headCells: readonly HeadCell[] = [
  {
    id: "numericId",
    label: "ID",
  },
  {
    id: "fullName",
    label: "ФИО",
  },
  {
    id: "nickname",
    label: "Ник",
  },
  {
    id: "createdAt",
    label: "Дата создания",
  },
];

interface HeadProps {
  onRequestSort: (property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

const Head = ({ order, orderBy, onRequestSort }: HeadProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding='checkbox'
          sx={{ backgroundColor: "bgSwitch", color: "primary.main" }}>
          Выбор
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              whiteSpace: "nowrap",
              backgroundColor: "bgSwitch",
              "& .MuiTableSortLabel-root.Mui-active": { color: "primary.main" },
            }}>
            <TableSortLabel
              active
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={() => onRequestSort(headCell.id)}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default Head;
