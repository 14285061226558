import React, { createContext, FC, ReactNode, useCallback, useMemo, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

export type ToastContextProps = {
  addToast: ({ text, type }: ToastType) => void;
};

export const ToastContext = createContext<ToastContextProps>({
  addToast: () => {},
});

export enum ToastTypeEnum {
  SUCCESS = "success",
  ERROR = "error",
}

export type ToastType = { text?: string; type: AlertColor };

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [popUp, setPopUp] = useState(false);
  const [toast, setToast] = useState<ToastType>({ type: ToastTypeEnum.SUCCESS });

  const addToast = useCallback(
    ({ text, type }: ToastType) => {
      setPopUp(true);
      if (!text) {
        switch (type) {
          case ToastTypeEnum.SUCCESS: {
            setToast({ text: "Успешно сохранено", type });
            break;
          }
          case ToastTypeEnum.ERROR:
          default: {
            setToast({ text: "Произошла ошибка. Данные не сохранены", type });
            break;
          }
        }
      } else {
        setToast({ text, type });
      }
    },
    [setPopUp]
  );

  const providerValue = useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
      <Snackbar
        open={popUp}
        autoHideDuration={5000}
        onClose={() => setPopUp(false)}
        sx={{ marginTop: 10, background: "white", borderRadius: "10px" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert
          variant='outlined'
          severity={toast.type}
          onClose={() => setPopUp(false)}
          sx={{
            height: "auto",
            "& .MuiAlert-message": {
              py: 1,
              lineHeight: 1.5,
            },
            "& .MuiAlert-icon": {
              py: 1,
            },
          }}>
          {toast.text}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
