import { useMemo, useState } from "react";
import useIsCreate from "hooks/useIsCreate";
import CircularLoading from "components/CircularLoading";
import { Competency_Map_Status, useCompetencyMapOptionsQuery, useExaminationInfoQuery } from "@generated/graphql";
import Box from "@mui/material/Box";
import SaveEditButtons from "components/buttons/SaveEditButtons";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import Form from "components/form/Form";
import TrialHistory from "pages/Testing/view/components/Personal/InfoTab/components/TrialHistory";
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import useInitTrialForm from "./hooks/useInitTrialForm";
import useUpdateCandidate from "./hooks/useUpdateCandidate";
import { InfoInputs } from "./types";
import InfoBlock from "../../InfoBlock";
import InfoTabLeft from "./components/Left";
import InfoTabRight from "./components/Right";

const InfoTab = () => {
  const { examinationId } = useParams();
  const isCreate = useIsCreate();
  const [isEditing, setIsEditing] = useState<boolean>(isCreate);

  const { data, loading } = useExaminationInfoQuery({
    variables: {
      examinationId: examinationId!,
    },
    skip: !examinationId,
  });

  const { data: competencyMapsData, loading: competencyMapsLoading } = useCompetencyMapOptionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        status: Competency_Map_Status.Approved,
      },
    },
  });

  const trial = data?.examination.trials?.[0];
  const competencyMaps = competencyMapsData?.competencyMaps.data;

  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { mapId } = params as unknown as { mapId?: string };
  const competencyMap = mapId ? competencyMaps?.find((map) => map.id === mapId) : data?.examination.competencyMap;

  const initFormData = useInitTrialForm(trial, competencyMap);

  const form = useForm<InfoInputs>({
    values: initFormData,
  });
  const { handleSubmit } = form;

  const { onUpdateCompanyCandidate, updateCompanyCandidateLoading, updateTrialLoading, createExaminationLoading } =
    useUpdateCandidate({
      setIsEditing,
    });

  if (loading || competencyMapsLoading) return <CircularLoading />;

  return (
    <Box sx={{ py: 2 }}>
      <Form form={form}>
        <Grid
          container
          rowSpacing={2.5}>
          <InfoBlock
            item
            LeftElement={
              <InfoTabLeft
                isEditing={isEditing}
                form={form}
              />
            }
            RightElement={
              <InfoTabRight
                competencyMaps={competencyMaps}
                competencyMapsLoading={competencyMapsLoading}
                isEditing={isEditing}
                form={form}
              />
            }
            ControlsElement={
              <SaveEditButtons
                isEdited={isEditing}
                onSubmit={handleSubmit(onUpdateCompanyCandidate)}
                setIsEdited={setIsEditing}
                loading={updateTrialLoading || createExaminationLoading || updateCompanyCandidateLoading}
              />
            }
          />
          <Grid
            item
            xs={12}>
            <TrialHistory trial={trial} />
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default InfoTab;
