import { SvgIcon, SvgIconProps } from "@mui/material";

const AccordionIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.457 9.22723L11.457 15.2272L5.45703 9.22723'
        stroke='#A9A9A9'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </SvgIcon>
);

export default AccordionIcon;
