import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { formatISODate } from "helpers/date";
import { formatNumber } from "helpers/index";
import React from "react";
import { TrialResultQuery } from "@generated/graphql";
import BulletList from "components/BulletList";

interface FactsProps {
  facts: NonNullable<TrialResultQuery["trial"]>["trialRepositoryChecks"][number]["facts"];
}

const Facts = ({ facts }: FactsProps) => {
  const firstReleaseDate = facts?.first_release_date;
  const mainLanguage = facts?.main_language;
  const developersCount = facts?.developers_count ?? 1;
  const forksCount = facts?.forks_count ?? 0;
  const languages: [string, number][] = facts?.languages ? Object.entries(facts?.languages) : [];
  const starsCount = facts?.stars_count ?? 0;
  return (
    <Box sx={{ boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)", borderRadius: 1, p: 1.5 }}>
      <Typography
        variant='body14rg'
        sx={{ fontWeight: 600, mb: 1 }}>
        Факты об этом репозитории:
      </Typography>
      <BulletList>
        <li>{`Дата первого релиза: ${formatISODate(firstReleaseDate)}`}</li>
        <li>{`Оценка была произведена по коду на ${mainLanguage}`}</li>
        <li>{`Количество разработчиков: ${developersCount}`}</li>
        <li>{`Количество форков: ${Intl.NumberFormat().format(forksCount)}`}</li>
        <li>
          Языки програмирования:
          <BulletList>
            {languages.map((language: [string, number]) => (
              <li key={`language-${language[0]}}`}>{`${language[0]}: ${language[1] * 100}%`}</li>
            ))}
          </BulletList>
        </li>
        <li>{`Репозиторий имеет оценку ${formatNumber(["звезда", "звезды", "звезд"], starsCount)} на GitHub`}</li>
      </BulletList>
    </Box>
  );
};

export default Facts;
