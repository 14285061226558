import TextFieldControl from "components/form/TextFieldControl";
import { CompetencyMapOptionList } from "../../../types";
import CompetencyMapControl from "../../../CompetencyMapControl";

interface ICompetencyMapInfoProps {
  competencyMaps: CompetencyMapOptionList;
  isEditing: boolean;
  competencyMapsLoading: boolean;
}

const CompetencyMapInfo = ({ competencyMaps, isEditing, competencyMapsLoading }: ICompetencyMapInfoProps) => {
  return (
    <>
      <CompetencyMapControl
        competencyMaps={competencyMaps}
        isEditing={isEditing}
        competencyMapsLoading={competencyMapsLoading}
      />
      <TextFieldControl
        name='competencyMap.comment'
        label='Комментарий к карте компетенций'
        minRows={7}
        multiline
        disabled
      />
      <TextFieldControl
        name='competencyMap.grades'
        label='Грейд карты компетенций'
        disabled
      />
    </>
  );
};

export default CompetencyMapInfo;
