import React from "react";
import { Typography } from "@mui/material";
import { TrialResultQuery } from "@generated/graphql";
import Result from "./Result";
import CollapsedBlock from "../../CollapsedBlock";

interface RepositoryCheckProps {
  trialRepositoryCheck: NonNullable<TrialResultQuery["trial"]>["trialRepositoryChecks"][number];
}

const RepositoryCheck = ({ trialRepositoryCheck }: RepositoryCheckProps) => {
  const { repoUrl } = trialRepositoryCheck;

  const getContent = () => {
    if (!repoUrl) {
      return <Typography>Кандидат не предоставил ссылку на репозиторий</Typography>;
    }
    if (trialRepositoryCheck.error) {
      return <Typography>Произошла ошибка проверки репозитория</Typography>;
    }
    return <Result trialRepositoryCheck={trialRepositoryCheck} />;
  };

  const Content = getContent();

  return <CollapsedBlock title='Детальные результаты по оценке кода кандидата'>{Content}</CollapsedBlock>;
};

export default RepositoryCheck;
