import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PlaceholderImage from "assets/images/placeholder-image.svg";

const EmptyList = ({ emptyPlaceholderText }: { emptyPlaceholderText: string }) => (
  <Stack
    spacing={3}
    alignItems='center'
    sx={{ width: "100%" }}>
    <Box
      component='img'
      src={PlaceholderImage}
      sx={{
        width: 300,
        height: 300,
      }}
    />
    <Typography variant='h20'>{emptyPlaceholderText}</Typography>
  </Stack>
);

export default EmptyList;
