import { Examination_Type } from "@generated/graphql";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "assets/icons/CloseIcon";
import { TESTING_TYPES_DATA } from "constants/global";
import ROUTES from "constants/routes";
import { NavigateFunction } from "react-router-dom";
import palette from "theme/palette";

export type TestingSelectModalProps = {
  open: boolean;
  onClose: () => void;
  navigate: NavigateFunction | null;
  mapId?: string;
};

// eslint-disable-next-line max-len
// navigate прокидывается через пропс, а не инициализируется с помощью useNavigate, так как модалка используется внутри testingSelectModalContext, в котором нет RouterProvider
const TestingSelectModal = ({ open, onClose, navigate, mapId }: TestingSelectModalProps) => {
  const handleCardActionClick = (type: Examination_Type) => {
    if (navigate) {
      let search = `?type=${type}`;
      if (mapId) search = `${search}&mapId=${mapId}`;
      navigate({ pathname: `/${ROUTES.TESTING}/create`, search });
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'>
      <Stack
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'>
        <Box />
        <DialogTitle
          variant='h5'
          sx={{ fontWeight: 500, paddingBottom: 0 }}>
          Выбор типа тестирований
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent>
        <Stack spacing={2}>
          {TESTING_TYPES_DATA.map((cardData) => (
            <Card
              key={`testing-modal-card-${cardData.type}`}
              sx={{ padding: 0 }}>
              <CardActionArea
                onClick={() => handleCardActionClick(cardData.type)}
                sx={{ backgroundColor: palette.bgSwitch }}>
                <CardContent sx={{ padding: 2.6 }}>
                  <Typography
                    gutterBottom
                    variant='colored'
                    sx={{ backgroundColor: cardData.color }}>
                    {cardData.title}
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{ color: "text.secondary" }}>
                    {cardData.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TestingSelectModal;
