import { InputType } from "components/generators/inputsGenerator";
import { GRADE_OPTIONS } from "constants/global";
import { Grade, KnowledgeBlocksQueryVariables } from "@generated/graphql";
import VALIDATION from "helpers/validation";
import { FilterField } from "layout/TableConstructor/types";
import dayjs from "dayjs";

type KnowledgeBlockFilter = NonNullable<KnowledgeBlocksQueryVariables["filter"]>;

const filterFields: FilterField<KnowledgeBlockFilter>[] = [
  {
    name: "createdAt",
    label: "Дата создания",
    type: InputType.DATE,
    customFilter: (value) => dayjs(value).toISOString(),
  },
  {
    name: "title",
    label: "Название блока знаний",
    type: InputType.TEXT,
    rules: VALIDATION.competencyMapTitle,
  },

  {
    name: "grades",
    label: "Грейд",
    type: InputType.AUTOCOMPLETE,
    options: GRADE_OPTIONS,
    customFilter: (grades: { id: string; displayName: Grade }[]) => grades.map((grade) => grade.id),
    limitTags: 1,
    multiple: true,
  },
];

export default filterFields;
