import React from "react";
import {
  AutocompleteValue,
  createFilterOptions,
  FilterOptionsState,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown } from "@mui/icons-material";
import { get, set } from "lodash";
import { IAutocompleteProps, IAutocompleteValue, NestedKeyOf } from "components/inputs/Autocomplete/types";

const Autocomplete = <
  Value extends Record<string, any> | string = IAutocompleteValue,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  TOption extends Record<string, any> | string = Value,
>({
  options = [],
  optionName = "displayName" as NestedKeyOf<Value>,
  multiple,
  disabled,
  onInputChange,
  placeholder,
  equalityParam = "id",
  loading,
  getOptionLabel,
  freeSolo,
  label,
  value,
  error,
  onChange,
  onChangeTextField,
  filterOptionsParams,
  formatDataBeforeOnChange,
  renderOption,
  sx,
}: IAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, TOption>) => {
  const filter = createFilterOptions<Value | TOption>({
    stringify: (option) => (typeof option === "string" ? option : (get(option, optionName) as string)),
  });

  return (
    <Stack spacing={0.5}>
      <Typography
        variant='body14rg'
        sx={{ color: "base.500" }}>
        {label}
      </Typography>
      <MuiAutocomplete<Value | TOption, Multiple, DisableClearable, FreeSolo>
        size='small'
        value={value}
        multiple={multiple}
        options={options || []}
        filterOptions={(existingOptions: (Value | TOption)[], params: FilterOptionsState<Value | TOption>) => {
          const currentParams = filterOptionsParams ? filterOptionsParams(params) : params;
          const filtered = filter(existingOptions, currentParams);

          if (freeSolo && params.inputValue !== "") {
            const isExisting = existingOptions.some(
              (option) => params.inputValue === get(option, optionName as string)
            );

            if (!isExisting && !loading) {
              const newOption = set(
                { newOption: `Добавить ${params.inputValue}` },
                optionName as string,
                params.inputValue
              );
              filtered.push(newOption as unknown as Value);
            }
          }

          return filtered;
        }}
        disabled={disabled}
        loading={loading}
        freeSolo={freeSolo}
        loadingText='Загрузка...'
        noOptionsText='Нет данных'
        getOptionLabel={(option) => {
          if (getOptionLabel) return getOptionLabel(option);

          if (typeof option === "object") return get(option, optionName) as string;
          return option;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder || label}
            helperText={error?.message}
            error={!!error}
            inputProps={{
              ...params.inputProps,
              onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                if (onChangeTextField) {
                  const newEvent = onChangeTextField(event);
                  params.inputProps.onChange?.(newEvent);
                } else params.inputProps.onChange?.(event as React.ChangeEvent<HTMLInputElement>);
              },
            }}
          />
        )}
        popupIcon={<KeyboardArrowDown sx={{ color: "base.200", width: 24, height: 24 }} />}
        renderOption={
          renderOption
            ? (optionProps, option, state, ownerState) => renderOption(optionProps, option, state, ownerState)
            : (optionProps, option) => {
                let text;
                if (typeof option === "object" && "newOption" in option) {
                  text = option.newOption;
                } else if (getOptionLabel) {
                  text = getOptionLabel(option);
                }
                if (typeof option === "string") {
                  text = option;
                } else {
                  text = get(option, optionName as string) as string;
                }
                return (
                  <li
                    {...optionProps}
                    key={typeof option === "string" ? option : `${get(option, equalityParam as string)}-${text}`}>
                    {text}
                  </li>
                );
              }
        }
        clearOnBlur
        isOptionEqualToValue={(option, inputValue) => {
          const optionData = typeof option === "string" ? option : get(option, equalityParam as string);
          const inputData = typeof inputValue === "string" ? inputValue : get(inputValue, equalityParam as string);
          return optionData === inputData;
        }}
        onChange={(_, data, reason) => {
          if (reason === "clear") {
            onChange(
              (multiple ? [] : null) as AutocompleteValue<Value | TOption, Multiple, DisableClearable, FreeSolo>
            );
          } else {
            const newData = formatDataBeforeOnChange ? formatDataBeforeOnChange(data) : data;
            onChange(newData);
          }
        }}
        onInputChange={onInputChange}
        sx={sx}
      />
    </Stack>
  );
};

export default Autocomplete;
