import { CompanyCandidatesQuery, Grade } from "@generated/graphql";
import { Dispatch, SetStateAction } from "react";
import { CompetencyMapInputs, LinkInput } from "../../types";

export type CandidateQueryItem = NonNullable<CompanyCandidatesQuery["companyCandidates"]["data"]>[number];
export type CompanyCandidate = NonNullable<
  NonNullable<CandidateQueryItem["candidateUsername"]>["companyCandidate"]
>[number];

export interface ICandidateItem {
  id: CandidateQueryItem["id"];
  fullName: CandidateQueryItem["fullName"];
  telegramUsername?: NonNullable<CandidateQueryItem["candidateUsername"]>["telegramUsername"];
  phoneNumber: NonNullable<NonNullable<CandidateQueryItem["candidateUsername"]>["candidate"]>["phoneNumber"];
  email: CompanyCandidate["email"];
  position: CompanyCandidate["position"];
  organization: CompanyCandidate["organization"];
  login?: NonNullable<CandidateQueryItem["candidateUsername"]>["login"];
  grade: CompanyCandidate["grade"];
}

export type Candidate = {
  id: string | null;
  fullName: ICandidateItem | null;
  telegramUsername: ICandidateItem | null;
  email?: string | null;
  organization?: string | null;
  position?: string | null;
  grade?: { id: Grade | null; displayName: string } | null;
  phoneNumber?: string | null;
  login?: ICandidateItem | null;
};

export enum CandidateType {
  Telegram = "Telegram",
  Login = "Login",
}

export type InfoInputs = {
  candidateType: CandidateType;
  id: string;
  comment?: string | null;
  createdAt: Date | string;
  completedAt: Date | string;
  startedAt?: Date | string;
  candidate: Candidate;
  password?: string;
} & LinkInput &
  CompetencyMapInputs;

export interface IUseTrialSubmitParams {
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

export interface UpdateTrialParams extends IUseTrialSubmitParams {
  formData: InfoInputs;
  candidateId: string;
}
