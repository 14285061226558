import { InputType } from "components/generators/inputsGenerator";
import { GRADE_OPTIONS } from "constants/global";
import { Grade } from "@generated/graphql";
import VALIDATION from "helpers/validation";
import { FilterField } from "layout/TableConstructor/types";
import dayjs from "dayjs";

const filterFields: FilterField<object>[] = [
  {
    name: "createdAt",
    label: "Дата создания",
    type: InputType.DATE,
    customFilter: (value) => dayjs(value).toISOString(),
  },
  {
    name: "title",
    label: "Название навыка",
    type: InputType.TEXT,
    rules: VALIDATION.competencyMapTitle,
  },

  {
    name: "grades",
    label: "Грейд",
    type: InputType.AUTOCOMPLETE,
    options: GRADE_OPTIONS,
    customFilter: (grades) => grades.map((grade: { id: Grade }) => grade.id),
    limitTags: 1,
    multiple: true,
  },
];

export default filterFields;
