import React, { useState } from "react";
import { Card, Collapse, IconButton, Stack, Typography } from "@mui/material";
import ExpandMoreButton from "components/buttons/ExpandMoreButton";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import ROUTES from "constants/routes";

interface CompetencyMapsListProps {
  competencyMaps: { __typename?: "CompetencyMap"; id: string; title: string }[] | null | undefined;
}

const CompetencyMapsList = ({ competencyMaps }: CompetencyMapsListProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Card>
      <Stack
        direction='row'
        justifyContent='space-between'
        mb={1.5}>
        <Typography
          variant='body16rg'
          sx={{ "& > span": { color: "primary.main" } }}>
          Содержится в картах компетенций <span>{` (${competencyMaps?.length})`}</span>
        </Typography>
        {!!competencyMaps?.length && (
          <IconButton onClick={() => setOpen(!open)}>
            <ExpandMoreButton expand={open} />
          </IconButton>
        )}
      </Stack>
      <Collapse in={open}>
        {competencyMaps?.map((map) => (
          <Box key={`map-${map.id}`}>
            <Typography
              variant='body14rg'
              component={Link}
              to={`/${ROUTES.COMPETENCE_MAPS}/view/${map.id}`}
              sx={{ color: "primary.main", textDecoration: "none" }}>
              {map.title}
            </Typography>
          </Box>
        ))}
      </Collapse>
    </Card>
  );
};

export default CompetencyMapsList;
