import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { Close } from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import AccordionIcon from "assets/icons/AccordionIcon";
import TextFieldControl from "components/form/TextFieldControl";
import { ComponentType, MouseEvent, useState } from "react";

type WithAccordionProps<T> = T & {
  name: string;
  title: string;
  titleVariant?: Variant;
  isEditable: boolean;
  sx?: SxProps<Theme>;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  onRemove: () => void;
};

const withAccordion = <T,>(Component: ComponentType<T>) => {
  // eslint-disable-next-line react/function-component-definition, react/display-name
  return (props: WithAccordionProps<T>) => {
    const { name: fieldName, title, titleVariant = "h6", isEditable, sx, dragHandleProps, onRemove } = props;

    const [expanded, setExpanded] = useState(false);

    const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onRemove();
    };

    const toggleExpanded = () => {
      setExpanded(!expanded);
    };

    return (
      <Accordion
        expanded={expanded}
        onChange={toggleExpanded}
        sx={{
          borderRadius: 2,
          "& .MuiAccordionSummary-content.Mui-expanded": { marginBottom: 0 },
          ...sx,
        }}>
        <div {...dragHandleProps}>
          <AccordionSummary sx={{ "&.Mui-focusVisible": { backgroundColor: "transparent" } }}>
            <Stack
              direction='row'
              spacing={2}
              sx={{ alignItems: "center" }}>
              <Typography
                variant={titleVariant}
                sx={{ fontWeight: 400, minWidth: 100 }}>
                {title}
              </Typography>
              <TextFieldControl
                placeholder='Название'
                name={`${fieldName}.title`}
                disabled={!isEditable}
                rules={{ required: true }}
                sx={{ width: 400 }}
                color='secondary'
                onClick={(e) => e.stopPropagation()}
              />
            </Stack>
            <AccordionActions sx={{ marginLeft: "auto", padding: 0 }}>
              <IconButton
                sx={{
                  color: "base.200",
                  transition: "transform 300ms ease",
                  transform: expanded ? "rotateZ(180deg)" : "none",
                }}>
                <AccordionIcon />
              </IconButton>
              {isEditable && (
                <IconButton
                  onClick={handleRemove}
                  sx={{ color: "base.200" }}>
                  <Close />
                </IconButton>
              )}
            </AccordionActions>
          </AccordionSummary>
        </div>
        <AccordionDetails>
          <Component {...props} />
        </AccordionDetails>
      </Accordion>
    );
  };
};

export default withAccordion;
