import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useMemo } from "react";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import ROUTES from "constants/routes";
import { CreateExaminationInput, Examination_Type, useCreateExaminationMutation } from "@generated/graphql";
import qs from "qs";

type SearchParams = {
  type?: Examination_Type;
};

const useCreateExamination = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    return qs.parse(searchParams.toString()) as SearchParams;
  }, [searchParams]);
  const searchExaminationType = params.type;

  const toggleToastOnError = (text?: string) => addToast({ type: ToastTypeEnum.ERROR, text });
  const toggleToastOnSuccess = (text?: string) => addToast({ type: ToastTypeEnum.SUCCESS, text });
  const navigateToCreatedTesting = (id: string) => navigate(`/${ROUTES.TESTING}/view/${id}`);

  const [createExamination, { loading: createExaminationLoading }] = useCreateExaminationMutation();

  const onCreateExamination = (data: Omit<CreateExaminationInput, "type">) => {
    createExamination({
      variables: {
        input: {
          ...data,
          type: searchExaminationType!,
        },
      },
    })
      .then((res) => {
        const createdId = res.data?.createExamination.id;
        if (createdId) {
          navigateToCreatedTesting(createdId);
          toggleToastOnSuccess();
        } else {
          toggleToastOnError();
        }
      })
      .catch(() => toggleToastOnError());
  };

  return { createExaminationLoading, onCreateExamination };
};

export default useCreateExamination;
