import TextFieldControl from "components/form/TextFieldControl";
import { UseFormReturn } from "react-hook-form";
import SelectCandidateType from "./SelectCandidateType";
import CandidateInfo from "./CandidateInfo";
import { InfoInputs } from "../types";

interface InfoTabLeftProps {
  isEditing: boolean;
  form: UseFormReturn<InfoInputs>;
}

const InfoTabLeft = ({ isEditing, form }: InfoTabLeftProps) => {
  const { reset, getValues } = form;

  const resetCandidateFields = () => {
    reset({
      ...getValues(),
      candidate: {
        email: "",
        phoneNumber: "",
        organization: "",
        position: "",
        grade: null,
        telegramUsername: null,
        login: null,
        fullName: null,
        id: null,
      },
    });
  };

  return (
    <>
      <SelectCandidateType
        resetCandidateFields={resetCandidateFields}
        isEditing={isEditing}
      />
      <CandidateInfo
        form={form}
        isEditing={isEditing}
        resetCandidateFields={resetCandidateFields}
      />
      <TextFieldControl
        name='comment'
        label='Комментарий к тестированию'
        minRows={6}
        disabled={!isEditing}
        multiline
      />
    </>
  );
};

export default InfoTabLeft;
