import { SvgIcon, SvgIconProps } from "@mui/material";

const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        // eslint-disable-next-line
        d='M12.1498 10.9468H11.3819L11.1098 10.6844C12.0623 9.57633 12.6358 8.1378 12.6358 6.5729C12.6358 3.08348 9.80732 0.255005 6.3179 0.255005C2.82847 0.255005 0 3.08348 0 6.5729C0 10.0623 2.82847 12.8908 6.3179 12.8908C7.88279 12.8908 9.32133 12.3173 10.4294 11.3648L10.6918 11.6369V12.4048L15.5517 17.255L17 15.8067L12.1498 10.9468ZM6.3179 10.9468C3.89766 10.9468 1.94397 8.99314 1.94397 6.5729C1.94397 4.15266 3.89766 2.19897 6.3179 2.19897C8.73814 2.19897 10.6918 4.15266 10.6918 6.5729C10.6918 8.99314 8.73814 10.9468 6.3179 10.9468Z'
        fill='#A9A9A9'
      />
    </svg>
  </SvgIcon>
);

export default SearchIcon;
