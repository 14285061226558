import { Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import React, { ReactNode } from "react";

const ViewGridLayout = ({
  Header,
  LeftColumn,
  RightColumn,
}: {
  Header: ReactNode;
  LeftColumn: ReactNode;
  RightColumn: ReactNode;
}) => (
  <Stack spacing={2.5}>
    <Stack
      direction='row'
      justifyContent='space-between'>
      {Header}
    </Stack>
    <Box>
      <Grid
        container
        columnSpacing={2.5}>
        <Grid
          item
          xs={12}
          md={9}>
          {LeftColumn}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}>
          {RightColumn}
        </Grid>
      </Grid>
    </Box>
  </Stack>
);

export default ViewGridLayout;
