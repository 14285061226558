import { useState } from "react";
import { FieldArrayWithId } from "react-hook-form";
import { Box, Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import TextFieldControl from "components/form/TextFieldControl";
import { gradeColor } from "helpers/index";
import { Link } from "react-router-dom";
import ROUTES from "constants/routes";
import GenerateQuestionsModal from "components/modals/GenerateQuestionsModal";
import {
  CompetencyMapInputs,
  isKnowledgeBlockCompetencyFieldsNonNullable,
  isSkillFieldsNonNullable,
  KnowledgeBlockCompetencyOption,
  SkillLiteralType,
  SkillsLiteralType,
} from "../types";

interface SkillProps {
  name: SkillLiteralType;
  skill: FieldArrayWithId<CompetencyMapInputs, SkillsLiteralType, "skillId">;
  knowledgeBlock: KnowledgeBlockCompetencyOption;
}

const Skill = ({ name, skill, knowledgeBlock }: SkillProps) => {
  const [openGenerateQuestionsModal, setOpenGenerateQuestionsModal] = useState<boolean>(false);
  const isNewSkill = !skill.id;

  return (
    <TableRow
      key={skill.skillId}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "& th, & td": { borderLeft: "none", borderRight: "none" },
      }}>
      <TableCell
        component='th'
        scope='row'>
        {isNewSkill ? (
          <TextFieldControl
            placeholder='Название навыка'
            name={`${name}.title`}
            rules={{ required: true }}
            color='secondary'
            sx={{ width: 200 }}
          />
        ) : (
          <Button
            size='small'
            component={Link}
            to={`/${ROUTES.SKILL}/view/${skill.id}`}
            sx={{ paddingLeft: 0, color: "blue", textDecoration: "underline", justifyContent: "flex-start" }}
            target='_blank'>
            {skill.title}
          </Button>
        )}
      </TableCell>
      <TableCell>
        {skill.grades?.length ? (
          skill.grades?.map((grade) => (
            <Typography
              key={`${skill.skillId}-${grade}`}
              sx={{ color: `${gradeColor(grade)}.contrast` }}>
              {grade}
            </Typography>
          ))
        ) : (
          <Typography sx={{ color: "primary.main" }}>—</Typography>
        )}
      </TableCell>
      <TableCell>
        <Stack
          direction='row'
          sx={{ justifyContent: "space-between" }}>
          <Box>
            <Typography
              component='span'
              sx={{ color: !skill.confirmedQuestionsCount ? "error.contrastText" : "primary.main" }}>
              {skill.confirmedQuestionsCount || 0}
            </Typography>
            <Typography
              component='span'
              sx={{ color: "primary.main" }}>
              /{skill.notConfirmedQuestionsCount || 0}
            </Typography>
          </Box>
          {!isNewSkill && knowledgeBlock.id && (
            <Button
              size='small'
              onClick={() => setOpenGenerateQuestionsModal(true)}>
              Сгенерировать
            </Button>
          )}
        </Stack>
      </TableCell>
      {openGenerateQuestionsModal &&
        isKnowledgeBlockCompetencyFieldsNonNullable(knowledgeBlock) &&
        isSkillFieldsNonNullable(skill) && (
          <GenerateQuestionsModal
            open={openGenerateQuestionsModal}
            onClose={() => setOpenGenerateQuestionsModal(false)}
            knowledgeBlock={knowledgeBlock}
            skill={skill}
          />
        )}
    </TableRow>
  );
};

export default Skill;
