import { PopoverPaper, styled } from "@mui/material";

const StyledPopoverPaper = styled(PopoverPaper)({
  display: "flex",
  flexDirection: "column",
  marginTop: 4,
  padding: 8,
  borderRadius: 8,
  position: "absolute",
  overflow: "visible",
  "&:before": {
    content: "''",
    position: "absolute",
    width: 16,
    height: 16,
    borderRadius: 2,
    backgroundColor: "#fff",
    top: -8,
    left: "50%",
    transform: "translateX(-50%) rotateZ(45deg)",
  },
});

export default StyledPopoverPaper;
