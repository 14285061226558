import { Grid, Stack } from "@mui/material";
import { ReactElement } from "react";

interface InfoBlockProps {
  item?: boolean;
  LeftElement: ReactElement;
  RightElement: ReactElement;
  ControlsElement?: ReactElement;
}

const InfoBlock = ({ item = false, LeftElement, RightElement, ControlsElement }: InfoBlockProps) => {
  return (
    <Grid
      container
      item={item}
      xs={12}
      spacing={2.5}>
      <Grid
        item
        xs={12}
        md={6}>
        <Stack spacing={2}>{LeftElement}</Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}>
        <Stack spacing={2}>{RightElement}</Stack>
      </Grid>
      {Boolean(ControlsElement) && (
        <Grid
          item
          xs={12}
          md={3}>
          {ControlsElement}
        </Grid>
      )}
    </Grid>
  );
};

export default InfoBlock;
