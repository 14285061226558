import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import Form from "components/form/Form";
import TextFieldControl from "components/form/TextFieldControl";
import { useForm } from "react-hook-form";
import {
  GenerateSkeletonCompetencyMapDescriptionInput,
  useGenerateSkeletonOfCompetencyMapByDescriptionMutation,
} from "@generated/graphql";
import VALIDATION from "helpers/validation";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import { useContext } from "react";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import AutocompleteControl from "components/form/AutocompleteControl";
import { GRADE_OPTIONS, LANGUAGE_OPTIONS } from "constants/global";

type DescriptionModalForm = Omit<GenerateSkeletonCompetencyMapDescriptionInput, "grade" | "language"> & {
  grade: (typeof GRADE_OPTIONS)[number];
  language: (typeof LANGUAGE_OPTIONS)[number];
};

interface DescriptionModalProps {
  open: boolean;
  handleClose: (doRefetch?: boolean) => void;
}

const DescriptionModal = ({ open, handleClose }: DescriptionModalProps) => {
  const { addToast } = useContext(ToastContext);
  const form = useForm<DescriptionModalForm>();
  const { handleSubmit } = form;

  const [createSkeleton, { loading }] = useGenerateSkeletonOfCompetencyMapByDescriptionMutation();

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const onSubmit = (formData: DescriptionModalForm) => {
    createSkeleton({
      variables: {
        input: {
          title: formData.title,
          specialization: formData.specialization,
          grade: formData.grade.id,
          language: formData.language.id,
          description: formData.description,
        },
      },
    })
      .then((res) => {
        const success = res.data?.generateSkeletonOfCompetencyMapByDescription;
        if (success) {
          addToast({ type: ToastTypeEnum.SUCCESS, text: "Генерация карты занимает несколько минут" });
          handleClose(true);
        } else onError();
      })
      .catch(() => onError());
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth='sm'>
      <DialogTitle
        variant='h24'
        textAlign='center'>
        Создание карты по текстовому описанию
      </DialogTitle>
      <DialogContent>
        <Form form={form}>
          <Stack spacing={2}>
            <TextFieldControl
              label='Название карты компетенций'
              name='title'
              rules={{ required: true, ...VALIDATION.competencyMapTitle }}
            />
            <TextFieldControl
              label='Специализация'
              name='specialization'
              rules={{ required: true, ...VALIDATION.competencyMapTitle }}
            />
            <AutocompleteControl
              options={GRADE_OPTIONS}
              name='grade'
              label='Грейд'
              rules={{
                required: true,
              }}
            />
            <AutocompleteControl
              options={LANGUAGE_OPTIONS}
              name='language'
              label='Язык'
              rules={{
                required: true,
              }}
            />
            <TextFieldControl
              label='Краткое описание'
              multiline
              minRows={4}
              maxRows={4}
              name='description'
              rules={{ required: true }}
            />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={() => handleClose()}>
          Отмена
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit, onError)}
          loading={loading}
          variant='contained'>
          Отправить запрос
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionModal;
