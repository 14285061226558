import React, { createContext, ReactNode, useMemo } from "react";
import { Exact, MeQuery, useMeQuery, UserCompaniesQuery, useUserCompaniesQuery } from "@generated/graphql";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import ROUTES from "constants/routes";

interface IAuthContext {
  me: MeQuery["me"] | undefined;
  meLoading: boolean;
  meError: ApolloError | undefined;
  meRefetch: (variables?: Partial<Exact<{ [key: string]: never }>>) => Promise<ApolloQueryResult<MeQuery>>;
  userCompanies: UserCompaniesQuery["userCompanies"] | undefined;
  userCompaniesLoading: boolean;
  userCompaniesRefetch: (
    variables?: Partial<Exact<{ [key: string]: never }>>
  ) => Promise<ApolloQueryResult<UserCompaniesQuery>>;
}

export const UserContext = createContext<IAuthContext>({
  me: undefined,
  meLoading: false,
  meError: undefined,
  meRefetch: async () => ({
    data: {
      me: {
        id: "",
        firstName: null,
        lastName: null,
        middleName: null,
        userEmail: { email: "", id: "" },
      },
    },
    loading: false,
    networkStatus: 7,
    stale: false,
  }),
  userCompanies: undefined,
  userCompaniesLoading: false,
  userCompaniesRefetch: async () => ({
    data: {
      userCompanies: [],
    },
    loading: false,
    networkStatus: 7,
    stale: false,
  }),
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { location } = window;

  const {
    data: meData,
    loading: meLoading,
    error: meError,
    refetch: meRefetch,
  } = useMeQuery({
    fetchPolicy: "network-only",
    skip: location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.USER_REGISTER,
  });

  const {
    data: companyData,
    loading: userCompaniesLoading,
    refetch: userCompaniesRefetch,
  } = useUserCompaniesQuery({
    fetchPolicy: "network-only",
    skip: location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.USER_REGISTER,
  });

  const me = meData?.me;
  const userCompanies = companyData?.userCompanies;

  const providerValue = useMemo(
    () => ({
      me,
      meLoading,
      meError,
      meRefetch,
      userCompanies,
      userCompaniesLoading,
      userCompaniesRefetch,
    }),
    [me, meLoading, meError, meRefetch, userCompanies, userCompaniesLoading, userCompaniesRefetch]
  );

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};
