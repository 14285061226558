import { createSvgIcon } from "@mui/material";

const CheckboxBorderIcon = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'>
    <path
      d='M4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6Z' // eslint-disable-line max-len
      stroke='currentColor'
    />
  </svg>,
  "CheckboxBorder"
);

export default CheckboxBorderIcon;
