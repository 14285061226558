import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import CircularLoading from "components/CircularLoading";

import { Competency_Map_Status, useCompetencyMapQuery } from "@generated/graphql";
import useIsCreate from "hooks/useIsCreate";
import useSetTabTitle from "hooks/useSetTabTitle";

import TabLayout from "layout/TabLayout";
import TestingListTab from "pages/CompetencyMaps/view/tabs/TestingListTab";
import Header from "./components/Header";
import { CompetencyMapInputs } from "./components/types";
import ConstructorTab from "./tabs/ConstructorTab";

const CompetencyMapView = () => {
  const isCreate = useIsCreate();
  const [isEditable, setIsEditable] = useState<boolean>(isCreate);

  const { mapId } = useParams();
  const { data, loading } = useCompetencyMapQuery({
    variables: { competencyMapId: mapId! },
    skip: !mapId,
  });

  const competencyMap = data?.competencyMap;
  const form = useForm<CompetencyMapInputs>({
    values: {
      id: competencyMap?.id,
      title: competencyMap?.title || "",
      comment: competencyMap?.comment,
      specialization: competencyMap?.specialization || "",
      grades: competencyMap?.grades?.join(", "),
      updatedAt: competencyMap?.updatedAt,
      createdAt: competencyMap?.createdAt,
      isRepositoryCheck: competencyMap?.isRepositoryCheck || false,
      knowledgeBlocks: competencyMap?.competences?.map((block) => ({
        id: block.id,
        title: block.title,
        knowledgeBlockCompetences: block.knowledgeBlockCompetences?.map((competence) => ({
          id: competence.knowledgeBlock.id,
          title: competence.knowledgeBlock.title,
          skills: competence.knowledgeBlock.skills,
          profession: competence.knowledgeBlock.profession,
        })),
      })),
    },
  });

  const disabled = competencyMap?.status === Competency_Map_Status.Generating;

  const { control } = form;
  const fieldArray = useFieldArray({
    control,
    name: "knowledgeBlocks",
    keyName: "formId",
  });

  const { append } = fieldArray;

  const title = `Карта компетенций ${competencyMap?.title ? ` «${competencyMap.title}»` : ""}`;
  useSetTabTitle(title, competencyMap?.title);

  const TABS = [
    {
      id: "constructor",
      title: "Коснтруктор КК",
      component: (
        <ConstructorTab
          fieldArray={fieldArray}
          form={form}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          competencyMap={competencyMap}
          disabled={disabled}
        />
      ),
    },
    {
      id: "testingList",
      title: "Тестирования",
      component: <TestingListTab />,
    },
  ];

  if (loading) return <CircularLoading />;

  return (
    <>
      <Header
        competencyMap={competencyMap}
        disabled={disabled}
        append={append}
        isCreate={isCreate}
        isEditable={isEditable}
      />
      <TabLayout
        tabs={TABS}
        defaultTab='constructor'
        sx={{
          "& .MuiTabPanel-root > div": {
            backgroundColor: "transparent",
          },
        }}
      />
    </>
  );
};

export default CompetencyMapView;
