import filterFields, { CompetencyMapFilters } from "pages/CompetencyMaps/list/tableConfig/filterFields";
import { useCompetencyMapsQuery } from "@generated/graphql";
import useSetTabTitle from "hooks/useSetTabTitle";
import TableConstructor from "layout/TableConstructor";
import columnConfig, { CompetencyMapItem } from "pages/CompetencyMaps/list/tableConfig/columnConfig";
import columnFilterMap from "pages/CompetencyMaps/list/tableConfig/columnFilterMap";
import { Stack } from "@mui/material";
import Header from "pages/CompetencyMaps/list/components/Header";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/routes";

const CompetencyMapsList = () => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useCompetencyMapsQuery({
    variables: {
      page: 0,
      limit: 0,
      filter: {},
    },
  });

  const list = data?.competencyMaps.data || [];

  useSetTabTitle("Карты компетенций");

  return (
    <Stack spacing={2.5}>
      <Header refetch={refetch} />
      <TableConstructor<CompetencyMapItem, CompetencyMapFilters>
        refetch={refetch}
        data={list}
        totalCount={data?.competencyMaps.totalCount}
        loading={loading}
        columnsConfig={columnConfig}
        filtersConfig={filterFields}
        defaultColumns={["title", "createdAt", "specialization", "grades", "updatedAt", "competencesCount", "action"]}
        columnFilterMap={columnFilterMap}
        emptyPlaceholderText='Пока нет карт компетенций'
        onRowClick={(row) => navigate(`/${ROUTES.COMPETENCE_MAPS}/view/${row.id}`)}
      />
    </Stack>
  );
};

export default CompetencyMapsList;
