import React, { useMemo } from "react";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Exact, SkillQuery } from "@generated/graphql";
import { QueryResult } from "@apollo/client";
import QuestionItem from "./QuestionItem";

interface QuestionListProps {
  isConfirmed?: boolean;
  skillQuery: QueryResult<SkillQuery, Exact<{ skillId: string }>>;
}

const QuestionList = ({ isConfirmed, skillQuery }: QuestionListProps) => {
  const { data } = skillQuery;
  const skill = data?.skill;

  const questions = skill?.questions;
  const list = useMemo(
    () => questions?.filter((question) => (isConfirmed ? question.confirmation : !question.confirmation)),
    [isConfirmed, questions]
  );

  return (
    <Box>
      {list?.length ? (
        list.map((item, index) => (
          <>
            <QuestionItem
              question={item}
              skillQuery={skillQuery}
            />
            {list.length > index + 1 && <Divider />}
          </>
        ))
      ) : (
        <Typography
          variant='body16rg'
          textAlign='center'
          sx={{ display: "block", py: 2.5 }}>
          Вопросов нет
        </Typography>
      )}
    </Box>
  );
};

export default QuestionList;

QuestionList.defaultProps = {
  isConfirmed: false,
};
