import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import withDragDropContext from "hocs/withDragDropContext";
import { CompetenciesLiteralType, CompetencyMapInputs, KnowledgeBlockLiteralType } from "../types";
import KnowledgeBlocksList from "../KnowledgeBlocksList";

const KnowledgeBlocksListWithDragDropContext = withDragDropContext(KnowledgeBlocksList);
interface ICompetencyProps {
  name: KnowledgeBlockLiteralType;
  isEditable: boolean;
  form: UseFormReturn<CompetencyMapInputs>;
}

const Competency = ({ name, isEditable, form }: ICompetencyProps) => {
  const { control } = form;
  const competenciesName: CompetenciesLiteralType = `${name}.knowledgeBlockCompetences`;
  const fieldArray = useFieldArray({
    control,
    name: competenciesName,
    keyName: "formId",
  });
  const { fields, remove, append, move } = fieldArray;

  const addKnowledgeBlock = () => {
    append({ title: "" });
  };

  return (
    <Box>
      <KnowledgeBlocksListWithDragDropContext
        droppableId='knowledgeBlocksList'
        moveItems={move}
        fields={fields}
        form={form}
        remove={remove}
        isEditable={isEditable}
        name={competenciesName}
      />
      <Button
        startIcon={<Add sx={{ color: "base.200" }} />}
        variant='contained'
        color='secondary'
        onClick={addKnowledgeBlock}
        disabled={!isEditable}
        sx={{
          mt: 2,
          "&:hover": { backgroundColor: "bgSwitch" },
          "&:active": { backgroundColor: "bgSwitch" },
          "&.Mui-disabled": { backgroundColor: "bgSwitch" },
        }}>
        Блок знаний
      </Button>
    </Box>
  );
};

export default Competency;
