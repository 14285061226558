import { useParams, useSearchParams } from "react-router-dom";
import { useSkillQuery } from "@generated/graphql";
import useIsCreate from "hooks/useIsCreate";
import CircularLoading from "components/CircularLoading";
import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useContext, useMemo, useState } from "react";
import Info from "pages/Skill/view/components/Info";
import Questions from "pages/Skill/view/components/QuestionItem";
import ViewGridLayout from "layout/ViewGridLayout";
import useSetTabTitle from "hooks/useSetTabTitle";
import UpdateQuestionModal from "pages/Skill/view/components/UpdateQuestionModal";
import BackPageButton from "components/buttons/BackPageButton";
import qs from "qs";
import ROUTES from "constants/routes";
import { CompanyContext } from "context/companyContext";
import ButtonWithPermissions, { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import GenerateQuestionsModal from "components/modals/GenerateQuestionsModal";

const SkillView = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);

  const generateQuestionsUnavailableText = generateUnavailableText(
    isCreateAvailable,
    currentCompany,
    "генерации вопросов"
  );

  const isCreate = useIsCreate();
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { knowledgeBlockId, knowledgeBlockTitle } = params as unknown as {
    knowledgeBlockId?: string;
    knowledgeBlockTitle?: string;
  };
  const { skillId } = useParams();
  const skillQuery = useSkillQuery({
    variables: {
      skillId: skillId!,
    },
    skip: !skillId,
  });

  const { data, loading, refetch } = skillQuery;

  const skill = data?.skill;

  const [openGenerateQuestionsModal, setOpenGenerateQuestionsModal] = useState<boolean>(false);
  const [openCreateQuestionModal, setOpenCreateQuestionModal] = useState<boolean>(false);
  const title = `Навык ${skill?.title ? ` «${skill.title}»` : ""}`;
  useSetTabTitle(title, skill?.title);

  const knowledgeBlock = skill?.knowledgeBlock || { id: knowledgeBlockId, title: knowledgeBlockTitle };

  if (loading) return <CircularLoading />;

  return (
    <>
      <ViewGridLayout
        Header={
          <Stack
            sx={{ width: "100%" }}
            spacing={2}
            alignItems='flex-start'>
            <BackPageButton
              text={`Блок знаний ${knowledgeBlock.title}`}
              link={`/${ROUTES.KNOWLEDGE_BLOCKS}/view/${knowledgeBlock.id}`}
            />
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ width: "100%" }}>
              <Typography variant='h28'>{title}</Typography>
              {!isCreate && (
                <Stack
                  direction='row'
                  spacing={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      backgroundColor: "white",
                      color: "text.primary",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                    onClick={() => setOpenCreateQuestionModal(true)}>
                    Создать вопрос
                  </Button>
                  <ButtonWithPermissions
                    buttonText='Сгенерировать вопросы'
                    onClick={() => setOpenGenerateQuestionsModal(true)}
                    tooltipText={generateQuestionsUnavailableText}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        }
        LeftColumn={<Questions skillQuery={skillQuery} />}
        RightColumn={<Info skill={skill} />}
      />
      {openGenerateQuestionsModal && (
        <GenerateQuestionsModal
          open={openGenerateQuestionsModal}
          onClose={() => setOpenGenerateQuestionsModal(false)}
          knowledgeBlock={skill?.knowledgeBlock!}
          skill={{ title: skill?.title!, id: skill?.id! }}
        />
      )}
      {openCreateQuestionModal && (
        <UpdateQuestionModal
          isOpen={openCreateQuestionModal}
          onClose={() => setOpenCreateQuestionModal(false)}
          isCreate
          skill={skill}
          refetchSkill={refetch}
        />
      )}
    </>
  );
};

export default SkillView;
