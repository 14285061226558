import { useCompanyCandidatesQuery } from "@generated/graphql";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "assets/icons/CloseIcon";
import SearchIcon from "assets/icons/SearchIcon";
import CircularLoading from "components/CircularLoading";
import { useCallback, useState } from "react";
import { debounce } from "lodash";
import CandidatesTable from "./components/Table";

export type AddCandidateModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (selected: string[]) => void;
};

const AddCandidateModal = ({ open, onClose, onSubmit }: AddCandidateModalProps) => {
  const [selected, setSelected] = useState<string[]>([]);

  const {
    loading: candidatesLoading,
    data: candidatesData,
    refetch,
  } = useCompanyCandidatesQuery({
    variables: { input: { isTelegram: true }, page: 0, limit: 15 },
  });

  const debounceQuery = useCallback(
    debounce((value) => {
      refetch({ input: { fullName: value, isTelegram: true }, page: 0, limit: 15 });
    }, 500),
    []
  );

  const handleSubmit = () => {
    onSubmit(selected);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'>
      <Stack
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'>
        <Box />
        <DialogTitle
          variant='h5'
          sx={{ fontWeight: 500, paddingBottom: 0 }}>
          Выбор кандидатов
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent>
        <Typography
          textAlign='center'
          sx={{ marginBottom: 1 }}>
          Укажите кандидатов, которых необходимо назначить на&#160;групповое тестирование
        </Typography>
        <TextField
          name='search'
          placeholder='Поиск'
          variant='outlined'
          color='secondary'
          fullWidth
          onChange={(e) => debounceQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ fontSize: 20 }} />
              </InputAdornment>
            ),
          }}
        />
        {candidatesLoading ? (
          <CircularLoading />
        ) : (
          <CandidatesTable
            data={candidatesData?.companyCandidates.data}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </DialogContent>
      <Stack
        justifyContent='space-between'
        alignItems='flex-end'
        flexDirection='row'>
        <Typography color='primary.main'>Количество выбранных кандидатов: {selected.length}</Typography>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClose}>
            Отменить
          </Button>
          <Button
            variant='contained'
            onClick={handleSubmit}>
            Добавить
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default AddCandidateModal;
