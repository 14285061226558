import { IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import EditIcon from "assets/icons/EditIcon";
import OpenEyeIcon from "assets/icons/OpenEyeIcon";
import React, { FC, useContext, useState } from "react";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { Exact, SkillQuery, useConfirmOrRejectQuestionMutation, useDeleteQuestionMutation } from "@generated/graphql";
import UpdateQuestionModal from "pages/Skill/view/components/UpdateQuestionModal";
import { ConfirmModalContext } from "context/confirmModalContext";
import { QuestionItemType } from "pages/Skill/view/types/skill";
import { ApolloQueryResult } from "@apollo/client";
import QuestionImgButton from "./QuestionImgModal";

interface ActionButtonsProps {
  skill: SkillQuery["skill"];
  question: QuestionItemType;
  refetchSkill: (variables?: Partial<Exact<{ skillId: string }>> | undefined) => Promise<ApolloQueryResult<SkillQuery>>;
}

const ActionButtons: FC<ActionButtonsProps> = ({ skill, question, refetchSkill }) => {
  const isConfirm = question.confirmation;
  const { handleOpen } = useContext(ConfirmModalContext);
  const { addToast } = useContext(ToastContext);

  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openImgModal, setOpenImgModal] = useState<boolean>();

  const [deleteQuestion, { loading: deleteLoading }] = useDeleteQuestionMutation();

  const [confirmOrRejectQuestion, { loading: confirmRejectLoading }] = useConfirmOrRejectQuestionMutation();

  const onConfirmOrReject = () =>
    confirmOrRejectQuestion({
      variables: {
        questionId: question.id,
      },
    });

  const onDelete = () =>
    deleteQuestion({
      variables: {
        questionId: question.id,
      },
    });

  const action = isConfirm ? onConfirmOrReject : onDelete;

  const handleConfirmOrDelete = () => {
    handleOpen({
      title: isConfirm ? "Вы уверены, что хотите отклонить вопрос?" : "Вы уверены, что хотите удалить вопрос?",
      actionButtonText: isConfirm ? "Отклонить" : "Удалить",
      loading: confirmRejectLoading || deleteLoading,
      handleClick: async () =>
        action()
          .then(() => {
            refetchSkill();
            addToast({ type: ToastTypeEnum.SUCCESS });
          })
          .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
    });
  };
  return (
    <Stack
      spacing={0.5}
      sx={{
        top: 16,
        right: -8,
        position: "absolute",
      }}>
      <IconButton onClick={handleConfirmOrDelete}>
        <Close sx={{ color: "text.disabled", width: 20, height: 20 }} />
      </IconButton>
      <IconButton onClick={() => setOpenUpdateModal(true)}>
        <EditIcon sx={{ color: "text.disabled", width: 20, height: 20 }} />
      </IconButton>
      <IconButton onClick={() => setOpenImgModal(true)}>
        <OpenEyeIcon sx={{ color: "text.disabled", width: 24, height: 24 }} />
      </IconButton>
      {openUpdateModal && (
        <UpdateQuestionModal
          isOpen={openUpdateModal}
          onClose={() => setOpenUpdateModal(false)}
          skill={skill}
          question={question}
          refetchSkill={refetchSkill}
        />
      )}
      {openImgModal && (
        <QuestionImgButton
          open={openImgModal}
          onClose={() => setOpenImgModal(false)}
          questionId={question.id}
        />
      )}
    </Stack>
  );
};

export default ActionButtons;
