import { TableConstructorProvider } from "layout/TableConstructor/context/tableConstructorContext";
import View from "layout/TableConstructor/View";
import { Identifiable, IOrderInput, TableConstructorProps } from "./types";

const TableConstructor = <
  T extends Identifiable,
  AvailableFilters extends object = object,
  OrderInput extends IOrderInput | undefined = undefined,
  ColapsingRowName extends string | undefined = undefined,
>(
  props: TableConstructorProps<T, AvailableFilters, OrderInput, ColapsingRowName>
) => {
  const { defaultColumns, defaultFilters, columnsConfig, filtersConfig, columnFilterMap } = props;

  return (
    <TableConstructorProvider<T, AvailableFilters, ColapsingRowName>
      defaultFilterList={defaultFilters}
      defaultColumnList={defaultColumns}
      columnConfigList={columnsConfig}
      columnFilterMap={columnFilterMap}
      filterConfigList={filtersConfig}>
      <View<T, AvailableFilters, OrderInput, ColapsingRowName> {...props} />
    </TableConstructorProvider>
  );
};

export default TableConstructor;
