import Box from "@mui/material/Box";
import { Chip, Stack, Typography } from "@mui/material";
import { gradeColor } from "helpers/index";
import React from "react";
import { getGradeByScore } from "pages/Testing/view/components/Personal/ResultTab/helpers";

interface ScoreProps {
  totalScore: number;
}

const Score = ({ totalScore }: ScoreProps) => {
  const gradeByScore = getGradeByScore(totalScore);

  return (
    <Stack
      direction='row'
      spacing={1.5}>
      <Box
        sx={{
          padding: 1,
          width: 128,
          borderRadius: 1,
          backgroundColor: "white",
          boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)",
        }}>
        <Typography variant='body14rg'>Общая оценка</Typography>
      </Box>
      <Box
        sx={{
          borderRadius: 1,
          backgroundColor: "white",
          pr: 1,
          display: "flex",
          alignItems: "center",
          height: "fit-content",
        }}>
        <Chip
          label={totalScore.toFixed(2)}
          sx={{
            backgroundColor: `${gradeColor(gradeByScore)}.light`,
            color: `${gradeColor(gradeByScore)}.contrast`,
            borderRadius: 1,
            mr: 0.5,
          }}
        />
        <Typography>баллов из 10</Typography>
      </Box>
    </Stack>
  );
};

export default Score;
