import { Box, Button, Stack, Typography } from "@mui/material";
import TextFieldControl from "components/form/TextFieldControl";
import { FieldArrayWithId, UseFieldArrayAppend, UseFormReturn } from "react-hook-form";
import { Add } from "@mui/icons-material";
import SkillsList from "../SkillsList";
import { CompetencyLiteralType, CompetencyMapInputs, SkillsLiteralType } from "../types";

interface IKnowledgeBlockBodyProps {
  name: CompetencyLiteralType;
  isEditable: boolean;
  form: UseFormReturn<CompetencyMapInputs>;
  skillsFields: FieldArrayWithId<CompetencyMapInputs, SkillsLiteralType, "skillId">[];
  appendSkill: UseFieldArrayAppend<CompetencyMapInputs, SkillsLiteralType>;
}

const KnowledgeBlockBody = ({ name, isEditable, skillsFields, appendSkill, form }: IKnowledgeBlockBodyProps) => {
  const knowledgeBlock = form.getValues(name);

  const addSkillBlock = () => {
    appendSkill({ title: "", grades: [] });
  };

  return (
    <Box>
      <Stack
        direction='row'
        spacing={2}
        sx={{ alignItems: "center", marginBottom: 3 }}>
        <Typography
          variant='subtitle1'
          sx={{ minWidth: 100 }}>
          Профессия
        </Typography>
        <TextFieldControl
          placeholder='Название профессии'
          name={`${name}.profession`}
          disabled={!isEditable || Boolean(knowledgeBlock.id)}
          rules={{ required: true }}
          color='secondary'
          sx={{ width: 400 }}
        />
      </Stack>
      <SkillsList
        name={`${name}.skills`}
        skills={skillsFields}
        knowledgeBlock={knowledgeBlock}
      />
      <Button
        startIcon={<Add sx={{ color: "base.200" }} />}
        variant='contained'
        color='secondary'
        onClick={addSkillBlock}
        disabled={!isEditable}
        sx={{
          mt: 2,
          backgroundColor: "#fff",
          "&:hover": { backgroundColor: "#fff" },
          "&:active": { backgroundColor: "#fff" },
          "&.Mui-disabled": { backgroundColor: "#fff" },
        }}>
        Навык
      </Button>
    </Box>
  );
};

export default KnowledgeBlockBody;
