import React from "react";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { formatNumber, gradeColor } from "helpers/index";
import { TrialResultQuery } from "@generated/graphql";
import TextBlock from "../TextBlock";
import { getGradeByScore, getScoreSummary } from "../../helpers";

interface CodeReviewCommonProps {
  trialRepositoryCheck: NonNullable<TrialResultQuery["trial"]>["trialRepositoryChecks"][number];
}

const CodeReviewCommon = ({ trialRepositoryCheck }: CodeReviewCommonProps) => {
  const totalScore = trialRepositoryCheck.totalScore ?? 0;
  const algorithmsScore = trialRepositoryCheck.algorithmsScore ?? 0;
  const maintainabilityScore = trialRepositoryCheck.maintainabilityScore ?? 0;
  const readabilityScore = trialRepositoryCheck.readabilityScore ?? 0;
  const grade = getGradeByScore(totalScore);
  const formatTotalScore = formatNumber(["балл", "балла", "баллов"], totalScore, true);

  const Text = (
    <Stack spacing={0.5}>
      <Typography
        variant='body14rg'
        sx={{ "& > span": { fontWeight: 600 }, "& > span:last-of-type": { color: `${gradeColor(grade)}.contrast` } }}>
        Общая оценка <span>{totalScore.toFixed(2)}</span> {formatTotalScore} находится в диапазоне грейда{" "}
        <span>{grade}</span>.
      </Typography>
      <Typography variant='body14rg'>Оценка кода была проведена по ключевым параметрам:</Typography>
      <Box
        component='ul'
        sx={{
          my: 0,
          pl: 2,
          "& >li": { mb: 0.5 },
          "& > li::marker": { color: "primary.main" },
        }}>
        <li>{`сложность кода - ${getScoreSummary(algorithmsScore)};`}</li>
        <li>{`поддерживаемость кода - ${getScoreSummary(maintainabilityScore)};`}</li>
        <li>{`читабельность кода - ${getScoreSummary(readabilityScore)}.`}</li>
      </Box>
      <Typography variant='body14rg'>
        Детальная оценка приведена в блоке &apos;Результаты по оценке кода кандидата&apos;
      </Typography>
    </Stack>
  );

  const getContent = () => {
    if (!trialRepositoryCheck.repoUrl) return "Кандидат не предоставил ссылку на репозиторий";
    if (trialRepositoryCheck.error) return "Произошла ошибка проверки репозитория";
    return Text;
  };

  const Content = getContent();
  return (
    <TextBlock
      title='Общая информация по оценке кода кандидата'
      text={Content}
    />
  );
};

export default CodeReviewCommon;
