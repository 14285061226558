import { Grid, Stack } from "@mui/material";
import React from "react";
import { TrialResultQuery } from "@generated/graphql";
import Chart from "./Chart";
import Score from "./Score";
import Facts from "./Facts";

interface ResultProps {
  trialRepositoryCheck: NonNullable<TrialResultQuery["trial"]>["trialRepositoryChecks"][number];
}

const Result = ({ trialRepositoryCheck }: ResultProps) => {
  const repoUrl = trialRepositoryCheck.repoUrl!;
  const totalScore = trialRepositoryCheck.totalScore ?? 0;
  const algorithmsScore = trialRepositoryCheck.algorithmsScore ?? 0;
  const maintainabilityScore = trialRepositoryCheck.maintainabilityScore ?? 0;
  const readabilityScore = trialRepositoryCheck.readabilityScore ?? 0;

  return (
    <Grid
      item
      xs={12}
      lg={6}>
      <Stack spacing={2}>
        <a
          href={repoUrl}
          target='_blank'
          rel='noreferrer'>
          {repoUrl}
        </a>
        <Score totalScore={totalScore} />
        <Chart {...{ algorithmsScore, maintainabilityScore, readabilityScore }} />
        <Facts facts={trialRepositoryCheck.facts} />
      </Stack>
    </Grid>
  );
};

export default Result;
