import { createContext, ReactNode, useMemo, useState } from "react";
import TestingSelectModal from "components/modals/TestingSelectModal";
import { NavigateFunction } from "react-router-dom";

type ModalProps = {
  navigate: NavigateFunction | null;
  mapId?: string;
};

interface ModalContextType {
  handleOpen: (modalProps: ModalProps) => void;
}

export const TestingSelectModalContext = createContext<ModalContextType>({
  handleOpen: () => {},
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<ModalProps>({
    navigate: null,
  });

  const handleOpen = (props: ModalProps) => {
    setContent(props);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const providerValue = useMemo(() => ({ handleOpen }), [handleOpen]);

  return (
    <TestingSelectModalContext.Provider value={providerValue}>
      <TestingSelectModal
        open={open}
        onClose={handleClose}
        {...content}
      />
      {children}
    </TestingSelectModalContext.Provider>
  );
};
