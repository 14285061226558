import { Competency_Map_Status, Examination_Type, Grade, Language, Question_Type } from "@generated/graphql";
import palette from "theme/palette";
import { generateOptions } from "helpers/index";

const GRADE_OPTIONS = [
  {
    id: Grade.Junior,
    displayName: "Junior",
  },
  {
    id: Grade.Middle,
    displayName: "Middle",
  },
  {
    id: Grade.Senior,
    displayName: "Senior",
  },
];

const QUESTION_TYPES = [
  {
    label: "Практический",
    value: Question_Type.Practical,
  },
  {
    label: "Теоретический",
    value: Question_Type.Theoretical,
  },
];

const COMPETENCY_MAP_STATUSES = [
  {
    label: "Драфт",
    value: Competency_Map_Status.Draft,
    color: "error" as const,
  },
  {
    label: "Подтверждено",
    value: Competency_Map_Status.Approved,
    color: "success" as const,
  },
  {
    label: "Генерация",
    value: Competency_Map_Status.Generating,
    color: "info" as const,
    hide: true,
  },
];

const LANGUAGE_OPTIONS = [
  {
    id: Language.En,
    displayName: "Английский",
  },
  {
    id: Language.Kk,
    displayName: "Казахский",
  },
  {
    id: Language.Ru,
    displayName: "Русский",
  },
];

const COMPETENCY_MAP_STATUS_TITLE = {
  [Competency_Map_Status.Draft]: "Драфт",
  [Competency_Map_Status.Approved]: "Подтверждено",
  [Competency_Map_Status.Generating]: "Генерируется",
};

const COMPETENCY_MAP_STATUS_OPTIONS = generateOptions(COMPETENCY_MAP_STATUS_TITLE);

const EXAMINATION_TYPES_DATA = [
  {
    title: "Персональное тестирование",
    description: "Персональное единичное тестирование на конкретного кандидата.",
    type: Examination_Type.Personal,
    color: palette.testing.personal,
  },
  {
    title: "Групповое тестирование",
    description:
      "Тестирование на конкретную группу кандидатов. Тестирование будет отображаться в общем списке единой группы.",
    type: Examination_Type.Group,
    color: palette.testing.private,
  },
  {
    title: "Открытое тестирование",
    description: "Обезличенное тестирование. Кандидаты могут пройти по ссылке и стать участниками тестирования.",
    type: Examination_Type.Open,
    color: palette.testing.public,
  },
];

const EXAMINATION_TYPE_TITLE = {
  [Examination_Type.Open]: "Открытое",
  [Examination_Type.Group]: "Групповое",
  [Examination_Type.Personal]: "Персональное",
};

const GENERATE_QUESTIONS_DEFAULT_AMOUNT = 3;

export {
  GRADE_OPTIONS,
  QUESTION_TYPES,
  COMPETENCY_MAP_STATUSES,
  GENERATE_QUESTIONS_DEFAULT_AMOUNT,
  EXAMINATION_TYPES_DATA as TESTING_TYPES_DATA,
  LANGUAGE_OPTIONS,
  COMPETENCY_MAP_STATUS_TITLE,
  COMPETENCY_MAP_STATUS_OPTIONS,
  EXAMINATION_TYPE_TITLE,
};
