import { ColumnConfig } from "layout/TableConstructor/types";
import { Examination_Type, ExaminationListQuery } from "@generated/graphql";
import TestingStatus from "components/TestingStatus";
import { gradeColor } from "helpers/index";
import { Chip, IconButton, Stack } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { EXAMINATION_TYPE_TITLE } from "constants/global";
import DownloadIcon from "assets/icons/DownloadIcon";
import TestingLink from "components/TestingLink";

export type ExaminationItem = NonNullable<NonNullable<ExaminationListQuery>["examinations"]["data"]>[number];

const secondsToMinutes = (seconds: number | null | undefined) =>
  seconds ? dayjs.duration(seconds, "s").format("mm:ss") : "-";

const columnConfig: ColumnConfig<ExaminationItem, "trials">[] = [
  {
    title: "ID",
    name: "numericId",
    render: (data) => data.numericId,
  },
  {
    title: "Название КК",
    name: "competencyMapTitle",
    render: (data) => data.competencyMap.title,
  },
  {
    title: "Специализация",
    name: "specialization",
    render: (data) => data.competencyMap.specialization,
  },
  {
    title: "Количество компетенций",
    name: "competencesCount",
    render: (data) => data.competencyMap.competencesCount || 0,
  },
  {
    title: "Количество блоков знаний",
    name: "knowledgeBlocksCount",
    render: (data) => data.competencyMap.blocksCount || 0,
  },
  {
    title: "ID кандидата",
    name: "candidateId",
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      if (isPersonal) return "-";
      return data.trials?.[0]?.companyCandidate.numericId;
    },
    renderCollapse: (data) => data.companyCandidate.numericId,
  },
  {
    title: "Кандидат",
    name: "candidateFullName",
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      if (isPersonal) return data.trials?.[0]?.companyCandidate.fullName;
      return new Set(data.trials?.map((t) => t.companyCandidate.id)).size || 0;
    },
    renderCollapse: (data) => data.companyCandidate.fullName,
  },
  {
    title: "Дата создания",
    name: "createdAt",
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      const createdAt = isPersonal ? data.trials?.[0]?.createdAt : data.createdAt;
      if (!createdAt) return "-";
      return dayjs(createdAt).format("DD.MM.YYYY");
    },
  },
  {
    title: "Дата завершения",
    name: "completedAt",
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      const completedAt = isPersonal ? data.trials?.[0]?.completedAt : data.lastCompleteDate;
      if (!completedAt) return "-";
      return dayjs(completedAt).format("DD.MM.YYYY");
    },
  },
  {
    title: "Статус",
    name: "trialStatus",
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      const status = isPersonal ? data.trials?.[0]?.status : undefined;
      if (!status) return "-";
      return (
        <TestingStatus
          sx={{ backgroundColor: "transparent", p: 0, "& .MuiTypography-root": { fontSize: 14 } }}
          status={status}
        />
      );
    },
    renderCollapse: (data) => {
      const { status } = data;
      return (
        <TestingStatus
          sx={{ backgroundColor: "transparent", p: 0, "& .MuiTypography-root": { fontSize: 14 } }}
          status={status}
        />
      );
    },
  },
  {
    title: "Грейды",
    name: "grades",
    render: (data) => (
      <Stack>
        {data.competencyMap.grades?.map((grade) => (
          <Chip
            key={grade}
            label={grade}
            sx={{ backgroundColor: `${gradeColor(grade)}.light`, color: `${gradeColor(grade)}.contrast` }}
          />
        ))}
      </Stack>
    ),
  },
  {
    title: "Результаты",
    name: "candidateScore",
    render: (data) => data.trials?.[0]?.candidateScore,
  },
  {
    title: "Время прохождения ожидаемое",
    name: "expectedTimeOfTesting",
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      return isPersonal ? secondsToMinutes(data.questionsTime) : "-";
    },
    renderCollapse: (data) => secondsToMinutes(data.examination.questionsTime),
  },
  {
    title: "Время прохождения реальное",
    name: "timeOfTesting",
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      const trial = data.trials?.[0];
      return isPersonal ? secondsToMinutes(trial?.candidateTime) : "-";
    },
    renderCollapse: (data) => secondsToMinutes(data.candidateTime),
  },
  {
    title: "Тип тестирования",
    name: "examinationType",
    render: (data) => (data.type ? EXAMINATION_TYPE_TITLE[data.type] : "-"),
  },
  {
    title: "Оценка репозитория",
    name: "isRepositoryCheck",
    render: (data) => (data.competencyMap.isRepositoryCheck ? "Да" : "Нет"),
  },
  {
    title: "Ссылка на тестирование",
    name: "link",
    withoutSort: true,
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      if (isPersonal) {
        const trial = data.trials?.[0];
        if (!trial) return "-";
        const link = trial?.companyCandidate.candidateUsername?.login
          ? `${process.env.REACT_APP_ASSESSMENT_URL}/login`
          : `https://t.me/${process.env.REACT_APP_BOT_URL}?start=${trial?.id}`;
        return (
          <TestingLink
            withoutLabel
            link={link}
          />
        );
      }
      return "-";
    },
    renderCollapse: (data) => {
      const isOpen = data.examination.type === Examination_Type.Open;
      let link;
      if (isOpen) {
        link = `${process.env.REACT_APP_ASSESSMENT_URL}/examination/${data.examination.id}`;
      } else {
        link = data?.companyCandidate.candidateUsername?.login
          ? `${process.env.REACT_APP_ASSESSMENT_URL}/login`
          : `https://t.me/${process.env.REACT_APP_BOT_URL}?start=${data?.id}`;
      }
      if (link)
        return (
          <TestingLink
            withoutLabel
            link={link}
          />
        );
      return "-";
    },
  },
  {
    title: "Скачать отчет",
    name: "action",
    withoutSort: true,
    render: (data) => {
      const isPersonal = data.type === Examination_Type.Personal;
      const link = isPersonal ? data.trials?.[0]?.recruiterReportFile?.file.url : undefined;
      if (!link) return "-";
      return (
        <IconButton onClick={() => window.open(link)}>
          <DownloadIcon />
        </IconButton>
      );
    },
    renderCollapse: (data) => {
      const report = data.recruiterReportFile?.file.url;
      if (!report) return "-";
      return (
        <IconButton onClick={() => window.open(report)}>
          <DownloadIcon />
        </IconButton>
      );
    },
  },
];

export default columnConfig;
