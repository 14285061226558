import Box from "@mui/material/Box";
import useIsCreate from "hooks/useIsCreate";
import { useParams, useSearchParams } from "react-router-dom";
import { Examination_Type, useExaminationHeaderQuery } from "@generated/graphql";
import CircularLoading from "components/CircularLoading";
import { useMemo, useState } from "react";
import qs from "qs";
import Header from "./components/Header";
import Personal from "./components/Personal";
import GroupAndOpen from "./components/GroupAndOpen";

const TestingView = () => {
  const [selectedCandidates, setSelectedCandidates] = useState<string[]>([]);
  const isCreate = useIsCreate();
  const { examinationId } = useParams();
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const searchExaminationType = (params as unknown as { type?: string }).type;

  const { data, loading } = useExaminationHeaderQuery({
    variables: {
      examinationId: examinationId!,
    },
    skip: !examinationId,
  });

  const examinationType = (isCreate ? searchExaminationType : data?.examination.type) as Examination_Type | undefined;

  const onSubmitAddCandidateModal = (items: string[]) => {
    setSelectedCandidates(items);
  };

  if (loading) return <CircularLoading />;

  return (
    <Box>
      <Header
        examination={data?.examination}
        examinationType={examinationType}
        onSubmitAddCandidateModal={onSubmitAddCandidateModal}
      />
      {examinationType === Examination_Type.Personal && (
        <Personal
          examination={data?.examination}
          isCreate={isCreate}
        />
      )}
      {(examinationType === Examination_Type.Group || examinationType === Examination_Type.Open) && (
        <GroupAndOpen
          selectedCandidates={selectedCandidates}
          examinationType={examinationType}
        />
      )}
      {/* {examinationType === Examination_Type.Open && <Public />} */}
    </Box>
  );
};
export default TestingView;
