import React from "react";
import Box from "@mui/material/Box";
import { Chip, Divider, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CurrentCompanyIcon from "components/CurrentCompanyIcon";

interface CompanyCardProps {
  company: { id: string; shortName: string; currentUserPosition?: string | null };
  onCompanyClick?: () => void;
  button?: {
    text: string;
    onClick: () => void;
  };
  isCurrentCompany?: boolean;
}

const CompanyCard = ({ company, onCompanyClick, button, isCurrentCompany }: CompanyCardProps) => {
  const { shortName, currentUserPosition } = company;

  return (
    <Box
      sx={{
        p: 2.5,
        borderRadius: 3,
        backgroundColor: "bgSwitch",
        cursor: onCompanyClick ? "pointer" : "default",
        "&:hover": {
          backgroundColor: onCompanyClick ? "background.default" : undefined,
        },
      }}
      onClick={onCompanyClick}>
      <Typography
        variant='h20'
        sx={{ mb: 1.5 }}>
        {shortName}
      </Typography>
      {(currentUserPosition || button || isCurrentCompany) && <Divider sx={{ mb: 2 }} />}
      <Stack
        direction='row'
        justifyContent='space-between'>
        {currentUserPosition && (
          <Chip
            label={currentUserPosition}
            sx={{ backgroundColor: "#fff" }}
          />
        )}
        {button && (
          <Button
            onClick={button.onClick}
            variant='contained'>
            {button.text}
          </Button>
        )}
        {isCurrentCompany && (
          <CurrentCompanyIcon sx={{ width: 32, height: 32, "& > .MuiSvgIcon-root": { width: 24, height: 24 } }} />
        )}
      </Stack>
    </Box>
  );
};

export default CompanyCard;
