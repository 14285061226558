import { GRADE_OPTIONS } from "constants/global";
import { formatISODate } from "helpers/date";
import { ExaminationInfoQuery, Trial_Status } from "@generated/graphql";
import { CandidateType, ICandidateItem, InfoInputs } from "pages/Testing/view/components/Personal/InfoTab/types";
import { CompetencyMapOptionList } from "../../../types";

const useInitTrialForm = (
  trial: NonNullable<ExaminationInfoQuery["examination"]["trials"]>[number] | undefined,
  competencyMap: NonNullable<CompetencyMapOptionList>[number] | undefined
): InfoInputs => {
  const companyCandidate = trial?.companyCandidate;
  const candidateUsername = companyCandidate?.candidateUsername;
  const comment = trial?.comment;
  const trialStatusHistory = trial?.trialStatusHistory;
  const completedAt = trial?.completedAt;
  const phoneNumber = candidateUsername?.candidate?.phoneNumber?.replace("+", "") || "";

  const getTelegramUserName = () => {
    const telegramUsername = candidateUsername?.telegramUsername;
    if (telegramUsername) {
      return `@${telegramUsername}`;
    }
    return "";
  };

  const candidateData: ICandidateItem | null = companyCandidate
    ? {
        id: companyCandidate.id || "",
        fullName: companyCandidate.fullName || "",
        telegramUsername: getTelegramUserName(),
        phoneNumber,
        email: companyCandidate.email,
        position: companyCandidate.position,
        organization: companyCandidate.organization,
        grade: companyCandidate.grade,
        login: companyCandidate.candidateUsername?.login,
      }
    : null;

  const startedAt = trialStatusHistory?.find((history) => history.newStatus === Trial_Status.Started)?.createdAt;
  const login = candidateUsername?.login;
  const createdAt = trial?.createdAt;

  let assessmentLink;
  if (trial?.id) {
    assessmentLink = login
      ? `${process.env.REACT_APP_ASSESSMENT_URL}/login`
      : `https://t.me/${process.env.REACT_APP_BOT_URL}?start=${trial?.id}`;
  }

  return {
    id: trial?.id || "",
    createdAt: createdAt ? formatISODate(createdAt, "dd.MM.yyyy HH:mm") : "",
    competencyMap: competencyMap
      ? {
          id: competencyMap.id || "",
          title: competencyMap.title || "",
          comment: competencyMap.comment || "",
          grades: competencyMap.grades?.join(", ") || "",
        }
      : null,
    link: assessmentLink,
    candidateType: login ? CandidateType.Login : CandidateType.Telegram,
    candidate: {
      id: companyCandidate?.id || "",
      fullName: candidateData,
      grade: GRADE_OPTIONS?.find((grade) => companyCandidate?.grade === grade.id) || null,
      telegramUsername: candidateData,
      phoneNumber: candidateUsername?.candidate?.phoneNumber?.replace("+", "") || "",
      email: companyCandidate?.email || "",
      organization: companyCandidate?.organization,
      position: companyCandidate?.position,
      login: candidateData,
    },
    comment,
    completedAt: completedAt ? formatISODate(completedAt, "dd.MM.yyyy HH:mm") : "",
    startedAt: startedAt ? formatISODate(startedAt, "dd.MM.yyyy HH:mm") : "",
  };
};

export default useInitTrialForm;
