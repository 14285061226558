import { ColumnConfig } from "layout/TableConstructor/types";
import { CompetencyMapsQuery } from "@generated/graphql";
import { gradeColor } from "helpers/index";
import { Chip, Stack } from "@mui/material";
import React from "react";
import { COMPETENCY_MAP_STATUS_TITLE } from "constants/global";
import dayjs from "dayjs";
import { QUESTION_LANGUAGE_TITLE } from "constants/language";

export type CompetencyMapItem = NonNullable<CompetencyMapsQuery["competencyMaps"]["data"]>[number];

const columnConfig: ColumnConfig<CompetencyMapItem>[] = [
  // {
  //   name: "numericId",
  //   title: "ID",
  //   render: (data) => data.numericId,
  // },
  {
    name: "title",
    title: "Название",
    render: (data) => data.title,
  },
  {
    name: "specialization",
    title: "Специализация",
    render: (data) => data.specialization || "-",
  },
  {
    name: "grades",
    title: "Грейд",
    render: (data) =>
      data.grades ? (
        <Stack
          spacing={0.5}
          direction='row'>
          {data.grades?.map((grade) => (
            <Chip
              key={`grade-${grade}`}
              label={grade}
              sx={{ backgroundColor: `${gradeColor(grade)}.light`, color: `${gradeColor(grade)}.contrast` }}
            />
          ))}
        </Stack>
      ) : (
        "-"
      ),
  },
  {
    name: "competencesCount",
    title: "Количество компетенций",
    render: (data) => data.competencesCount || 0,
  },
  {
    name: "trialsCount",
    title: "Количество тестирований",
    render: (data) => data.trialsCount || 0,
  },
  {
    name: "createdAt",
    title: "Дата создания",
    render: (data) => (data.createdAt ? dayjs(data.createdAt).format("DD.MM.YYYY") : "-"),
  },
  {
    name: "updatedAt",
    title: "Дата изменения",
    render: (data) => (data.updatedAt ? dayjs(data.updatedAt).format("DD.MM.YYYY") : "-"),
  },
  {
    name: "lastTrialDate",
    title: "Дата последнего тестирования",
    render: (data) => (data.lastTrialDate ? dayjs(data.lastTrialDate).format("DD.MM.YYYY") : "-"),
  },
  {
    name: "status",
    title: "Статус",
    render: (data) => (data.status ? COMPETENCY_MAP_STATUS_TITLE[data.status] : "-"),
  },
  {
    name: "languages",
    title: "Язык",
    render: (data) =>
      data.languages && data.languages?.length > 0
        ? data.languages.map((l) => QUESTION_LANGUAGE_TITLE[l]).join(", ")
        : "-",
  },
  // TODO интегрировать скачивание КК
  // {
  //   name: "action",
  //   title: "Действие",
  //   withoutSort: true,
  //   render: () => {
  //     return (
  //       <IconButton>
  //         <DownloadIcon />
  //       </IconButton>
  //     );
  //   },
  // },
];

export default columnConfig;
