import { useNavigate, useParams } from "react-router-dom";
import { useKnowledgeBlockQuery, useSkillsQuery } from "@generated/graphql";
import CircularLoading from "components/CircularLoading";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Filters from "layout/ListLayout/components/Filters";
import { useForm } from "react-hook-form";
import ScillCard, { SkillCardProps } from "pages/KnowledgeBlocks/view/components/SkillCard";
import Info from "pages/KnowledgeBlocks/view/components/Info";
import { generateFilterQuery } from "components/generators/filtersGenerator";
import useList from "hooks/useList";
import _ from "lodash";
import EmptyList from "components/EmptyList";
import useIsCreate from "hooks/useIsCreate";
import useSetTabTitle from "hooks/useSetTabTitle";
import ROUTES from "constants/routes";
import { CompanyContext } from "context/companyContext";
import ButtonWithPermissions, { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import CompetencyMapsList from "./components/CompetencyMapsList";
import filterFields from "./filterFields";

const KnowledgeBlockView = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const createSkillUnavailableText = generateUnavailableText(isCreateAvailable, currentCompany, "создания навыков");

  const navigate = useNavigate();
  const isCreate = useIsCreate();
  const { knowledgeBlockId } = useParams();
  const { data, loading } = useKnowledgeBlockQuery({
    variables: {
      knowledgeBlockId: knowledgeBlockId!,
    },
    skip: !knowledgeBlockId,
  });

  const {
    data: skillsData,
    loading: skillsLoading,
    refetch,
  } = useSkillsQuery({
    variables: {
      page: 0,
      limit: 1000,
      filter: {
        knowledgeBlockId: knowledgeBlockId!,
      },
    },
    skip: !knowledgeBlockId,
  });

  const knowledgeBlock = data?.knowledgeBlock;
  const form = useForm();

  const { params } = useList();

  useEffect(() => {
    if (refetch && knowledgeBlockId) {
      refetch({
        filter: _.merge(
          {
            knowledgeBlockId: knowledgeBlockId!,
          },
          generateFilterQuery(filterFields, params)
        ),
      });
    }
  }, [params]);

  const skills = skillsData?.skills.data;

  const title = `Блок знаний ${knowledgeBlock?.title ? ` «${knowledgeBlock.title}»` : ""}`;
  useSetTabTitle(title, knowledgeBlock?.title);

  const onCreateSkill = () => {
    navigate(
      `/${ROUTES.SKILL}/create?knowledgeBlockId=${knowledgeBlockId}&knowledgeBlockTitle=${knowledgeBlock?.title}`
    );
  };

  if (loading || skillsLoading) return <CircularLoading />;

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        mb={2.5}>
        <Typography variant='h28'>{title}</Typography>
        {!isCreate && (
          <ButtonWithPermissions
            buttonText='Создать навык'
            onClick={onCreateSkill}
            tooltipText={createSkillUnavailableText}
          />
        )}
      </Stack>
      <Filters
        form={form}
        filters={filterFields}
      />
      <Grid
        container
        columnSpacing={2.5}>
        <Grid
          container
          item
          xs={12}
          md={9}
          columnSpacing={2.5}
          rowSpacing={2.5}
          sx={{ height: "fit-content" }}>
          {skills?.length ? (
            skillsData?.skills.data?.map((skill) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                key={`skill-${skill.id}`}>
                <ScillCard skill={skill as SkillCardProps} />
              </Grid>
            ))
          ) : (
            <EmptyList emptyPlaceholderText='Пока нет навыков' />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={3}>
          <Stack spacing={2.5}>
            <Info knowledgeBlock={knowledgeBlock} />
            {!isCreate && <CompetencyMapsList competencyMaps={knowledgeBlock?.competenceMaps} />}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default KnowledgeBlockView;
