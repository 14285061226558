import React, { useCallback, useContext, useMemo } from "react";
import { debounce } from "lodash";
import { UseFormReturn } from "react-hook-form";
import { AutocompleteInputChangeReason, FilterOptionsState } from "@mui/material";
import AutocompleteControl from "components/form/AutocompleteControl";
import TextFieldControl from "components/form/TextFieldControl";
import { GRADE_OPTIONS } from "constants/global";
import { useCompanyCandidatesQuery } from "@generated/graphql";
import { CompanyContext } from "context/companyContext";
import VALIDATION from "helpers/validation";
import { InfoInputs, ICandidateItem, CandidateType } from "../types";

type CandidateInfoProps = {
  form: UseFormReturn<InfoInputs>;
  isEditing: boolean;
  resetCandidateFields: () => void;
};

const CandidateInfo: React.FC<CandidateInfoProps> = ({ form, isEditing, resetCandidateFields }) => {
  const { currentCompanyId } = useContext(CompanyContext);
  const { setValue, watch } = form;
  const candidateType = watch("candidateType");
  const isTelegramUser = candidateType === CandidateType.Telegram;
  const candidateFieldName = isTelegramUser ? "telegramUsername" : "login";

  const isUserExist = !!watch("candidate.id");

  const {
    loading: candidatesLoading,
    data: candidatesData,
    refetch,
  } = useCompanyCandidatesQuery({
    variables: { input: { isTelegram: isTelegramUser }, page: 0, limit: 15 },
    notifyOnNetworkStatusChange: true,
  });

  const candidateList: ICandidateItem[] = useMemo(
    () =>
      candidatesData?.companyCandidates.data?.map((candidate) => {
        const { candidateUsername, id, fullName } = candidate;
        const companyCandidate = candidateUsername?.companyCandidate?.find((c) => c.companyId === currentCompanyId);

        return {
          id,
          fullName,
          telegramUsername: candidateUsername?.telegramUsername || "",
          phoneNumber: candidateUsername?.candidate?.phoneNumber?.replace("+", "") || "",
          email: companyCandidate?.email || "",
          position: companyCandidate?.position || "",
          login: candidateUsername?.login || "",
          grade: companyCandidate?.grade || null,
          organization: companyCandidate?.organization || "",
        };
      }) || [],
    [candidatesData]
  );

  const debounceQuery = useCallback(
    debounce((inputValue, fieldName) => {
      refetch({ input: { [fieldName]: inputValue, isTelegram: isTelegramUser }, limit: 15, page: 0 });
    }, 500),
    [isTelegramUser]
  );

  const onAutocompleteInputChange = (
    inputValue: string,
    reason: AutocompleteInputChangeReason,
    filterName: "fullName" | "telegramUsername" | "login"
  ) => {
    if (reason === "clear") {
      resetCandidateFields();
    } else if (reason === "input") {
      const searchValue = inputValue.replace("@", "");
      debounceQuery(searchValue, filterName);
    }
  };

  const setCandidateData = (data: ICandidateItem) => {
    [
      { name: "candidate.grade", value: GRADE_OPTIONS?.find((grade) => data?.grade === grade.id) },
      { name: "candidate.organization", value: data?.organization },
      { name: "candidate.position", value: data?.position },
      { name: "candidate.email", value: data?.email },
      { name: "candidate.phoneNumber", value: data?.phoneNumber },
      { name: "candidate.id", value: data?.id },
    ].forEach(({ name, value }) => setValue(name as keyof InfoInputs, value as InfoInputs[keyof InfoInputs]));
  };

  const handleFullNameChange = (data: ICandidateItem | null | string): ICandidateItem | string | null => {
    if (!data || typeof data === "string" || !("candidateUsername" in data)) return data;
    setCandidateData(data);
    const { telegramUsername, login, ...rest } = data;
    setValue(`candidate.${candidateFieldName}`, {
      telegramUsername: telegramUsername ? `@${telegramUsername}` : telegramUsername || "",
      login,
      ...rest,
    });
    return data;
  };

  const handleUsernameChange = (data: ICandidateItem | null | string): ICandidateItem | string | null => {
    if (!data || typeof data === "string") return data;

    if ("fullName" in data) {
      setCandidateData(data);
      setValue("candidate.fullName", data);
    }

    if (isTelegramUser) {
      const { telegramUsername, ...rest } = data;
      const firstSymbol = telegramUsername?.[0];
      const telegramUsernameValue = firstSymbol === "@" ? telegramUsername : `@${telegramUsername}`;

      return { telegramUsername: telegramUsernameValue, ...rest };
    }

    return data;
  };

  const onChangeUsernameTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newEvent = event as React.ChangeEvent<HTMLInputElement>;
    const inputValue = event.target.value;
    newEvent.target.value =
      inputValue?.length > 1 || inputValue === "@" ? inputValue.replace(/(?!^)@/g, "") : `@${inputValue}`;
    return newEvent;
  };

  const filterUsernameOptions = (params: FilterOptionsState<ICandidateItem>) => {
    const newParams = params;
    newParams.inputValue = params.inputValue.replace("@", "");
    return newParams;
  };

  return (
    <>
      <AutocompleteControl<ICandidateItem, false, false, true, ICandidateItem>
        name='candidate.fullName'
        label='ФИО'
        rules={{ required: true }}
        disabled={!isEditing}
        options={candidateList}
        loading={candidatesLoading}
        onInputChange={(event, value, reason) => onAutocompleteInputChange(value, reason, "fullName")}
        optionName='fullName'
        freeSolo
        formatDataBeforeOnChange={handleFullNameChange}
      />
      {isTelegramUser ? (
        <AutocompleteControl<ICandidateItem, false, false, true, ICandidateItem>
          name='candidate.telegramUsername'
          label='Профиль Telegram'
          rules={{ required: true }}
          disabled={!isEditing}
          options={candidateList}
          loading={candidatesLoading}
          optionName='telegramUsername'
          freeSolo
          onInputChange={(event, value, reason) => onAutocompleteInputChange(value, reason, "telegramUsername")}
          onChangeTextField={onChangeUsernameTextField}
          filterOptionsParams={filterUsernameOptions}
          formatDataBeforeOnChange={handleUsernameChange}
        />
      ) : (
        <AutocompleteControl<ICandidateItem, false, false, true, ICandidateItem>
          name='candidate.login'
          label='Логин пользователя'
          rules={{ required: true }}
          disabled={!isEditing}
          options={candidateList}
          loading={candidatesLoading}
          optionName='login'
          freeSolo
          onInputChange={(event, value, reason) => onAutocompleteInputChange(value, reason, "login")}
          formatDataBeforeOnChange={handleUsernameChange}
        />
      )}

      {!isTelegramUser && (
        <TextFieldControl
          name='password'
          label='Пароль'
          type='password'
          disabled={isUserExist}
        />
      )}
      <TextFieldControl
        name='candidate.phoneNumber'
        label='Номер телефона'
        disabled
        maskProps={{ mask: "+{7} (000) 000-00-00" }}
      />
      <TextFieldControl
        name='candidate.email'
        label='Почта'
        disabled={!isEditing}
      />
      <TextFieldControl
        name='candidate.organization'
        label='Организация'
        rules={{ ...VALIDATION.companyName }}
        disabled={!isEditing}
      />
      <TextFieldControl
        name='candidate.position'
        label='Должность'
        rules={{ ...VALIDATION.position }}
        disabled={!isEditing}
      />
      <AutocompleteControl
        options={GRADE_OPTIONS}
        name='candidate.grade'
        label='Грейд кандидата'
        disabled={!isEditing}
      />
    </>
  );
};

export default CandidateInfo;
