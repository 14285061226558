import TabLayout from "layout/TabLayout";
import { ExaminationHeaderQuery, Trial_Status } from "@generated/graphql";
import InfoTab from "./InfoTab";
import ResultTab from "./ResultTab";

interface PersonalProps {
  examination: ExaminationHeaderQuery["examination"] | undefined;
  isCreate: boolean;
}

const Personal = ({ examination, isCreate }: PersonalProps) => {
  const TABS = [
    {
      title: "Общая информация",
      id: "info",
      component: <InfoTab />,
    },
    {
      title: "Результаты",
      id: "result",
      component: <ResultTab />,
      disabled: isCreate || examination?.trials?.[0]?.status !== Trial_Status.Completed,
    },
  ];

  return (
    <TabLayout
      tabs={TABS}
      defaultTab='info'
    />
  );
};

export default Personal;
