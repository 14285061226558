import { formatISODate } from "helpers/date";
import { ExaminationInfoQuery } from "@generated/graphql";
import { useMemo } from "react";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import { CompetencyMapOptionList, GroupAndOpenInfoInputs } from "../../types";

const useInitBaseExaminationForm = (
  examination: ExaminationInfoQuery["examination"] | undefined,
  competencyMaps: CompetencyMapOptionList
): GroupAndOpenInfoInputs => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { mapId } = params as unknown as { mapId?: string };

  const competencyMap = mapId ? competencyMaps?.find((map) => map.id === mapId) : examination?.competencyMap;
  const createdAt = examination?.createdAt;
  const assessmentLink = examination
    ? `${process.env.REACT_APP_ASSESSMENT_URL}/examination/${examination.id}`
    : undefined;

  return {
    id: examination?.id || "",
    createdAt: createdAt ? formatISODate(createdAt, "dd.MM.yyyy HH:mm") : "",
    competencyMap: competencyMap
      ? {
          id: competencyMap.id || "",
          title: competencyMap.title || "",
          comment: competencyMap.comment || "",
          grades: competencyMap.grades?.join(", ") || "",
        }
      : null,
    link: assessmentLink,
  };
};

export default useInitBaseExaminationForm;
