import React, { ReactNode } from "react";
import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import CloseIcon from "assets/icons/CloseIcon";
import Box from "@mui/material/Box";

type CustomModalProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  content: ReactNode;
  handleClick?: () => void;
  buttonText?: string;
  maxWidth?: Breakpoint | false;
  withoutButtons?: boolean;
};

const CustomModal = ({
  open,
  onClose,
  title,
  content,
  handleClick,
  buttonText,
  maxWidth = "md",
  withoutButtons = false,
}: CustomModalProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth={maxWidth}
    fullWidth>
    <Stack
      justifyContent='space-between'
      alignItems='center'
      flexDirection='row'>
      <Box />
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        sx={{ top: 0, right: 0 }}
        onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>

    <DialogContent>{content}</DialogContent>
    {!withoutButtons && (
      <DialogActions>
        <Button
          variant='outlined'
          onClick={onClose}>
          Отмена
        </Button>
        <Button
          onClick={handleClick}
          variant='contained'>
          {buttonText}
        </Button>
      </DialogActions>
    )}
  </Dialog>
);
export default CustomModal;
