import { Stack, Typography } from "@mui/material";
import Form from "components/form/Form";
import TextFieldControl from "components/form/TextFieldControl";
import { LoadingButton } from "@mui/lab";
import React, { useContext } from "react";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { FieldErrors, useForm } from "react-hook-form";
import { MeQuery, useUpdateUserMutation } from "@generated/graphql";
import VALIDATION from "helpers/validation";

interface ProfileDataForm {
  userEmail: { email: string; id: string };
  middleName?: string | undefined | null;
  lastName?: string | undefined | null;
  firstName?: string | undefined | null;
}

interface ProfileFormProps {
  user: MeQuery["me"];
}

const ProfileForm: React.FC<ProfileFormProps> = ({ user }) => {
  const { addToast } = useContext(ToastContext);

  const form = useForm<ProfileDataForm>({
    values: user,
  });
  const { handleSubmit } = form;

  const [updateUser] = useUpdateUserMutation();

  const onSubmit = (formData: ProfileDataForm) => {
    updateUser({
      variables: {
        input: {
          email: formData.userEmail.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          middleName: formData.middleName,
        },
      },
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const onError = (errors: FieldErrors<ProfileDataForm>) => {
    const text = errors.userEmail?.email?.message;
    addToast({ type: ToastTypeEnum.ERROR, text });
  };

  return (
    <>
      <Typography
        variant='h28'
        sx={{ mb: 3 }}>
        Мои данные
      </Typography>
      <Form form={form}>
        <Stack spacing={3}>
          <TextFieldControl
            name='lastName'
            label='Фамилия'
            rules={{ required: true, ...VALIDATION.userName }}
          />
          <TextFieldControl
            name='firstName'
            label='Имя'
            rules={{ required: true, ...VALIDATION.userName }}
          />
          <TextFieldControl
            name='middleName'
            label='Отчество'
            rules={{ required: true, ...VALIDATION.userName }}
          />
          <TextFieldControl
            name='userEmail.email'
            label='Email'
            type='email'
            rules={{
              required: true,
              ...VALIDATION.emailFull,
            }}
          />
          <LoadingButton
            variant='contained'
            size='large'
            onClick={handleSubmit(onSubmit, onError)}>
            Сохранить
          </LoadingButton>
        </Stack>
      </Form>
    </>
  );
};

export default ProfileForm;
