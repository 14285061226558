import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useController } from "react-hook-form";
import { CandidateType } from "../types";

interface ISelectCandidateTypeProps {
  resetCandidateFields: () => void;
  isEditing: boolean;
}

const SelectCandidateType = ({ resetCandidateFields, isEditing }: ISelectCandidateTypeProps) => {
  const {
    field: { value, onChange },
  } = useController({
    name: "candidateType",
  });
  return (
    <FormControl disabled={!isEditing}>
      <FormLabel id='demo-radio-buttons-group-label'>Вид авторизации</FormLabel>
      <RadioGroup
        name='candidate-type'
        value={value}
        onChange={(_, value) => {
          onChange(value as CandidateType);
          resetCandidateFields();
        }}>
        <FormControlLabel
          value={CandidateType.Telegram}
          control={<Radio />}
          label='Кандидата с Telegram-аккаунтом'
        />
        <FormControlLabel
          value={CandidateType.Login}
          control={<Radio />}
          label='Кандидат без Telegram-аккаунта'
        />
      </RadioGroup>
    </FormControl>
  );
};

export default SelectCandidateType;
