import { InputType } from "components/generators/inputsGenerator";
import { COMPETENCY_MAP_STATUS_OPTIONS, GRADE_OPTIONS } from "constants/global";
import { Competency_Map_Status, CompetencyMapsQueryVariables, Grade } from "@generated/graphql";
import VALIDATION from "helpers/validation";
import { FilterField } from "layout/TableConstructor/types";
import dayjs from "dayjs";

export type CompetencyMapFilters = NonNullable<CompetencyMapsQueryVariables["filter"]>;

const filterFields: FilterField<CompetencyMapFilters>[] = [
  {
    name: "numericId",
    label: "ID",
    type: InputType.TEXT,
    rules: VALIDATION.inn, // только цифры
    // TODO type number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "title",
    label: "Карта компетенций",
    type: InputType.TEXT,
    rules: VALIDATION.competencyMapTitle,
  },
  // {
  //   name: "specialization",
  //   label: "Специализация",
  //   type: InputType.TEXT,
  //   rules: VALIDATION.competencyMapTitle,
  // },
  {
    name: "grades",
    label: "Грейд",
    multiple: true,
    type: InputType.AUTOCOMPLETE,
    options: GRADE_OPTIONS,
    customFilter: (grades: { id: Grade; displayName: string }[]) => grades.map((grade) => grade.id),
    limitTags: 1,
  },
  {
    name: "competencesCount",
    label: "Количество компетенций",
    type: InputType.TEXT,
    rules: VALIDATION.inn,
    // type: number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "trialsCount",
    label: "Количество тестирований",
    type: InputType.TEXT,
    rules: VALIDATION.inn,
    // type: number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "createdAt",
    label: "Дата создания",
    type: InputType.DATE,
    customFilter: (value) => dayjs(value).toISOString(),
  },
  {
    name: "updatedAt",
    label: "Дата изменения",
    type: InputType.DATE,
    customFilter: (value) => dayjs(value).toISOString(),
  },
  // {
  //   name: "isRepositoryCheck",
  //   label: "Проверка репозитория",
  //   type: InputType.AUTOCOMPLETE,
  //   options: [
  //     { id: true, value: "Да" },
  //     { id: false, displayName: "Нет" },
  //   ],
  //   customFilter: (option: { id: boolean; displayName: string }) => String(option.id),
  //   limitTags: 1,
  // },
  {
    name: "status",
    label: "Статус",
    type: InputType.AUTOCOMPLETE,
    options: COMPETENCY_MAP_STATUS_OPTIONS,
    customFilter: (value: { id: Competency_Map_Status; displayName: string }) => value.id,
    limitTags: 1,
  },
  // {
  //   name: "languages",
  //   label: "Язык",
  //   type: InputType.AUTOCOMPLETE,
  //   options: COMPETENCY_MAP_STATUS_OPTIONS,
  //   customFilter: (value: { id: Language; displayName: string }) => value.id,
  //   limitTags: 1,
  // },
];

export default filterFields;
