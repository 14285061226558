import { createContext, ReactNode, useContext, useMemo } from "react";
import {
  Company_Status,
  CompanyQuery,
  Exact,
  Organization_State,
  Type_Of_Organization,
  useCompanyQuery,
} from "@generated/graphql";
import { ApolloError, ApolloQueryResult, useApolloClient } from "@apollo/client";
import cookies from "js-cookie";
import CircularLoading from "components/CircularLoading";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { setAuthCookies } from "helpers/cookieHelper";

interface CompanyContextValue {
  loading: boolean;
  error: ApolloError | undefined;
  currentCompany: CompanyQuery["company"] | undefined;
  currentCompanyId: string | undefined;
  refetchCompany: (
    variables?: Partial<Exact<{ [p: string]: never }>> | undefined
  ) => Promise<ApolloQueryResult<CompanyQuery>>;
  handleSelectCompany: (companyId: string) => void;
  isCreateAvailable: boolean;
}

export const CompanyContext = createContext<CompanyContextValue>({
  loading: false,
  error: undefined,
  currentCompany: undefined,
  currentCompanyId: undefined,
  isCreateAvailable: false,
  handleSelectCompany: () => {},
  refetchCompany: async () => ({
    data: {
      company: {
        id: "",
        identification: "",
        inn: "",
        shortName: "",
        fullName: "",
        kpp: "",
        managerFio: "",
        managerPosition: "",
        ogrn: "",
        okato: "",
        ogrnDate: 0,
        okpo: "",
        oktmo: "",
        type: Type_Of_Organization.Legal,
        registrationDate: 0,
        state: Organization_State.Active,
        registrationAddress: "",
        status: Company_Status.Created,
      },
    },
    loading: false,
    networkStatus: 7,
    stale: false,
  }),
});

export const CompanyProvider = ({ children }: { children: ReactNode }) => {
  const { data, loading, error, refetch: refetchCompany } = useCompanyQuery();
  const { addToast } = useContext(ToastContext);
  const client = useApolloClient();

  const currentCompanyId = cookies.get("companyId");

  const currentCompany = data?.company;

  const handleSelectCompany = (companyId: string) => {
    setAuthCookies({ name: "companyId", value: companyId });
    refetchCompany()
      .then(() => {
        client
          .resetStore()
          .then(() => addToast({ type: ToastTypeEnum.SUCCESS, text: "Компания изменена" }))
          .catch(() => addToast({ type: ToastTypeEnum.ERROR, text: "Произошла ошибка при изменении компании" }));
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR, text: "Произошла ошибка при изменении компании" }));
  };

  const currentCompanyStatus = currentCompany?.status;
  const isCreateAvailable =
    currentCompanyStatus !== Company_Status.NotApproved && currentCompanyStatus !== Company_Status.PartlyApproved;

  const providerValue = useMemo(
    () => ({
      currentCompany,
      loading,
      error,
      currentCompanyId,
      refetchCompany,
      handleSelectCompany,
      isCreateAvailable,
    }),
    [currentCompany, loading, error, currentCompanyId, handleSelectCompany, isCreateAvailable]
  );

  return (
    <CompanyContext.Provider value={providerValue}>{loading ? <CircularLoading /> : children}</CompanyContext.Provider>
  );
};
