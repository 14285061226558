import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TestingSelectModalContext } from "context/testingSelectModalContext";
import { CompanyContext } from "context/companyContext";
import ButtonWithPermissions, { generateUnavailableText } from "components/buttons/ButtonWithPermissions";

const Header = () => {
  const navigate = useNavigate();
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const { handleOpen } = useContext(TestingSelectModalContext);

  const createAssessmentUnavailableText = generateUnavailableText(
    isCreateAvailable,
    currentCompany,
    "создания тестирования"
  );

  const handleCreateButtonClick = () => {
    handleOpen({ navigate });
  };
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      alignItems={{ xs: "flex-start", md: "center" }}
      justifyContent='space-between'>
      <Typography variant='h28'>Тестирования</Typography>
      <Stack
        direction='row'
        spacing={1.5}>
        <ButtonWithPermissions
          buttonText='Создать тестирование'
          tooltipText={createAssessmentUnavailableText}
          onClick={handleCreateButtonClick}
        />
      </Stack>
    </Stack>
  );
};

export default Header;
