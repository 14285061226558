import Form from "components/form/Form";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  Competency_Map_Status,
  Examination_Type,
  useCompetencyMapOptionsQuery,
  useExaminationInfoQuery,
} from "@generated/graphql";
import { useParams } from "react-router-dom";
import CircularLoading from "components/CircularLoading";
import { useContext } from "react";
import useIsCreate from "hooks/useIsCreate";
import ButtonWithPermissions, { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import { CompanyContext } from "context/companyContext";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import InfoBlock from "../InfoBlock";
import { GroupAndOpenInfoInputs } from "../types";
import GroupAndOpenLeft from "./components/Left";
import GroupAndOpenRight from "./components/Right";
import useInitBaseExaminationForm from "./hooks/useInitExaminationForm";
import useCreateExamination from "../../hooks/useCreateExamination";

interface GroupAndOpenProps {
  selectedCandidates: string[];
  examinationType: Examination_Type.Group | Examination_Type.Open | undefined;
}

const GroupAndOpen = ({ selectedCandidates, examinationType }: GroupAndOpenProps) => {
  const { examinationId } = useParams();
  const isCreate = useIsCreate();
  const { addToast } = useContext(ToastContext);
  const { data: competencyMapsData, loading: competencyMapsLoading } = useCompetencyMapOptionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        status: Competency_Map_Status.Approved,
      },
    },
  });
  const { createExaminationLoading, onCreateExamination } = useCreateExamination();

  const competencyMaps = competencyMapsData?.competencyMaps.data;

  const { data, loading } = useExaminationInfoQuery({
    variables: {
      examinationId: examinationId!,
    },
    skip: !examinationId,
  });

  const initFormData = useInitBaseExaminationForm(data?.examination, competencyMaps);

  const form = useForm<GroupAndOpenInfoInputs>({ values: initFormData });
  const { handleSubmit } = form;

  const onGroupSubmit = (formData: GroupAndOpenInfoInputs) => {
    if (!selectedCandidates.length) {
      addToast({ type: ToastTypeEnum.ERROR, text: "Не выбран ни один кандидат" });
    } else {
      onCreateExamination({
        competencyMapId: formData.competencyMap?.id!,
        groupInput: { companyCandidateIds: selectedCandidates },
      });
    }
  };

  const onOpenSubmit = (formData: GroupAndOpenInfoInputs) => {
    onCreateExamination({
      competencyMapId: formData.competencyMap?.id!,
    });
  };

  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);

  if (loading) return <CircularLoading />;

  return (
    <Box sx={{ py: 2, px: 2.5, backgroundColor: "white", borderRadius: 2, width: "100%", marginBottom: 2.5 }}>
      <Form form={form}>
        <InfoBlock
          LeftElement={
            <GroupAndOpenLeft
              competencyMaps={competencyMaps}
              competencyMapsLoading={competencyMapsLoading}
              isCreate={isCreate}

            />
          }
          RightElement={
            <GroupAndOpenRight
              form={form}
              examinationType={examinationType}
            />
          }
          ControlsElement={
            isCreate ? (
              <ButtonWithPermissions
                buttonText='Сохранить'
                onClick={handleSubmit(examinationType === Examination_Type.Group ? onGroupSubmit : onOpenSubmit)}
                tooltipText={generateUnavailableText(isCreateAvailable, currentCompany, "создания")}
                loading={loading || createExaminationLoading}
              />
            ) : undefined
          }
        />
      </Form>
    </Box>
  );
};

export default GroupAndOpen;
