import React, { useContext } from "react";
import { Stack, Typography } from "@mui/material";
import CompanyCard from "components/CompanyCard";
import { UserCompaniesQuery } from "@generated/graphql";
import { CompanyContext } from "context/companyContext";

interface CompaniesProps {
  companies: UserCompaniesQuery["userCompanies"];
}

const Companies: React.FC<CompaniesProps> = ({ companies }) => {
  const { currentCompanyId, handleSelectCompany } = useContext(CompanyContext);

  return (
    <>
      <Typography
        variant='h28'
        sx={{ mb: 3, mt: 3.5 }}>
        Мои компании
      </Typography>
      <Stack spacing={3}>
        {companies?.map((company) => {
          const isCurrentCompany = currentCompanyId === company.id;
          return (
            <CompanyCard
              key={company.id}
              onCompanyClick={!isCurrentCompany ? () => handleSelectCompany(company.id) : undefined}
              isCurrentCompany={isCurrentCompany}
              company={{
                id: company.id,
                shortName: company.shortName,
                currentUserPosition: company.currentUserPosition,
              }}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default Companies;
