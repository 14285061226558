import { Examination_Type, useExaminationListQuery } from "@generated/graphql";
import useSetTabTitle from "hooks/useSetTabTitle";
import { useNavigate } from "react-router-dom";
import TableConstructor from "layout/TableConstructor";
import columnConfig, { ExaminationItem } from "pages/Testing/list/config/columnConfig";
import ROUTES from "constants/routes";
import { Stack } from "@mui/material";
import Header from "./components/Header";
import filterFields, { ExaminationFilter } from "./config/filterFields";
import columnFilterMap from "./config/columnFilterMap";

const TestingList = () => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useExaminationListQuery({
    variables: {
      page: 0,
      limit: 50,
      filter: {},
    },
  });
  const list = data?.examinations.data || [];

  useSetTabTitle("Тестирования кандидатов");

  const navigateToView = (
    examination: ExaminationItem,
    trial?: NonNullable<ExaminationItem["trials"]>[number] | null
  ) => {
    if (trial) {
      navigate(`/${ROUTES.TESTING}/view/${trial.id}`);
    } else {
      navigate(`/${ROUTES.TESTING}/view/${examination.id}`);
    }
  };

  return (
    <Stack spacing={2.5}>
      <Header />
      <TableConstructor<ExaminationItem, ExaminationFilter, undefined, "trials">
        data={list}
        totalCount={data?.examinations.totalCount}
        loading={loading}
        columnsConfig={columnConfig}
        filtersConfig={filterFields}
        checkIsCollapsingRow={(row) => row.type !== Examination_Type.Personal}
        colapsingRowName='trials'
        defaultColumns={[
          "numericId",
          "competencyMapTitle",
          "competencesCount",
          "candidateId",
          "candidateFullName",
          "createdAt",
          "trialStatus",
          "examinationType",
        ]}
        columnFilterMap={columnFilterMap}
        emptyPlaceholderText='Пока нет тестирований'
        refetch={refetch}
        onRowClick={navigateToView}
      />
    </Stack>
  );
};

export default TestingList;
