import { ArrowBack } from "@mui/icons-material";
import Button from "@mui/material/Button";
import React from "react";
import { Link } from "react-router-dom";

type BackPageButtonProps = {
  text: string;
  link: string;
};
const BackPageButton = ({ text, link }: BackPageButtonProps) => (
  <Button
    component={Link}
    to={link}
    startIcon={<ArrowBack />}
    sx={{ px: 0, justifyContent: "flex-start", color: "base.200" }}>
    {text}
  </Button>
);

export default BackPageButton;
