import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import LogoImg from "assets/images/logo.svg";
import { useContext } from "react";
import { UserContext } from "context/userContext";
import SecretCompanyCode from "components/SecretCompanyCode";
import Navigation from "layout/MainLayout/components/Navigation";
import User from "layout/MainLayout/components/User";
import { CompanyContext } from "context/companyContext";

const Header = () => {
  const { currentCompany } = useContext(CompanyContext);
  const { me, meError, meLoading } = useContext(UserContext);
  const isAuth = !!me && !meError && !meLoading;

  return (
    <AppBar position='static'>
      <Container maxWidth='xl'>
        <Toolbar
          disableGutters
          sx={{ alignItems: "center" }}>
          <Box
            src={LogoImg}
            component='img'
            alt='FoxtailBox Logo'
            sx={{ height: 40, width: 172, mr: 4 }}
          />
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Navigation isAuth={isAuth} />
            {isAuth && currentCompany?.identification && (
              <SecretCompanyCode
                code={currentCompany?.identification}
                sx={{ ml: 4 }}
              />
            )}
          </Box>
          {isAuth && <User />}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
