import React, { FC } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useController } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import CalendarIcon from "assets/icons/CalendarIcon";
import { isBefore, isAfter, isValid, parseISO } from "date-fns";

export type DatePickerProps = {
  name: string;
  label?: string;
  rules?: object;
  defaultValue?: string;
  disabled?: boolean;
  maxDate?: Date;
};

const DatePickerControl: FC<DatePickerProps> = ({ name, label, rules, defaultValue, disabled, maxDate }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    rules: {
      ...rules,
      validate: (value) => {
        if (!value) return value;
        const dateValue = typeof value === "string" ? parseISO(value) : value;
        const isDateValid = isValid(dateValue);
        if (!isDateValid) return "Неверный формат даты";
        const isDateBefore = isBefore(dateValue, new Date("1900-01-01"));
        if (isDateBefore) return "Дата не может быть раньше 01.01.1900";
        const isDateAfter = isAfter(dateValue, new Date());
        if (isDateAfter) return "Нельзя выбрать дату в будущем";
        return dateValue;
      },
    },
  });
  return (
    <Stack spacing={0.5}>
      <Typography
        variant='body14rg'
        sx={{ color: "base.500" }}>
        {label}
      </Typography>
      <DatePicker
        maxDate={maxDate}
        value={field.value ? new Date(field.value) : null}
        onChange={field.onChange}
        views={["day", "month", "year"]}
        format='dd.MM.yyyy'
        disabled={disabled}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!error,
            helperText: error?.message,
          },
          openPickerButton: {
            sx: { color: "base.200" },
          },
          openPickerIcon: {
            sx: { width: 16, height: 16 },
          },
        }}
      />
    </Stack>
  );
};

export default DatePickerControl;
