import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const DeleteIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M14.152 3C14.5298 3.0001 14.898 3.11906 15.2044 3.34003C15.5108 3.561 15.7399 3.87278 15.8593 4.2312L16.348 5.7H19.3C19.5387 5.7 19.7676 5.79482 19.9364 5.9636C20.1052 6.13239 20.2 6.3613 20.2 6.6C20.2 6.83869 20.1052 7.06761 19.9364 7.2364C19.7676 7.40518 19.5387 7.5 19.3 7.5H18.4V18.3C18.4 19.0161 18.1155 19.7028 17.6092 20.2092C17.1028 20.7155 16.4161 21 15.7 21H8.5C7.78392 21 7.09716 20.7155 6.59081 20.2092C6.08446 19.7028 5.8 19.0161 5.8 18.3V7.5H4.9C4.66131 7.5 4.43239 7.40518 4.2636 7.2364C4.09482 7.06761 4 6.83869 4 6.6C4 6.3613 4.09482 6.13239 4.2636 5.9636C4.43239 5.79482 4.66131 5.7 4.9 5.7H7.852L8.3407 4.2312C8.46013 3.87263 8.68939 3.56075 8.99599 3.33976C9.30259 3.11878 9.67096 2.99991 10.0489 3H14.152ZM16.6 7.5H7.6V18.3C7.6 18.5387 7.69482 18.7676 7.8636 18.9364C8.03239 19.1052 8.26131 19.2 8.5 19.2H15.7C15.9387 19.2 16.1676 19.1052 16.3364 18.9364C16.5052 18.7676 16.6 18.5387 16.6 18.3V7.5ZM14.152 4.8H10.048L9.7483 5.7H14.4517L14.152 4.8Z'
        fill='currentColor'
      />
    </svg>
  </SvgIcon>
);

export default DeleteIcon;
