import React, { useContext } from "react";
import ListLayout from "layout/ListLayout";
import { KnowledgeBlocksQuery, useKnowledgeBlocksQuery } from "@generated/graphql";
import useSetTabTitle from "hooks/useSetTabTitle";
import { CompanyContext } from "context/companyContext";
import { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import KnowledgeBlockCard from "./KnowledgeBlockCard";
import filterFields from "./filterFields";

type KnowledgeBlockItem = NonNullable<KnowledgeBlocksQuery["knowledgeBlocks"]["data"]>[number];

const KnowledgeBlocksList = () => {
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);

  const { data, loading, error, refetch } = useKnowledgeBlocksQuery({
    variables: {
      page: 0,
      limit: 8,
      filter: {},
    },
  });
  const list = data?.knowledgeBlocks.data;
  const createUnavailableText = generateUnavailableText(isCreateAvailable, currentCompany, "создания блока знаний");

  useSetTabTitle("Блоки знаний");
  return (
    <ListLayout<KnowledgeBlockItem>
      createUnavailableText={createUnavailableText}
      title='Блоки знаний'
      filters={filterFields}
      CardComponent={KnowledgeBlockCard}
      list={list || []}
      loading={loading}
      error={error}
      refetch={refetch}
      totalCount={data?.knowledgeBlocks.totalCount}
      emptyPlaceholderText='Пока нет блоков знаний'
    />
  );
};

export default KnowledgeBlocksList;
