import Avatar from "@mui/material/Avatar";
import HomeIcon from "assets/icons/HomeIcon";
import React from "react";
import { SxProps, Tooltip, tooltipClasses } from "@mui/material";

const CurrentCompanyIcon = ({ sx }: { sx?: SxProps }) => (
  <Tooltip
    arrow
    title='Вы находитесь в личном кабинете данной компании'
    componentsProps={{
      popper: {
        sx: {
          [`.${tooltipClasses.tooltip}`]: {
            maxWidth: "none",
          },
        },
      },
    }}>
    <Avatar
      sx={{
        width: 24,
        height: 24,
        backgroundColor: "blue",
        color: "#fff",
        ...sx,
      }}>
      <HomeIcon sx={{ width: 16, height: 16 }} />
    </Avatar>
  </Tooltip>
);

export default CurrentCompanyIcon;
