import { CompanyCandidatesQuery } from "@generated/graphql";
import { Table, TableBody, TableContainer } from "@mui/material";
import { Order } from "layout/ListLayout/types";
import { useMemo, useState } from "react";
import { getComparator } from "helpers/sort";
import { formatISODate } from "helpers/date";
import Head from "./Head";
import { Data } from "../types";
import Row from "./Row";

interface CandidatesTableProps {
  data: CompanyCandidatesQuery["companyCandidates"]["data"];
  selected: string[];
  setSelected: (values: string[]) => void;
}

const CandidatesTable = ({ data, selected, setSelected }: CandidatesTableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");

  const rows: Data[] = data
    ? data.map((item) => ({
        id: item.id,
        numericId: item.numericId,
        fullName: item.fullName,
        nickname: item.candidateUsername?.telegramUsername || "",
        createdAt: formatISODate(item.createdAt, "dd.MM.yyyy"),
      }))
    : [];

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const sortedRows = useMemo(() => [...rows].sort(getComparator(order, orderBy)), [order, orderBy, data]);

  return (
    <TableContainer sx={{ marginTop: 2, maxHeight: 215, paddingRight: 1.5 }}>
      <Table size='small'>
        <Head
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {sortedRows.map((row, index) => (
            <Row
              key={`enhanced-table-row-${row.numericId}`}
              row={row}
              isItemSelected={selected.includes(row.id)}
              labelId={`enhanced-table-checkbox-${index}`}
              handleClick={handleClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CandidatesTable;
