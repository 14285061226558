import React, { useContext, useEffect, useState } from "react";
import CustomModal from "components/modals/CustomModal";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import cookies from "js-cookie";
import CircularLoading from "components/CircularLoading";
import Box from "@mui/material/Box";

interface QuestionImgModalProps {
  questionId: string;
  open: boolean;
  onClose: () => void;
}

const QuestionImgModal = ({ questionId, open, onClose }: QuestionImgModalProps) => {
  const [img, setImg] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const { addToast } = useContext(ToastContext);
  const accessToken = cookies.get("accessToken");

  const getImg = async () => {
    setLoading(true);
    await fetch(`${process.env.REACT_APP_API_URL}/question/img-generate?questionId=${questionId}`, {
      headers: {
        Authorization: `JWT ${accessToken}`,
      },
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        setImg(url);
      })
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getImg();
  }, []);
  return (
    <CustomModal
      onClose={onClose}
      open={open}
      withoutButtons
      content={
        loading ? (
          <CircularLoading />
        ) : (
          <Box
            component='img'
            src={img}
            alt='Question text'
            sx={{
              mx: "auto",
              display: "block",
            }}
          />
        )
      }
    />
  );
};

export default QuestionImgModal;
