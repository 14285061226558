import { FieldArrayWithId, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { useContext } from "react";
import { ConfirmModalContext } from "context/confirmModalContext";
import withDraggable from "hocs/withDraggable";
import Competency from "./Competency";
import withAccordion from "../withAccordion";
import { CompetencyMapInputs } from "../types";

const CompetencyWithAccordion = withAccordion(Competency);
const CompetencyWithAccordionWithDraggable = withDraggable(CompetencyWithAccordion);

interface CompetenciesListProps {
  fields: FieldArrayWithId<CompetencyMapInputs, "knowledgeBlocks", "formId">[];
  form: UseFormReturn<CompetencyMapInputs>;
  isEditable: boolean;
  remove: UseFieldArrayRemove;
}

const CompetenciesList = ({ fields, form, isEditable, remove }: CompetenciesListProps) => {
  const { handleOpen } = useContext(ConfirmModalContext);

  const handleRemoveBlock = (index: number) => {
    handleOpen({
      title: "Вы уверены, что хотите удалить блок знаний?",
      handleClick: () => remove(index),
    });
  };

  return (
    <>
      {fields.map((field, index) => (
        <CompetencyWithAccordionWithDraggable
          key={field.formId}
          draggableId={field.formId}
          index={index}
          isDragDisabled={!isEditable}
          name={`knowledgeBlocks.${index}`}
          title='Компетенция'
          onRemove={() => handleRemoveBlock(index)}
          isEditable={isEditable}
          sx={{ boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)" }}
          form={form}
        />
      ))}
    </>
  );
};

export default CompetenciesList;
