import { useState } from "react";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import KnowledgeBlockCreation from "./KnowledgeBlockCreation";
import withAccordion from "../withAccordion";
import KnowledgeBlockBody from "./KnowledgeBlockBody";
import { CompetencyLiteralType, CompetencyMapInputs } from "../types";

const KnowledgeBlockBodyWithAccordion = withAccordion(KnowledgeBlockBody);

interface KnowledgeBlockProps {
  knowledgeBlockId: string | undefined;
  name: CompetencyLiteralType;
  isEditable: boolean;
  form: UseFormReturn<CompetencyMapInputs>;
  dragHandleProps: DraggableProvidedDragHandleProps | null;
  onRemove: () => void;
}

const KnowledgeBlock = ({
  knowledgeBlockId,
  name,
  isEditable,
  form,
  dragHandleProps,
  onRemove,
}: KnowledgeBlockProps) => {
  const [isCreation] = useState(!knowledgeBlockId);
  const { control } = form;
  const skillsArray = useFieldArray({
    control,
    name: `${name}.skills`,
    keyName: "skillId",
  });
  const { fields: skillsFields, append: appendSkill, replace: replaceSkills } = skillsArray;

  if (isCreation)
    return (
      <KnowledgeBlockCreation
        name={name}
        isEditable={isEditable}
        form={form}
        onRemove={onRemove}
        appendSkill={appendSkill}
        replaceSkills={replaceSkills}
        dragHandleProps={dragHandleProps}
        skillsFields={skillsFields}
      />
    );

  return (
    <KnowledgeBlockBodyWithAccordion
      title='Блок знаний'
      titleVariant='subtitle1'
      name={name}
      sx={{ backgroundColor: "bgSwitch", boxShadow: "none" }}
      isEditable={isEditable}
      form={form}
      onRemove={onRemove}
      appendSkill={appendSkill}
      dragHandleProps={dragHandleProps}
      skillsFields={skillsFields}
    />
  );
};

export default KnowledgeBlock;
