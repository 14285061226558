import { Language } from "@generated/graphql";

export const QUESTION_LANGUAGE_TITLE = {
  [Language.En]: "Английский",
  [Language.Kk]: "Казахский",
  [Language.Ru]: "Русский",
};

export const QUESTION_LANGUAGE_OPTIONS = [
  {
    value: Language.En,
    label: QUESTION_LANGUAGE_TITLE[Language.En],
  },
  {
    value: Language.Kk,
    label: QUESTION_LANGUAGE_TITLE[Language.Kk],
  },
  {
    value: Language.Ru,
    label: QUESTION_LANGUAGE_TITLE[Language.Ru],
  },
];
