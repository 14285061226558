import { Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TestingSelectModalContext } from "context/testingSelectModalContext";
import { CompetencyMapsQuery, Exact } from "@generated/graphql";
import { ApolloQueryResult } from "@apollo/client";
import CreateTemplate from "./CreateTemplate";

interface HeaderProps {
  refetch: (variables?: Partial<Exact<{ [key: string]: never }>>) => Promise<ApolloQueryResult<CompetencyMapsQuery>>;
}

const Header = ({ refetch }: HeaderProps) => {
  const navigate = useNavigate();
  const { handleOpen } = useContext(TestingSelectModalContext);

  const handleCreateButtonClick = () => {
    handleOpen({ navigate });
  };

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      alignItems={{ xs: "flex-start", md: "center" }}
      justifyContent='space-between'>
      <Typography variant='h28'>Карты компетенций</Typography>
      <Stack
        direction='row'
        spacing={1.5}>
        <CreateTemplate refetch={refetch} />
        <Button
          variant='contained'
          onClick={handleCreateButtonClick}>
          Запустить тестирование
        </Button>
      </Stack>
    </Stack>
  );
};

export default Header;
