import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Collapse, Grid, Stack } from "@mui/material";
import ExpandMoreButton from "components/buttons/ExpandMoreButton";
import Button from "@mui/material/Button";
import Form from "components/form/Form";
import GeneratedInput from "components/generators/inputsGenerator";

import { useTableConstructor } from "layout/TableConstructor/context/tableConstructorContext";
import { generateQueryOnSubmit, onClearFilter, onUseEffect } from "components/generators/filtersGenerator";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { Identifiable, IFilterProps } from "../../types";

const Filters = <T extends Identifiable, AvailableFilters extends object>({ form }: IFilterProps<AvailableFilters>) => {
  const { watch, reset, handleSubmit } = form;
  const { visibleFilterConfig } = useTableConstructor<T, AvailableFilters>();

  const [showFilters, setShowFilters] = useState<boolean>(true);
  const showFiltersText = showFilters ? "скрыть фильтры" : "показать фильтры";

  const [, setSearchParams] = useSearchParams();

  const onSubmit = (data: Record<string, unknown>) => {
    generateQueryOnSubmit(data, visibleFilterConfig, setSearchParams);
  };

  const onClear = () => onClearFilter(visibleFilterConfig, reset, setSearchParams);

  return (
    <Stack mb={2.5}>
      <Box>
        <Button
          size='small'
          color='secondary'
          disableRipple
          sx={(theme) => ({
            ...theme.typography.body14rg,
            ml: "auto",
            display: "flex",
            mb: 1,
            color: theme.palette.base[200],
          })}
          onClick={() => setShowFilters(!showFilters)}
          endIcon={
            <ExpandMoreButton
              expand={showFilters}
              onClick={() => setShowFilters(!showFilters)}
            />
          }>
          {showFiltersText}
        </Button>
      </Box>

      <Collapse
        in={showFilters}
        timeout='auto'
        unmountOnExit>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            padding: 3,
            boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)",
          }}>
          <Form
            form={form}
            autoComplete='false'>
            <Stack spacing={2}>
              <Box>
                <Grid
                  container
                  spacing={3}
                  alignItems='flex-end'>
                  {visibleFilterConfig.map((input) => (
                    <Grid
                      item
                      xs={12}
                      md={3}
                      key={`filter-input-${input.name}`}>
                      <GeneratedInput
                        watch={watch}
                        input={input}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Stack
                spacing={2}
                direction='row'
                justifyContent='end'>
                <Button
                  onClick={onClear}
                  sx={{
                    marginRight: 2,
                  }}
                  color='secondary'
                  variant='contained'
                  size='medium'>
                  Сбросить
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  onClick={handleSubmit(onSubmit)}
                  size='medium'>
                  Применить
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </Collapse>
    </Stack>
  );
};

export default Filters;
