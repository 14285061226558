import { Order } from "layout/ListLayout/types";

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = <Key extends keyof Record<string, number | string>>(
  order: Order,
  orderBy: Key
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};
