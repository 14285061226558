import ButtonWithPermissions, { generateUnavailableText } from "components/buttons/ButtonWithPermissions";
import { CompanyContext } from "context/companyContext";
import { MouseEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CompetencyMapsQuery, Exact } from "@generated/graphql";
import { ApolloQueryResult } from "@apollo/client";
import CreationPopover from "./CreationPopover";
import { CreationType } from "../../types";
import UploadTemplateModal from "./UploadTemplateModal";
import UploadJSONModal from "./UploadJSONModal";
import DescriptionModal from "./DescriptionModal";
import VacancyModal from "./VacancyModal";

const switchModal = (creationType: CreationType | undefined) => {
  switch (creationType) {
    case CreationType.TEMPLATE:
      return UploadTemplateModal;
    case CreationType.JSON:
      return UploadJSONModal;
    case CreationType.DESCRIPTION:
      return DescriptionModal;
    case CreationType.VACANCY:
      return VacancyModal;
    default:
      return null;
  }
};

interface CreateTemplateProps {
  refetch: (variables?: Partial<Exact<{ [key: string]: never }>>) => Promise<ApolloQueryResult<CompetencyMapsQuery>>;
}

const CreateTemplate = ({ refetch }: CreateTemplateProps) => {
  const navigate = useNavigate();
  const { isCreateAvailable, currentCompany } = useContext(CompanyContext);
  const createUnavailableText = generateUnavailableText(isCreateAvailable, currentCompany, "создания карт компетенций");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [creationType, setCreationType] = useState<CreationType>();

  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = (doRefetch: boolean = false) => {
    setCreationType(undefined);

    if (doRefetch) refetch();
  };

  const onCreationTypeSelect = (value: CreationType) => {
    handlePopoverClose();

    if (value === CreationType.CONSTRUCTOR) {
      navigate("create");
    } else {
      setCreationType(value);
    }
  };

  const openModal = creationType !== undefined && creationType !== CreationType.CONSTRUCTOR;

  const Modal = switchModal(creationType);

  return (
    <>
      <ButtonWithPermissions
        sx={{ backgroundColor: "#fff", color: "text.primary", "&:hover": { color: "#fff" } }}
        tooltipText={createUnavailableText}
        onClick={(e) => {
          e.stopPropagation();
          handlePopoverOpen(e);
        }}
        buttonText='Создать карту'
      />
      <CreationPopover
        anchorEl={anchorEl}
        open={openPopover}
        onClose={handlePopoverClose}
        onCreationTypeSelect={onCreationTypeSelect}
      />
      {openModal && Modal && (
        <Modal
          open={openModal}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default CreateTemplate;
