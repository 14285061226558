import React, { useMemo } from "react";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import { Order } from "layout/ListLayout/types";

export interface ILimit {
  value: string;
  label: string;
}
export const LIMITS: ILimit[] = [
  { value: "8", label: "8" },
  { value: "16", label: "16" },
  { value: "24", label: "24" },
];
export const DEFAULT_LIMIT = 8;

const useList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
  const { limit, page, order, orderBy } = params as unknown as {
    limit: ILimit["value"];
    page: string;
    order: Order;
    orderBy: string;
  };
  const changePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setSearchParams(qs.stringify({ ...params, page: newPage }));
  };

  const changeLimit = (event: SelectChangeEvent<ILimit>) =>
    setSearchParams(
      qs.stringify({
        ...params,
        limit: parseInt((event.target as HTMLTextAreaElement | HTMLInputElement).value, 10),
        page: 0,
      })
    );

  const changeSort = (newOrder: Order, newOrderBy: string) =>
    setSearchParams(
      qs.stringify({
        ...params,
        order: newOrder,
        orderBy: newOrderBy,
        limit: limit || DEFAULT_LIMIT,
        page: 0,
      })
    );

  return {
    changePage,
    changeLimit,
    page,
    limit,
    params,
    changeSort,
    orderBy,
    order,
  };
};

export default useList;
