import TextFieldControl from "components/form/TextFieldControl";
import CompetencyMapControl from "../../CompetencyMapControl";
import { CompetencyMapOptionList } from "../../types";

interface GroupAndOpenLeftProps {
  competencyMaps: CompetencyMapOptionList;
  competencyMapsLoading: boolean;
  isCreate: boolean;
}

const GroupAndOpenLeft = ({ competencyMaps, competencyMapsLoading, isCreate }: GroupAndOpenLeftProps) => {
  return (
    <>
      <CompetencyMapControl
        competencyMaps={competencyMaps}
        isEditing={isCreate}
        competencyMapsLoading={competencyMapsLoading}
      />
      <TextFieldControl
        name='competencyMap.comment'
        label='Комментарий к карте компетенций'
        minRows={7}
        multiline
        disabled
      />
    </>
  );
};

export default GroupAndOpenLeft;
