import { IconButton, Popover, Typography } from "@mui/material";
import StyledPopoverPaper from "components/PopoverPaper";
import { CreationType } from "../../types";

const CREATION_TYPES_ITEMS = [
  {
    label: "Конструктор",
    value: CreationType.CONSTRUCTOR,
  },
  {
    label: "Загрузить шаблон",
    value: CreationType.TEMPLATE,
  },
  {
    label: "Загрузить JSON",
    value: CreationType.JSON,
  },
  {
    label: "По текстовому описанию",
    value: CreationType.DESCRIPTION,
  },
  {
    label: "По вакансии",
    value: CreationType.VACANCY,
  },
];

interface ICreationPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onCreationTypeSelect: (creationType: CreationType) => void;
}

const CreationPopover = ({ open, anchorEl, onClose, onCreationTypeSelect }: ICreationPopoverProps) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={(e: MouseEvent) => {
        e.stopPropagation();
        onClose();
      }}
      slots={{ paper: StyledPopoverPaper }}
      disableRestoreFocus>
      {CREATION_TYPES_ITEMS.map((item) => (
        <IconButton
          key={`creation-type-button-${item.value}`}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            padding: "6px 10px",
            borderRadius: 1,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onCreationTypeSelect(item.value);
          }}>
          <Typography>{item.label}</Typography>
        </IconButton>
      ))}
    </Popover>
  );
};

export default CreationPopover;
