import { format, fromUnixTime, isValid, parse, parseISO } from "date-fns";

const formatFromStringToDate = (date: string) => parse(date, "yyyy-MM-dd", new Date());

const checkIsDateValid = (date: Date | string) => {
  const parsedDate = typeof date === "string" ? formatFromStringToDate(date) : date;
  return isValid(parsedDate);
};

const formatISODate = (dateISO: string, dateFormat: string = "dd.MM.yyyy") => format(parseISO(dateISO), dateFormat);

const fromMillisecondsToDate = (milliseconds: number): Date => {
  const seconds = Math.floor(milliseconds / 1000);
  return fromUnixTime(seconds);
};

export { checkIsDateValid, formatISODate, fromMillisecondsToDate };
