import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { LoadingButton } from "@mui/lab";

type ConfirmModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  handleClick: () => void;
  actionButtonText?: string;
  description?: string;
  loading?: boolean;
};

const ConfirmModal = ({
  open,
  onClose,
  title,
  handleClick,
  actionButtonText,
  description,
  loading,
}: ConfirmModalProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth='xs'
    fullWidth>
    <DialogTitle
      variant='h24'
      textAlign='center'>
      {title}
    </DialogTitle>
    {description && (
      <DialogContent>
        <Typography
          variant='body16rg'
          textAlign='center'
          display='block'>
          {description}
        </Typography>
      </DialogContent>
    )}
    <DialogActions sx={{ justifyContent: "center" }}>
      <Button
        variant='outlined'
        onClick={onClose}>
        Отмена
      </Button>
      <LoadingButton
        loading={loading}
        variant='contained'
        onClick={handleClick}>
        {actionButtonText || "Удалить"}
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export default ConfirmModal;
