import { Language } from "@generated/graphql";
import { PopoverProps } from "@mui/material";
import LanguagePopover from "components/LanguagePopover";
import { ComponentType, MouseEvent, useState } from "react";

type WithLanguageProps<T> = T & {
  setLanguage: (language: Language) => void;
  popoverProps?: Pick<PopoverProps, "anchorOrigin" | "slotProps"> | Record<string, never>;
};

function withLanguagePopover<T>(Component: ComponentType<T>) {
  return function WithLanguage(props: WithLanguageProps<T>) {
    const { setLanguage, popoverProps } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (e: MouseEvent<HTMLElement>) => {
      setAnchorEl(e.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        <Component
          {...props}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            handlePopoverOpen(e);
          }}
        />
        <LanguagePopover
          anchorEl={anchorEl}
          open={open}
          onClose={handlePopoverClose}
          setLanguage={setLanguage}
          popoverProps={popoverProps}
        />
      </>
    );
  };
}

export default withLanguagePopover;
