import React, { useContext } from "react";
import { Exact, Question_Type, SkillQuery, useConfirmOrRejectQuestionMutation } from "@generated/graphql";
import { Chip, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ConfirmModalContext } from "context/confirmModalContext";
import Button from "@mui/material/Button";
import { formatNumber, gradeColor } from "helpers/index";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import MarkdownFormatter from "components/MarkdownFormatter";
import { QueryResult } from "@apollo/client";
import { QuestionItemType } from "pages/Skill/view/types/skill";
import LabelBox from "pages/Skill/view/components/QuestionItem/components/LabelBox";
import ValueBox from "pages/Skill/view/components/QuestionItem/components/ValueBox";
import { QUESTION_LANGUAGE_TITLE } from "constants/language";
import ActionButtons from "./ActionButtons";

interface QuestionItemProps {
  question: QuestionItemType;
  skillQuery: QueryResult<SkillQuery, Exact<{ skillId: string }>>;
}

const QuestionItem: React.FC<QuestionItemProps> = ({ question, skillQuery }) => {
  const { data, refetch: refetchSkill } = skillQuery;
  const skill = data?.skill;
  const [confirmOrRejectQuestion, { loading: confirmRejectLoading }] = useConfirmOrRejectQuestionMutation();
  const { handleOpen } = useContext(ConfirmModalContext);
  const { addToast } = useContext(ToastContext);

  // const isConfirm = question.confirmation;
  // const isMicroCriterionExist: boolean = !!question.microCriterion?.length;

  const onConfirmOrReject = () =>
    confirmOrRejectQuestion({
      variables: {
        questionId: question.id,
      },
    });

  const onConfirm = () => {
    handleOpen({
      title: "Вы уверены, что хотите подтвердить вопрос?",
      actionButtonText: "Подтвердить",
      loading: confirmRejectLoading,
      handleClick: async () =>
        onConfirmOrReject()
          .then(() => {
            refetchSkill();
            addToast({ type: ToastTypeEnum.SUCCESS });
          })
          .catch(() => addToast({ type: ToastTypeEnum.ERROR })),
    });
  };

  const { type } = question;

  return (
    <Box sx={{ position: "relative" }}>
      <ActionButtons
        skill={skill}
        question={question}
        refetchSkill={refetchSkill}
      />
      <Stack
        spacing={2}
        sx={{ py: 2.5 }}>
        <Stack
          spacing={2}
          sx={{ pr: 3 }}
          alignItems='flex-start'>
          <LabelBox text={QUESTION_LANGUAGE_TITLE[question.language]} />
          {type === Question_Type.Practical && (
            <Stack
              direction='row'
              spacing={2.5}
              alignItems='center'>
              <Box
                sx={{
                  borderRadius: 2,
                  backgroundColor: "blue",
                  px: 1.5,
                  py: 1,
                }}>
                <Typography
                  variant='body14rg'
                  sx={{ color: "white" }}>
                  Практика
                </Typography>
              </Box>
              <Typography
                variant='body14rg'
                sx={{ color: "primary.main" }}>
                {`Подтип: ${question.subtype}`}
              </Typography>
            </Stack>
          )}
          {/* { */}
          {/*  isConfirm && !isMicroCriterionExist && ( */}
          {/*    <Alert sx={{ maxWidth: 490 }}> */}
          {/*      Производится генерация микрокритериев, */}
          {/*      до&nbsp;момента окончания процесса вопрос */}
          {/*      не&nbsp;будет использоваться в&nbsp;тестировании. */}
          {/*    </Alert> */}
          {/*  ) */}
          {/* } */}
          <MarkdownFormatter
            text={question.text}
            variant='body16rg'
          />
          <Stack
            direction='row'
            spacing={1.5}
            sx={{ "& > div": { display: "inherit" } }}>
            {question.grade && (
              <Chip
                size='small'
                label={question.grade}
                sx={{
                  backgroundColor: `${gradeColor(question.grade)}.light`,
                  color: `${gradeColor(question.grade)}.contrast`,
                  width: 70,
                }}
              />
            )}
            <LabelBox text='Время ответа' />
            <ValueBox text={formatNumber(["минута", "минуты", "минут"], Math.ceil(question.time / 60))} />
          </Stack>
          <Stack
            direction='row'
            spacing={1.5}
            sx={{ "& > div": { display: "inherit" } }}>
            <LabelBox text='Рекомендуемое время ответа' />
            <ValueBox
              text={formatNumber(["минута", "минуты", "минут"], Math.ceil((question.recommendedTime || 0) / 60))}
            />
          </Stack>
        </Stack>
        <Stack spacing={1}>
          {question.criterion.map((criteria, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={`criteria-${index}`}
              sx={{
                backgroundColor: "bgSwitch",
                px: 1,
                py: 0.5,
                borderRadius: 1,
              }}>
              <Typography
                variant='body14rg'
                sx={{ color: "primary.dark" }}>
                {criteria}
              </Typography>
            </Box>
          ))}
        </Stack>
        {!question.confirmation && (
          <Button
            variant='contained'
            onClick={onConfirm}
            sx={{ width: "fit-content" }}>
            Подтвердить
          </Button>
        )}
      </Stack>
    </Box>
  );
};
export default QuestionItem;
