import React, { FC } from "react";
import { Box, CircularProgress, SxProps } from "@mui/material";

type CircularLoadingProps = {
  sx?: SxProps;
  size?: number;
};

const CircularLoading: FC<CircularLoadingProps> = ({ sx, size = 40 }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      py: 2,
      ...sx,
    }}>
    <CircularProgress size={size} />
  </Box>
);

export default CircularLoading;
