import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const LanguageIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.8999 3.045C12.8999 3.045 15.5999 6.6 15.5999 12C15.5999 17.4 12.8999 20.955 12.8999 20.955M11.0999 20.955C11.0999 20.955 8.39989 17.4 8.39989 12C8.39989 6.6 11.0999 3.045 11.0999 3.045M3.56689 15.15H20.4329M3.56689 8.85H20.4329M3 12C3 16.9707 7.0293 21 12 21C16.9707 21 21 16.9707 21 12C21 7.0293 16.9707 3 12 3C7.0293 3 3 7.0293 3 12Z'
        stroke='white'
        strokeWidth='1.35'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </SvgIcon>
);

export default LanguageIcon;
