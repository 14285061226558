import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import Form from "components/form/Form";
import TextFieldControl from "components/form/TextFieldControl";
import { useForm } from "react-hook-form";
import { CompetencyMapFromXlsxInput, useCreateCompetencyMapFromXlsxMutation } from "@generated/graphql";
import VALIDATION from "helpers/validation";
import FileUploadButton from "components/buttons/FileUploadButton";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import React, { useContext } from "react";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { useNavigate } from "react-router-dom";

interface IUploadTemplateModalProps {
  open: boolean;
  handleClose: () => void;
}

const UploadTemplateModal = ({ open, handleClose }: IUploadTemplateModalProps) => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const form = useForm<CompetencyMapFromXlsxInput>();
  const { handleSubmit } = form;

  const [createTemplate, { loading }] = useCreateCompetencyMapFromXlsxMutation();

  const onError = () => addToast({ type: ToastTypeEnum.ERROR });

  const onSubmit = (formData: CompetencyMapFromXlsxInput) => {
    createTemplate({
      variables: {
        input: {
          title: formData.title,
          specialization: formData.specialization,
          file: formData.file,
        },
      },
    })
      .then((res) => {
        const competencyMapId = res.data?.createCompetencyMapFromXlsx.id;
        if (competencyMapId) {
          navigate(`view/${competencyMapId}`);
          addToast({ type: ToastTypeEnum.SUCCESS });
          handleClose();
        } else onError();
      })
      .catch(() => onError());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'>
      <DialogTitle
        variant='h24'
        textAlign='center'>
        Загрузка шаблона карты
      </DialogTitle>
      <DialogContent>
        <Form form={form}>
          <Stack spacing={2}>
            <TextFieldControl
              label='Название карты компетенций'
              name='title'
              rules={{ required: true, ...VALIDATION.competencyMapTitle }}
            />
            <TextFieldControl
              label='Специализация'
              name='specialization'
              rules={{ required: true, ...VALIDATION.competencyMapTitle }}
            />
            <FileUploadButton
              name='file'
              label='Загрузить шаблон'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              rules={{ required: "Обязательное поле" }}
            />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={handleClose}>
          Отмена
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit, onError)}
          loading={loading}
          variant='contained'>
          Загрузить
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadTemplateModal;
