import { Identifiable, ITableRowProps, OnRowClick } from "layout/TableConstructor/types";
import { useTableConstructor } from "layout/TableConstructor/context/tableConstructorContext";
import { Dispatch, SetStateAction, useState } from "react";
import { IconButton } from "@mui/material";
import MuiTableRow from "@mui/material/TableRow";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import TableCell from "./TabelCell";

const CollapseButtonCell = ({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => (
  <TableCell onClick={(e) => e.stopPropagation()}>
    <IconButton
      sx={{ color: "base.200" }}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open);
      }}
      size='small'>
      {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButton>
  </TableCell>
);

const CollapsingRow = <T extends Identifiable, ColapsingRowName extends keyof T | undefined = undefined>({
  isCollapsingRow,
  colapsingRowName,
  row,
  open,
  onRowClick,
}: {
  isCollapsingRow: boolean | undefined;
  row: T;
  colapsingRowName: ColapsingRowName | undefined;
  onRowClick: OnRowClick<T, ColapsingRowName> | undefined;
  open: boolean;
}) => {
  const { visibleColumnConfig } = useTableConstructor();

  if (
    isCollapsingRow &&
    colapsingRowName &&
    Array.isArray(row[colapsingRowName]) &&
    row[colapsingRowName]?.length &&
    open
  ) {
    const collapsingRow = row[colapsingRowName];
    return collapsingRow.map((collapsedRow) => (
      <MuiTableRow
        onClick={() => onRowClick?.(row, collapsedRow)}
        sx={{ backgroundColor: "#fff", cursor: onRowClick ? "pointer" : "default" }}
        key={`collapsed-row-${collapsedRow.id}`}>
        <TableCell />
        {visibleColumnConfig.map((col) => (
          <TableCell key={`cell-row-${collapsedRow.id}-col-${col.name}`}>
            {"renderCollapse" in col && typeof col.renderCollapse === "function"
              ? // @ts-expect-error порешать потом с типом TODO
                col.renderCollapse(collapsedRow)
              : col.render(row)}
          </TableCell>
        ))}
      </MuiTableRow>
    ));
  }

  return null;
};

const Row = <T extends Identifiable, ColapsingRowName extends keyof T | undefined = undefined>({
  row,
  colapsingRowName,
  checkIsCollapsingRow,
  onRowClick,
}: ITableRowProps<T, ColapsingRowName>) => {
  const { visibleColumnConfig } = useTableConstructor();

  const [open, setOpen] = useState(false);

  const isCollapsingRow = checkIsCollapsingRow?.(row);

  return (
    <>
      <MuiTableRow
        onClick={() => onRowClick?.(row)}
        sx={{
          backgroundColor: isCollapsingRow ? "#fff" : undefined,
          cursor: onRowClick ? "pointer" : "default",
          "&:last-of-type": {
            "& .MuiTableCell-root": {
              "&:first-of-type": {
                borderLeftStyle: "solid",
                borderBottomLeftRadius: 8,
              },
              "&:last-of-type": {
                borderBottomRightRadius: 8,
              },
            },
          },
        }}
        key={`row-${row.id}`}>
        {isCollapsingRow && (
          <CollapseButtonCell
            open={open}
            setOpen={setOpen}
          />
        )}
        {colapsingRowName && !isCollapsingRow && <TableCell />}
        {visibleColumnConfig.map((col) => (
          <TableCell key={`cell-row-${row.id}-col-${col.name}`}>{col.render(row)}</TableCell>
        ))}
      </MuiTableRow>
      <CollapsingRow
        open={open}
        isCollapsingRow={isCollapsingRow}
        row={row}
        onRowClick={onRowClick}
        colapsingRowName={colapsingRowName}
      />
    </>
  );
};

export default Row;
