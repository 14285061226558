import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import React from "react";

const ValueBox = ({ text }: { text: string }) => (
  <Box
    sx={{
      py: 1,
      px: 1.5,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "divider",
      width: 128,
    }}>
    <Typography variant='body14rg'>{text}</Typography>
  </Box>
);

export default ValueBox;
