import { ColumnFilterMap } from "layout/TableConstructor/types";
import { CompetencyMapFilters } from "pages/CompetencyMaps/list/tableConfig/filterFields";

const columnFilterMap: ColumnFilterMap<CompetencyMapFilters> = {
  title: "title",
  status: "status",
  createdAt: "createdAt",
  // specialization: "specialization",
  grades: "grades",
  // competencesCount: "competencesCount",
  // trialsCount: "trialsCount",
  // updatedAt: "updatedAt",
  // isRepositoryCheck: "isRepositoryCheck",
  // lastTrialDate
  // language
};

export default columnFilterMap;
