import { createContext, ReactNode, useState, useMemo, useContext, Dispatch, SetStateAction } from "react";
import { ColumnConfig, ColumnFilterMap, FilterField, Identifiable } from "layout/TableConstructor/types";
import { useLocation } from "react-router-dom";

interface TableConstructorContextProps<
  T extends Identifiable,
  AvailableFilters extends object,
  ColapsingRowName extends string | undefined = undefined,
> {
  setVisibleFilterList: Dispatch<SetStateAction<(keyof AvailableFilters | string)[]>>;
  visibleFilterList: (keyof AvailableFilters | string)[];
  visibleFilterConfig: FilterField<AvailableFilters>[];
  visibleColumnList: string[];
  setVisibleColumnList: Dispatch<SetStateAction<string[]>>;
  filterConfigList: FilterField<AvailableFilters>[];
  columnConfigList: ColumnConfig<T, ColapsingRowName>[];
  visibleColumnConfig: ColumnConfig<T, ColapsingRowName>[];
  columnFilterMap: ColumnFilterMap<AvailableFilters>;
}

const TableConstructorContext = createContext<TableConstructorContextProps<any, any, any> | undefined>(undefined);

interface TableConstructorProviderProps<
  T extends Identifiable,
  AvailableFilters extends object,
  ColapsingRowName extends string | undefined = undefined,
> {
  children: ReactNode;
  defaultFilterList?: (keyof AvailableFilters)[];
  defaultColumnList?: string[];
  columnConfigList: ColumnConfig<T, ColapsingRowName>[];
  filterConfigList: FilterField<AvailableFilters>[];
  columnFilterMap: ColumnFilterMap<AvailableFilters>;
}

const TableConstructorProvider = <
  T extends Identifiable,
  AvailableFilters extends object,
  ColapsingRowName extends string | undefined = undefined,
>({
  children,
  defaultFilterList,
  defaultColumnList,
  columnConfigList,
  filterConfigList,
  columnFilterMap,
}: TableConstructorProviderProps<T, AvailableFilters, ColapsingRowName>) => {
  const location = useLocation();
  const allColumns = columnConfigList.map((filter) => filter.name);

  const savedConfigString = localStorage.getItem(location.pathname.replace("/", ""));
  const savedColumnConfig: string[] | null = savedConfigString ? JSON.parse(savedConfigString) : null;

  const [visibleColumnList, setVisibleColumnList] = useState<string[]>(
    savedColumnConfig || defaultColumnList || allColumns
  );

  const allFilters = visibleColumnList.reduce((acc: (keyof AvailableFilters | string)[], columnName) => {
    const filterName = columnFilterMap[columnName];
    if (filterName) acc.push(filterName);
    return acc;
  }, []);

  const [visibleFilterList, setVisibleFilterList] = useState<(keyof AvailableFilters | string)[]>(
    defaultFilterList || allFilters
  );

  const visibleFilterConfig = useMemo(
    () =>
      filterConfigList.reduce<FilterField<AvailableFilters>[]>((acc, filterConfig) => {
        const isFilterVisible = visibleFilterList.includes(filterConfig.name);
        if (isFilterVisible) acc.push(filterConfig);
        return acc;
      }, []),
    [visibleFilterList]
  );

  const visibleColumnConfig = useMemo(
    () =>
      columnConfigList.reduce<ColumnConfig<T, ColapsingRowName>[]>((acc, columnConfig) => {
        const isColumnVisible = visibleColumnList.includes(columnConfig.name);
        if (isColumnVisible) acc.push(columnConfig);
        return acc;
      }, []),
    [visibleColumnList]
  );

  const value: TableConstructorContextProps<T, AvailableFilters, ColapsingRowName> = useMemo(
    () => ({
      visibleFilterList,
      visibleColumnList,
      setVisibleFilterList,
      setVisibleColumnList,
      visibleFilterConfig,
      filterConfigList,
      columnConfigList,
      visibleColumnConfig,
      columnFilterMap,
    }),
    [
      visibleFilterList,
      visibleColumnList,
      setVisibleFilterList,
      setVisibleColumnList,
      filterConfigList,
      columnConfigList,
      visibleColumnConfig,
      columnFilterMap,
    ]
  );
  return <TableConstructorContext.Provider value={value}>{children}</TableConstructorContext.Provider>;
};

const useTableConstructor = <
  T extends Identifiable,
  AvailableFilters extends object,
  ColapsingRowName extends string | undefined = undefined,
>(): TableConstructorContextProps<T, AvailableFilters, ColapsingRowName> => {
  const context = useContext(TableConstructorContext);
  if (context === undefined) {
    throw new Error("useTableConstructor must be used within a TableConstructorProvider");
  }
  return context;
};

export { TableConstructorProvider, useTableConstructor };
