import MuiTable from "@mui/material/Table";
import { Stack, TableContainer } from "@mui/material";
import ListPagination from "components/ListPagination";
import React from "react";
import { ILimit } from "hooks/useList";
import EmptyList from "components/EmptyList";
import CircularLoading from "components/CircularLoading";
import TableHeader from "./components/TableHeader";
import TableBody from "./components/TableBody";
import { Identifiable, TableViewProps } from "../../types";

const TableView = <
  T extends Identifiable,
  AvailableFilters extends object,
  ColapsingRowName extends string | undefined = undefined,
>(
  props: TableViewProps<T, ColapsingRowName>
) => {
  const { data, totalCount, emptyPlaceholderText, loading, colapsingRowName, checkIsCollapsingRow, onRowClick } = props;

  const LIMITS: ILimit[] = [
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "150", value: "150" },
    { label: "Все", value: "100000" },
  ];
  // TODO коллапс строк
  if (loading) return <CircularLoading />;
  if (!data.length) return <EmptyList emptyPlaceholderText={emptyPlaceholderText} />;
  return (
    <Stack spacing={3}>
      <TableContainer sx={{ overflowX: "auto", "& .MuiTable-root": { borderCollapse: "separate" } }}>
        <MuiTable>
          <TableHeader<T, AvailableFilters> colapsingRowName={colapsingRowName} />
          <TableBody<T, ColapsingRowName>
            data={data}
            colapsingRowName={colapsingRowName}
            checkIsCollapsingRow={checkIsCollapsingRow}
            onRowClick={onRowClick}
          />
        </MuiTable>
      </TableContainer>
      <ListPagination
        text={"Количество\nна странице"}
        totalCount={totalCount || 0}
        limits={LIMITS}
        defaultLimit={50}
      />
    </Stack>
  );
};

export default TableView;
