import { FieldArrayWithId } from "react-hook-form";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CompetencyMapInputs, KnowledgeBlockCompetencyOption, SkillsLiteralType } from "../types";
import Skill from "./Skill";

interface SkillsListProps {
  name: SkillsLiteralType;
  skills: FieldArrayWithId<CompetencyMapInputs, SkillsLiteralType, "skillId">[];
  knowledgeBlock: KnowledgeBlockCompetencyOption;
}

const SkillsList = ({ name, skills, knowledgeBlock }: SkillsListProps) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
      <Table
        sx={{ minWidth: 650 }}
        size='small'>
        <TableHead>
          <TableRow sx={{ "& th": { border: "none" } }}>
            <TableCell>Навык</TableCell>
            <TableCell>Грейд</TableCell>
            <TableCell>Количество вопросов (подтвержденные/неподтвержденные)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {skills.map((skill, index) => (
            <Skill
              key={skill.skillId}
              name={`${name}.${index}`}
              skill={skill}
              knowledgeBlock={knowledgeBlock}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SkillsList;
