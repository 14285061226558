import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { ComponentType } from "react";

const getListStyle = () => ({
  width: "100%",
  overflow: "none",
});

type WithDragDropContextProps<T> = T & {
  droppableId: string;
  moveItems: (indexA: number, indexB: number) => void;
};

const withDragDropContext = <T,>(Component: ComponentType<T>) => {
  // eslint-disable-next-line react/function-component-definition, react/display-name
  return (props: WithDragDropContextProps<T>) => {
    const { droppableId, moveItems } = props;

    const onDragEnd = (result: DropResult<string>) => {
      if (!result.destination) {
        return;
      }

      const startIndex = result.source.index;
      const endIndex = result.destination.index;

      if (startIndex === endIndex) {
        return;
      }

      moveItems(startIndex, endIndex);
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle()}>
              <Component {...props} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
};

export default withDragDropContext;
