import { Checkbox, TableCell, TableRow } from "@mui/material";
import { Data } from "../types";

interface RowProps {
  row: Data;
  isItemSelected: boolean;
  labelId: `enhanced-table-checkbox-${number}`;
  handleClick: (id: string) => void;
}

const Row = ({ row, isItemSelected, labelId, handleClick }: RowProps) => {
  return (
    <TableRow
      hover
      onClick={() => handleClick(row.id)}
      role='checkbox'
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}>
      <TableCell align='center'>
        <Checkbox
          color='primary'
          size='small'
          checked={isItemSelected}
        />
      </TableCell>
      <TableCell
        id={labelId}
        scope='row'>
        {row.numericId}
      </TableCell>
      <TableCell>{row.fullName}</TableCell>
      <TableCell>@{row.nickname}</TableCell>
      <TableCell>{row.createdAt}</TableCell>
    </TableRow>
  );
};

export default Row;
