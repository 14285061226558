import { FieldArrayWithId, UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { useContext } from "react";
import { ConfirmModalContext } from "context/confirmModalContext";
import withDraggable from "hocs/withDraggable";
import KnowledgeBlock from "./KnowledgeBlock";
import { CompetenciesLiteralType, CompetencyMapInputs } from "../types";

const KnowledgeBlockWithDraggable = withDraggable(KnowledgeBlock);

interface KnowledgeBlocksListProps {
  fields: FieldArrayWithId<CompetencyMapInputs, CompetenciesLiteralType, "formId">[];
  form: UseFormReturn<CompetencyMapInputs>;
  isEditable: boolean;
  name: CompetenciesLiteralType;
  remove: UseFieldArrayRemove;
}

const KnowledgeBlocksList = ({ fields, form, isEditable, name, remove }: KnowledgeBlocksListProps) => {
  const { handleOpen } = useContext(ConfirmModalContext);
  const handleRemoveBlock = (index: number) => {
    handleOpen({
      title: "Вы уверены, что хотите удалить блок знаний?",
      handleClick: () => remove(index),
    });
  };

  return (
    <>
      {fields.map((knowledgeBlock, index) => (
        <KnowledgeBlockWithDraggable
          key={knowledgeBlock.formId}
          draggableId={knowledgeBlock.formId}
          isDragDisabled={!isEditable}
          index={index}
          knowledgeBlockId={knowledgeBlock.id}
          form={form}
          isEditable={isEditable}
          name={`${name}.${index}`}
          onRemove={() => handleRemoveBlock(index)}
        />
      ))}
    </>
  );
};

export default KnowledgeBlocksList;
