import { Competency_Map_Status } from "@generated/graphql";
import { COMPETENCY_MAP_STATUSES } from "constants/global";
import { TestingSelectModalContext } from "context/testingSelectModalContext";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

interface CreateTestingProps {
  competencyMap?: {
    __typename?: "CompetencyMap";
    id: string;
    status: Competency_Map_Status;
    competences?: Array<{
      id: string;
      knowledgeBlockCompetences?: Array<{
        id: string;
        knowledgeBlock: {
          id: string;
          skills?: Array<{
            id: string;
            questions?: Array<{
              id: string;
              confirmation: boolean;
            }> | null;
          }> | null;
        };
      }> | null;
    }> | null;
  } | null;
}

const useCreateTesting = ({ competencyMap }: CreateTestingProps) => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const { handleOpen } = useContext(TestingSelectModalContext);

  const mapId = competencyMap?.id;

  const createTesting = () => {
    const competences = competencyMap?.competences;
    if (competencyMap?.status !== Competency_Map_Status.Approved) {
      const statusLable = COMPETENCY_MAP_STATUSES.find((status) => status.value === competencyMap?.status)?.label;
      addToast({
        type: ToastTypeEnum.ERROR,
        text: `Невозможно создать тестирование, так как карта компетенций имеет статус ${statusLable}`,
      });
      return;
    }
    if (!competences?.length || competences.every((competency) => !competency.knowledgeBlockCompetences?.length)) {
      addToast({
        type: ToastTypeEnum.ERROR,
        text: "Невозможно создать тестирование, так как карта компетенций пустая.",
      });
      return;
    }
    if (competences.some((competency) => !competency.knowledgeBlockCompetences?.length)) {
      addToast({
        type: ToastTypeEnum.ERROR,
        text: 'Невозможно создать тестирование, так как компетенция "название компетенции" не содержит блок знаний.',
      });
      return;
    }

    let skill: any;
    // eslint-disable-next-line no-restricted-syntax
    for (const competency of competences) {
      if (!competency.knowledgeBlockCompetences?.length) break;
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      competency.knowledgeBlockCompetences.every((kbc) => {
        if (!kbc.knowledgeBlock.skills?.length) return false;
        skill = kbc.knowledgeBlock.skills?.find((t) => t.questions?.every((q) => !q.confirmation));
        return skill;
      });
    }

    if (skill) {
      addToast({
        type: ToastTypeEnum.ERROR,
        text: `Невозможно создать тестирование, так как навык ${skill.title} не содержит вопросы.`,
      });
    } else {
      handleOpen({ navigate, mapId });
    }
  };

  return { createTesting };
};

export default useCreateTesting;
