import { Grid } from "@mui/material";
import ListPagination from "components/ListPagination";
import EmptyList from "components/EmptyList";
import React, { FC } from "react";
import { ApolloError } from "@apollo/client";
import { Identifiable } from "layout/ListLayout/types";

type ListProps<Row> = {
  list: Row[];
  CardComponent: FC<any>;
  error?: ApolloError;
  totalCount: number | undefined;
  emptyPlaceholderText: string;
};

const List = <Row extends Identifiable>({
  error,
  list,
  totalCount,
  emptyPlaceholderText,
  CardComponent,
}: ListProps<Row>) => {
  if (!error && list && list.length) {
    return (
      <>
        <Grid
          container
          mb={2.5}
          rowSpacing={2.5}
          columnSpacing={2.5}>
          {list?.map((item) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              key={`card-${item.id}`}>
              <CardComponent {...item} />
            </Grid>
          ))}
        </Grid>
        <ListPagination totalCount={totalCount!} />
      </>
    );
  }

  return <EmptyList emptyPlaceholderText={emptyPlaceholderText} />;
};

export default List;
