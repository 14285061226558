import { Box, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { ChangeEvent, MouseEvent, useCallback, useState } from "react";
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayReplace, UseFormReturn } from "react-hook-form";
import TextFieldControl from "components/form/TextFieldControl";
import { useKnowledgeBlockOptionsQuery } from "@generated/graphql";
import Autocomplete from "components/inputs/Autocomplete";
import { debounce } from "lodash";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { Close } from "@mui/icons-material";
import {
  CompetencyLiteralType,
  CompetencyMapInputs,
  isKnowledgeBlockCompetencyFieldsNonNullable,
  KnowledgeBlockCreationType,
  KnowledgeBlocksOption,
  SkillsLiteralType,
} from "../types";
import KnowledgeBlockBody from "./KnowledgeBlockBody";

interface IKnowledgeBlockSelectProps {
  name: CompetencyLiteralType;
  isEditable: boolean;
  form: UseFormReturn<CompetencyMapInputs>;
  skillsFields: FieldArrayWithId<CompetencyMapInputs, SkillsLiteralType, "skillId">[];
  dragHandleProps: DraggableProvidedDragHandleProps | null;
  appendSkill: UseFieldArrayAppend<CompetencyMapInputs, SkillsLiteralType>;
  replaceSkills: UseFieldArrayReplace<CompetencyMapInputs, SkillsLiteralType>;
  onRemove: () => void;
}

const KnowledgeBlockCreation = ({
  name,
  isEditable,
  skillsFields,
  dragHandleProps,
  appendSkill,
  replaceSkills,
  onRemove,
  form,
}: IKnowledgeBlockSelectProps) => {
  const { getValues, setValue } = form;
  const knowledgeBlock = getValues(name);
  const [knowledgeBlockCreationType, setKnowledgeBlockCreationType] = useState(KnowledgeBlockCreationType.EXIST);
  const {
    data: knowledgeBlockOptionsData,
    loading,
    refetch,
  } = useKnowledgeBlockOptionsQuery({
    variables: { limit: 10 },
  });

  const debounceQuery = useCallback(
    debounce((value) => {
      refetch({ filter: { title: value }, limit: 10 });
    }, 500),
    []
  );

  const resetKnowledgeBlockData = () => {
    setValue(`${name}.id`, undefined);
    setValue(`${name}.title`, "");
    setValue(`${name}.profession`, "");
    replaceSkills(skillsFields.filter((skill) => !skill.id) || []);
  };

  const handleChangeCreationType = (event: ChangeEvent<HTMLInputElement>) => {
    const typeValue = Number((event.target as HTMLInputElement).value);
    setKnowledgeBlockCreationType(typeValue);
    resetKnowledgeBlockData();

    if (typeValue === KnowledgeBlockCreationType.EXIST) {
      refetch({ filter: { title: "" }, limit: 10 });
    }
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemove();
  };

  const handleAutocompleteChange = (data: KnowledgeBlocksOption | null) => {
    if (data) {
      setValue(`${name}.id`, data.id);
      setValue(`${name}.title`, data.title);
      setValue(`${name}.profession`, data.profession);
      replaceSkills([...(data.skills || []), ...(skillsFields.filter((skill) => !skill.id) || [])]);
    } else {
      resetKnowledgeBlockData();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "bgSwitch",
        boxShadow: "none",
        borderRadius: 2,
        paddingY: 1.5,
        paddingX: 2,
        "&:not(:first-child)": { marginTop: 2 },
      }}>
      <div {...dragHandleProps}>
        <Stack
          direction='row'
          spacing={2}
          alignItems='flex-start'
          justifyContent='space-between'>
          <Box>
            <RadioGroup
              value={knowledgeBlockCreationType}
              onChange={handleChangeCreationType}>
              <FormControlLabel
                value={KnowledgeBlockCreationType.EXIST}
                control={<Radio />}
                label='выбрать существующий блок знаний'
              />
              <FormControlLabel
                value={KnowledgeBlockCreationType.NEW}
                control={<Radio />}
                label='создать новый блок знаний'
              />
            </RadioGroup>
            <Stack
              direction='row'
              spacing={2}
              sx={{ alignItems: "center", marginBottom: 1 }}>
              <Typography
                variant='subtitle1'
                sx={{ fontWeight: 400, minWidth: 100 }}>
                Блок знаний
              </Typography>
              {knowledgeBlockCreationType === KnowledgeBlockCreationType.EXIST ? (
                <Autocomplete
                  value={isKnowledgeBlockCompetencyFieldsNonNullable(knowledgeBlock) ? knowledgeBlock : null}
                  optionName='title'
                  options={knowledgeBlockOptionsData?.knowledgeBlocks.data || []}
                  loading={loading}
                  onChange={handleAutocompleteChange}
                  onInputChange={(_, value) => debounceQuery(value)}
                  sx={{
                    width: 400,
                    "& .MuiInputBase-root": {
                      backgroundColor: "white",
                      boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)",
                      border: `1px solid palette.divider`,
                      borderRadius: 2,
                    },
                  }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={`knowledgeBlock-${option.id}`}>
                      <Stack spacing={0.5}>
                        <Typography
                          variant='body14rg'
                          sx={{ color: "primary.main" }}>
                          {option.title}
                        </Typography>
                        <Stack
                          direction='row'
                          spacing={1.5}>
                          {!!option.grades?.length && (
                            <Typography
                              variant='body14rg'
                              sx={{ "& > span": { color: "base.500" } }}>
                              <span>грейд: </span>
                              {option.grades?.join(", ")}
                            </Typography>
                          )}
                          {option.profession && (
                            <Typography
                              variant='body14rg'
                              sx={{ "& > span": { color: "base.500" } }}>
                              <span>Профессия: </span>
                              {option.profession}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </li>
                  )}
                />
              ) : (
                <TextFieldControl
                  placeholder='Название блока знаний'
                  name={`${name}.title`}
                  disabled={!isEditable}
                  rules={{ required: true }}
                  sx={{ width: 400 }}
                  color='secondary'
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </Stack>
          </Box>
          {isEditable && (
            <IconButton
              onClick={handleRemove}
              sx={{ color: "base.200" }}>
              <Close />
            </IconButton>
          )}
        </Stack>
        <KnowledgeBlockBody
          name={name}
          isEditable={isEditable}
          form={form}
          skillsFields={skillsFields}
          appendSkill={appendSkill}
        />
      </div>
    </Box>
  );
};

export default KnowledgeBlockCreation;
