import TextFieldControl from "components/form/TextFieldControl";
import { UseFormGetValues, UseFormReturn } from "react-hook-form";
import { Examination_Type } from "@generated/graphql";
import TestingLink from "components/TestingLink";
import { GroupAndOpenInfoInputs } from "../../types";

interface GroupAndOpenRightProps {
  form: UseFormReturn<GroupAndOpenInfoInputs>;
  examinationType: Examination_Type | undefined;
}

const GroupAndOpenRight = ({ form, examinationType }: GroupAndOpenRightProps) => {
  const getValues = form.getValues as unknown as UseFormGetValues<GroupAndOpenInfoInputs>;

  return (
    <>
      <TextFieldControl
        name='competencyMap.grades'
        label='Грейд карты компетенций'
        disabled
      />
      <TextFieldControl
        name='createdAt'
        label='Дата создания тестирования'
        disabled
      />
      {examinationType === Examination_Type.Open && <TestingLink link={getValues("link")} />}
    </>
  );
};

export default GroupAndOpenRight;
