import { InputType } from "components/generators/inputsGenerator";
import { generateOptions } from "helpers/index";
import { STATUS_TITLE } from "components/TestingStatus";
import { IAutocompleteValue } from "components/inputs/Autocomplete/types";
import VALIDATION from "helpers/validation";
import { FilterField } from "layout/TableConstructor/types";
import dayjs from "dayjs";
import { ExaminationListQueryVariables } from "@generated/graphql";
import { EXAMINATION_TYPE_TITLE, GRADE_OPTIONS } from "constants/global";

export type ExaminationFilter = NonNullable<ExaminationListQueryVariables["filter"]>;

const filterConfig: FilterField<ExaminationFilter>[] = [
  {
    name: "numericId",
    label: "ID",
    type: InputType.TEXT,
    rules: VALIDATION.inn,
    // type: number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "candidateNumericId",
    label: "ID кандидата",
    type: InputType.TEXT,
    rules: VALIDATION.inn,
    // type: number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "candidateFullName",
    label: "Кандидат",
    type: InputType.TEXT,
    rules: VALIDATION.userName,
  },
  {
    name: "createdAt",
    label: "Дата создания",
    type: InputType.DATE,
    customFilter: (value) => dayjs(value).toISOString(),
  },
  {
    name: "completedAt",
    label: "Дата завершения тестирования",
    type: InputType.DATE,
    customFilter: (value) => dayjs(value).toISOString(),
  },
  {
    name: "status",
    label: "Статус",
    type: InputType.AUTOCOMPLETE,
    options: generateOptions(STATUS_TITLE),
    customFilter: (value: IAutocompleteValue) => value.id,
  },
  {
    name: "grades",
    label: "Грейд",
    type: InputType.AUTOCOMPLETE,
    options: GRADE_OPTIONS,
    customFilter: (value: IAutocompleteValue) => value.id,
  },
  {
    name: "candidateScore",
    label: "Результаты",
    type: InputType.TEXT,
    rules: VALIDATION.inn,
    // type: number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "expectedTimeOfTesting",
    label: "Время прохождения ожидаемое",
    type: InputType.TEXT,
    rules: VALIDATION.inn,
    // type: number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "timeOfTesting",
    label: "Время прохождения реальное",
    type: InputType.TEXT,
    rules: VALIDATION.inn,
    // type: number
    customFilter: (value) => (value ? +value : undefined),
  },
  {
    name: "examinationType",
    label: "Тип тестирования",
    type: InputType.AUTOCOMPLETE,
    options: generateOptions(EXAMINATION_TYPE_TITLE),
    customFilter: (value: IAutocompleteValue) => value.id,
  },
];

export default filterConfig;
