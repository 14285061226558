import React, { useContext } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { FieldErrors, useForm } from "react-hook-form";
import TextFieldControl from "components/form/TextFieldControl";
import Form from "components/form/Form";
import { LoadingButton } from "@mui/lab";
import CheckboxControl from "components/form/CheckboxControl";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/routes";
import { useCreateUserMutation } from "@generated/graphql";
import { setAuthCookies } from "helpers/cookieHelper";
import { cleanSpaceInString } from "helpers/validation/createValidation";
import VALIDATION from "helpers/validation";

type UserRegisterForm = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  agreement: boolean;
  confirmPassword: string;
};

const UserRegister = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const form = useForm<UserRegisterForm>();
  const { handleSubmit } = form;

  const [createUser, { loading }] = useCreateUserMutation();

  const onSubmit = (formData: UserRegisterForm) => {
    createUser({
      variables: {
        input: {
          email: cleanSpaceInString(formData.email),
          password: formData.password,
          firstName: cleanSpaceInString(formData.firstName),
          lastName: cleanSpaceInString(formData.lastName),
          middleName: formData.middleName ? cleanSpaceInString(formData.middleName) : undefined,
        },
      },
    })
      .then((res) => {
        const accessToken = res.data?.createUser.accessToken;
        const refreshToken = res.data?.createUser.refreshToken;
        if (accessToken && refreshToken) {
          setAuthCookies([
            {
              name: "accessToken",
              value: accessToken,
            },
            {
              name: "refreshToken",
              value: refreshToken,
            },
          ]);
          navigate(`/${ROUTES.COMPANY_REGISTER}`);
        } else {
          addToast({ type: ToastTypeEnum.ERROR, text: "Произошла ошибка" });
        }
      })
      .catch((error) => {
        if (error?.message?.includes("Email должен быть уникальным")) {
          addToast({ type: ToastTypeEnum.ERROR, text: "Email должен быть уникальным" });
        } else {
          addToast({ type: ToastTypeEnum.ERROR, text: "Произошла ошибка" });
        }
      });
  };

  const onError = (errors: FieldErrors<UserRegisterForm>) => {
    let message;
    if (Object.keys(errors).length > 1) message = "Проверьте правильность заполнения полей";
    else if (errors.agreement) message = errors.agreement.message;
    addToast({ text: message, type: ToastTypeEnum.ERROR });
  };

  return (
    <Card sx={{ py: 3.5, px: 5, minWidth: 556 }}>
      <Typography
        variant='h28'
        sx={{ mb: 3.5 }}>
        Регистрация
      </Typography>
      <Form form={form}>
        <Stack spacing={3.5}>
          <TextFieldControl
            label='Email'
            name='email'
            type='email'
            rules={{
              required: true,
              ...VALIDATION.emailFull,
            }}
          />
          <TextFieldControl
            label='Фамилия'
            name='lastName'
            rules={{ required: true, ...VALIDATION.userName }}
          />
          <TextFieldControl
            label='Имя'
            name='firstName'
            rules={{ required: true, ...VALIDATION.userName }}
          />
          <TextFieldControl
            label='Отчество'
            name='middleName'
            rules={VALIDATION.userName}
          />
          <TextFieldControl
            label='Пароль'
            type='password'
            name='password'
            rules={{
              required: true,
            }}
          />
          <TextFieldControl
            label='Подтвердите пароль'
            type='password'
            name='confirmPassword'
            rules={{
              required: true,
              validate: (value, formValues) => value === formValues.password,
            }}
          />
          <CheckboxControl
            label='согласие с обработкой персональных данных'
            name='agreement'
            rules={{ validate: (value) => value || "Вы должны дать согласие на обработку персональных данных" }}
          />
          <LoadingButton
            loading={loading}
            variant='contained'
            size='large'
            onClick={handleSubmit(onSubmit, onError)}>
            Зарегистрироваться
          </LoadingButton>
        </Stack>
      </Form>
    </Card>
  );
};

export default UserRegister;
