import { useContext, useState } from "react";
import { ToastContext, ToastTypeEnum } from "context/toastContext";
import { formatISODate } from "helpers/date";
import cookies from "js-cookie";
import { Language } from "@generated/graphql";

const useDownloadReport = (
  trialId: string,
  isWithExplanations: boolean,
  fullName: string | undefined | null,
  date: string
) => {
  const { addToast } = useContext(ToastContext);
  const accessToken = cookies.get("accessToken");
  const companyId = cookies.get("companyId");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onError = () =>
    addToast({
      type: ToastTypeEnum.ERROR,
      text: "Произошла ошибка. Попробуйте позже.",
    });

  const onDownloadReport = async (language: Language) => {
    setIsLoading(true);
    await fetch(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_API_URL}/trial/fetch-result?trialId=${trialId}&isWithExplanations=${isWithExplanations}&language=${language}`,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
          ...(companyId && { "company-id": companyId }),
        },
      }
    )
      .then(async (response) => {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(JSON.stringify(errorData));
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${fullName}_${formatISODate(date, "dd.MM.yyyy HH:mm")}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        try {
          const errorData = JSON.parse(error.message);
          if (errorData.error === "NOT_IN_COMPANY_ENVIRONMENT") {
            addToast({
              type: ToastTypeEnum.ERROR,
              text: "У текущей компании нет доступа к этому отчету",
            });
          } else {
            onError();
            console.error("Произошла другая ошибка:", errorData);
          }
        } catch (e) {
          onError();
          console.error("Ошибка при обработке данных ошибки:", e);
        }
      })
      .finally(() => setIsLoading(false));
  };
  return { onDownloadReport, isLoading };
};

export default useDownloadReport;
