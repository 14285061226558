import { styled } from "@mui/material";
import MuiTabelCell from "@mui/material/TableCell";

const TableCell = styled(MuiTabelCell)(({ theme }) => ({
  px: "10px",
  py: theme.spacing(1.5),
  borderStyle: "none solid solid none",
  "&:first-of-type": {
    borderLeftStyle: "solid",
  },
}));

export default TableCell;
