import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const OpenEyeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <path
        // eslint-disable-next-line max-len
        d='M3.20057 12.7844C2.93314 12.2954 2.93314 11.7045 3.20058 11.2154C4.9 8.10803 8.20336 6 12 6C15.7966 6 19.1 8.10809 20.7994 11.2156C21.0669 11.7046 21.0669 12.2956 20.7994 12.7846C19.1 15.892 15.7966 18 12 18C8.20336 18 4.89997 15.8919 3.20057 12.7844Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        // eslint-disable-next-line max-len
        d='M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  </SvgIcon>
);

export default OpenEyeIcon;
