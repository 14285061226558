import React, { FC } from "react";
import { UseFormWatch } from "react-hook-form";
import { checkIsDateValid } from "helpers/date";
import DatePickerControl from "components/form/DatePickerControl";
import TextFieldControl from "components/form/TextFieldControl";
import AutocompleteControl from "components/form/AutocompleteControl";
import { FilterField } from "layout/TableConstructor/types";

export enum InputType {
  DATE = "DATE",
  TEXT = "TEXT",
  AUTOCOMPLETE = "AUTOCOMPLETE",
}

export const INPUT_DEFAULT_VALUE = {
  [InputType.DATE]: null,
  [InputType.TEXT]: "",
  [InputType.AUTOCOMPLETE]: null,
};
type InputsGeneratorProps = {
  input: FilterField<object>;
  watch: UseFormWatch<any>;
};

const GeneratedInput: FC<InputsGeneratorProps> = ({ input, watch, ...rest }) => {
  const { type } = input;
  switch (type) {
    case InputType.DATE: {
      return (
        <DatePickerControl
          rules={{ validate: (value: Date) => (value ? checkIsDateValid(value) : true) }}
          maxDate={new Date()}
          {...{ ...input, ...rest }}
        />
      );
    }
    case InputType.TEXT: {
      return (
        <TextFieldControl
          size='small'
          {...{ ...input, ...rest }}
        />
      );
    }
    case InputType.AUTOCOMPLETE: {
      return <AutocompleteControl {...{ ...input, ...rest }} />;
    }

    default:
      return null;
  }
};

export default GeneratedInput;
