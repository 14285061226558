import React, { FC } from "react";
import { CheckboxProps, FormControlLabel, SxProps, Typography } from "@mui/material";
import MuiCheckbox from "@mui/material/Checkbox";
import CheckboxBorderIcon from "./CheckboxBorderIcon";
import CheckedIcon from "./CheckedIcon";

export type TCheckboxProp = {
  label: string;
  handleChange: (value: boolean) => void;
  value: boolean;
  disabled?: boolean;
  sx?: SxProps;
};

const Checkbox: FC<CheckboxProps & TCheckboxProp> = ({ label, disabled, handleChange, value, sx }) => {
  return (
    <FormControlLabel
      sx={sx}
      checked={value}
      onChange={(_, checked) => {
        handleChange(checked);
      }}
      control={
        <MuiCheckbox
          icon={<CheckboxBorderIcon />}
          checkedIcon={<CheckedIcon />}
        />
      }
      label={<Typography dangerouslySetInnerHTML={{ __html: `${label}` }} />}
      disabled={disabled}
    />
  );
};

export default Checkbox;
