import MuiTableBody from "@mui/material/TableBody";
import { Identifiable, TableBodyProps } from "../../../types";
import TableRow from "./TableRow";

const TableBody = <T extends Identifiable, ColapsingRowName extends string | undefined = undefined>({
  data,
  colapsingRowName,
  checkIsCollapsingRow,
  onRowClick,
}: TableBodyProps<T, ColapsingRowName>) => {
  return (
    <MuiTableBody>
      {data.map((row) => (
        <TableRow<T, ColapsingRowName>
          checkIsCollapsingRow={checkIsCollapsingRow}
          colapsingRowName={colapsingRowName}
          row={row}
          key={`row-${row.id}`}
          onRowClick={onRowClick}
        />
      ))}
    </MuiTableBody>
  );
};

export default TableBody;
