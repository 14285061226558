import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ApolloProvider } from "@apollo/client";
import { ToastProvider } from "context/toastContext";
import { ModalProvider as ConfirmModalProvider } from "context/confirmModalContext";
import { ModalProvider as TestingSelectModalProvider } from "context/testingSelectModalContext";
import { UserProvider } from "context/userContext";
import { CompanyProvider } from "context/companyContext";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import createAppTheme from "./theme";
import router from "./router";
import client from "./config/apolloConfig";
import "./assets/style.css";
import "highlight.js/styles/github.css";

dayjs.extend(duration);

const App = () => {
  const theme = createAppTheme();
  return (
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <UserProvider>
              <CompanyProvider>
                <ConfirmModalProvider>
                  <TestingSelectModalProvider>
                    <CssBaseline />
                    <RouterProvider router={router} />
                  </TestingSelectModalProvider>
                </ConfirmModalProvider>
              </CompanyProvider>
            </UserProvider>
          </ToastProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  );
};

export default App;
