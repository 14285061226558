import { styled, SvgIcon, SvgIconProps, TableHead, TableSortLabel } from "@mui/material";
import MuiTabelCell from "@mui/material/TableCell";
import { useTableConstructor } from "layout/TableConstructor/context/tableConstructorContext";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import { Identifiable } from "layout/TableConstructor/types";

const SortIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <path
        d='M18.5 6.74999C18.5 6.55108 18.421 6.36031 18.2803 6.21966C18.1397 6.07901 17.9489 5.99999 17.75 5.99999C17.5511 5.99999 17.3603 6.07901 17.2197 6.21966C17.079 6.36031 17 6.55108 17 6.74999V16.44L14.78 14.22C14.7113 14.1463 14.6285 14.0872 14.5365 14.0462C14.4445 14.0052 14.3452 13.9832 14.2445 13.9814C14.1438 13.9796 14.0438 13.9981 13.9504 14.0359C13.857 14.0736 13.7722 14.1297 13.701 14.201C13.6297 14.2722 13.5736 14.357 13.5359 14.4504C13.4982 14.5438 13.4796 14.6438 13.4814 14.7445C13.4832 14.8452 13.5052 14.9445 13.5462 15.0365C13.5872 15.1285 13.6463 15.2113 13.72 15.28L17.22 18.78C17.3606 18.9204 17.5512 18.9993 17.75 18.9993C17.9488 18.9993 18.1394 18.9204 18.28 18.78L21.78 15.28C21.9125 15.1378 21.9846 14.9498 21.9812 14.7555C21.9777 14.5612 21.899 14.3758 21.7616 14.2384C21.6242 14.101 21.4388 14.0222 21.2445 14.0188C21.0502 14.0154 20.8622 14.0875 20.72 14.22L18.5 16.44V6.74999ZM2.75 6.49999C2.55109 6.49999 2.36032 6.57901 2.21967 6.71966C2.07902 6.86031 2 7.05108 2 7.24999C2 7.4489 2.07902 7.63967 2.21967 7.78032C2.36032 7.92097 2.55109 7.99999 2.75 7.99999H12.25C12.4489 7.99999 12.6397 7.92097 12.7803 7.78032C12.921 7.63967 13 7.4489 13 7.24999C13 7.05108 12.921 6.86031 12.7803 6.71966C12.6397 6.57901 12.4489 6.49999 12.25 6.49999H2.75ZM2.75 11.5C2.55109 11.5 2.36032 11.579 2.21967 11.7197C2.07902 11.8603 2 12.0511 2 12.25C2 12.4489 2.07902 12.6397 2.21967 12.7803C2.36032 12.921 2.55109 13 2.75 13H8.25C8.44891 13 8.63968 12.921 8.78033 12.7803C8.92098 12.6397 9 12.4489 9 12.25C9 12.0511 8.92098 11.8603 8.78033 11.7197C8.63968 11.579 8.44891 11.5 8.25 11.5H2.75ZM2.75 16.5C2.55109 16.5 2.36032 16.579 2.21967 16.7197C2.07902 16.8603 2 17.0511 2 17.25C2 17.4489 2.07902 17.6397 2.21967 17.7803C2.36032 17.921 2.55109 18 2.75 18H6.25C6.44891 18 6.63968 17.921 6.78033 17.7803C6.92098 17.6397 7 17.4489 7 17.25C7 17.0511 6.92098 16.8603 6.78033 16.7197C6.63968 16.579 6.44891 16.5 6.25 16.5H2.75Z' // eslint-disable-line max-len
        fill='#A9A9A9'
      />
    </svg>
  </SvgIcon>
);

const TableCell = styled(MuiTabelCell)(({ theme }) => ({
  backgroundColor: "#fff",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(1.5),
  paddingLeft: theme.spacing(1.5),
  ...theme.typography.body14rg,
  color: theme.palette.primary.main,
  borderTop: "none",
  borderStyle: "none solid solid none",
  "&:first-of-type": {
    borderTopLeftRadius: 8,
    borderLeft: "none",
  },
  "&:last-of-type": {
    borderTopRightRadius: 8,
    borderRight: "none",
  },
}));

const TableHeader = <T extends Identifiable, AvailableFilters extends object>({
  colapsingRowName,
}: {
  colapsingRowName: string | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { visibleColumnConfig } = useTableConstructor<T, AvailableFilters>();

  const handleSort = (field: string) => {
    const direction = query.sortField === field && query.sortOrder === "asc" ? "desc" : "asc";
    navigate(`?${qs.stringify({ ...query, sortField: field, sortOrder: direction })}`);
  };

  return (
    <TableHead>
      {colapsingRowName && <TableCell />}
      {visibleColumnConfig.map((column) => {
        const isActive = query.sortField === column.title;
        const sortDirection = isActive && query.sortOrder === "asc" ? "asc" : "desc";
        return (
          <TableCell key={`column-${column.name as string}`}>
            {column.withoutSort ? (
              column.title
            ) : (
              <TableSortLabel
                sx={{
                  "&.Mui-active": {
                    color: "primary.main",
                  },
                  "& .MuiSvgIcon-root": {
                    opacity: 0.3,
                  },
                }}
                IconComponent={SortIcon}
                hideSortIcon={false}
                active={isActive}
                direction={sortDirection}
                onClick={() => handleSort(column.title)}>
                {column.title}
              </TableSortLabel>
            )}
          </TableCell>
        );
      })}
    </TableHead>
  );
};

export default TableHeader;
