import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Card, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { formatISODate } from "helpers/date";
import { formatNumber, gradeColor } from "helpers/index";
import { Link } from "react-router-dom";
import CardHeader from "components/CardHeader";
import { Grade } from "@generated/graphql";

type CardProps = {
  id: string;
  title: string;
  grades?: Array<Grade> | null;
  createdAt: any;
  updatedAt: any;
  skills?: Array<{ id: string }> | null;
};

const KnowledgeBlockCard: FC<CardProps> = ({ title, grades, updatedAt, createdAt, skills, id }) => (
  <Card
    component={Link}
    to={`view/${id}`}
    sx={{ display: "block", height: "100%", textDecoration: "none" }}>
    <Stack
      spacing={2.5}
      sx={{ height: "100%" }}
      justifyContent='space-between'>
      <Box>
        <CardHeader title={title} />
        <Divider sx={{ mt: 1.5 }} />
      </Box>
      <Stack spacing={2.5}>
        {!!grades?.length && (
          <Box>
            <Grid
              container
              columnSpacing={2}
              rowSpacing={1}>
              {grades.map((grade) => (
                <Grid
                  item
                  key={`knowledge-block-grade-item-${grade}`}>
                  <Chip
                    label={grade}
                    sx={{ backgroundColor: `${gradeColor(grade)}.light`, color: `${gradeColor(grade)}.contrast` }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <Stack
          direction='row'
          justifyContent='space-between'>
          <Box
            sx={{
              borderRadius: 1,
              backgroundColor: "primary.main",
              color: "white",
              py: 0.5,
              px: 1.5,
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {formatNumber(["навык", "навыка", "навыков"], skills?.length)}
          </Box>
          <Stack
            justifyContent='space-between'
            spacing={0.5}>
            <Typography
              variant='body14rg'
              sx={{
                "& > span": { color: "text.disabled" },
                display: "inline-flex",
                justifyContent: "space-between",
              }}>
              <span>созд.</span> {createdAt && formatISODate(createdAt, "dd.MM.yy")}
            </Typography>
            <Typography
              variant='body14rg'
              sx={{
                "& > span": { color: "text.disabled" },
                display: "inline-flex",
                justifyContent: "space-between",
              }}>
              <span>ред.</span> {updatedAt && formatISODate(updatedAt, "dd.MM.yy")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </Card>
);

export default KnowledgeBlockCard;
