import CircularLoading from "components/CircularLoading";
import { TrialResultQuery, useExaminationResultQuery } from "@generated/graphql";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import RepositoryCheck from "./components/blocks/RepositoryCheck/RepositoryCheck";
import CodeReviewCommon from "./components/blocks/CodeReviewCommon";
import CommonInfo from "./components/blocks/CommonInfo";
import RecommendationBlock from "./components/blocks/RecomendationBlock";
import AnswerStatistics from "./components/blocks/AnswerStatistics";
import CandidateAnswers from "./components/blocks/CandidateAnswers";

export type KnowledgeBlocks = NonNullable<TrialResultQuery["trial"]>["examination"]["competencyMap"]["competences"];

const ResultTab = () => {
  const { examinationId } = useParams();
  const { data, loading } = useExaminationResultQuery({
    variables: {
      examinationId: examinationId!,
    },
  });
  const result = data?.examination;
  const knowledgeBlocks = result?.competencyMap.competences;
  const trial = result?.trials?.[0];

  const isRepositoryCheckExist = !!trial?.trialRepositoryChecks?.length;
  const lastRepositoryCheck = trial?.trialRepositoryChecks?.[0];

  if (loading) return <CircularLoading />;

  return (
    <Stack
      py={2.5}
      spacing={2.5}>
      <CommonInfo score={trial?.candidateScore!} />
      {isRepositoryCheckExist && lastRepositoryCheck && <CodeReviewCommon trialRepositoryCheck={lastRepositoryCheck} />}
      <RecommendationBlock knowledgeBlocks={knowledgeBlocks} />
      <AnswerStatistics knowledgeBlocks={knowledgeBlocks} />
      <CandidateAnswers knowledgeBlocks={knowledgeBlocks} />
      {isRepositoryCheckExist && lastRepositoryCheck && <RepositoryCheck trialRepositoryCheck={lastRepositoryCheck} />}
    </Stack>
  );
};

export default ResultTab;
