import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ChartProps {
  algorithmsScore: number;
  maintainabilityScore: number;
  readabilityScore: number;
}

const Chart = ({ algorithmsScore, readabilityScore, maintainabilityScore }: ChartProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    chart: {
      type: "column",
      height: 340,
    },
    title: {
      text: "",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    xAxis: {
      categories: ["Оптимальность алгоритмов", "Поддерживаемость кода", "Читаемость кода"],
    },
    yAxis: {
      min: 0,
      max: 10,
      tickInterval: 1,
      labels: {
        format: "{value:.1f}",
      },
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
    },
    series: [
      {
        name: "Баллы",
        type: "column",
        data: [
          {
            y: algorithmsScore,
            color: "#94D4FF",
            name: "Оптимальность алгоритмов",
          },
          {
            y: maintainabilityScore,
            color: "#D4AAE5",
            name: "Поддерживаемость кода",
          },
          {
            y: readabilityScore,
            color: "#C5EBA4",
            name: "Читаемость кода",
          },
        ],
      },
    ],
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",
        px: 1.5,
        py: 2.5,
        boxShadow: "0px 0px 7.3px 0px rgba(164, 184, 253, 0.24)",
        borderRadius: 1.5,
      }}>
      <Typography
        variant='body14rg'
        sx={{ fontWeight: 600, textAlign: "center", display: "block" }}>
        Основные метрики оценки кода
      </Typography>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </Box>
  );
};

export default Chart;
